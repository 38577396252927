import { formConstants } from "../constants";
export function form(state = { answers: false}, action) {
    switch (action.type) {
      case formConstants.SET_ANSWERS:
        return {
          ...state,
          ...{
            answers: action.payload,
          },
        };
      default:
        return state;
    }
  }
  