import React from 'react';
import { Row } from 'reactstrap';
// components
import EventContainer from '../../components/event_container';
import { withFirebase } from '../../components/firebase';
import { asyncForEach } from '../../helpers/arrays';
import { withRouter } from 'react-router-dom';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import Ticket from './Ticket';
import Product from './Product';
import Coutesy from './Courtesy';
import './styles.scss';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetOrderId, handleAcceptedTerms } from '../../redux/actions';
import { centsToDecimal } from '../../helpers/currency';
import { sendEventPixel } from '../../helpers/pixel';
import { sendTransaction } from '../../helpers/analytics';

class OrderConfirmation extends React.Component {
  orderTrack = false;
  state = {
    tickets: [],
    products: [],
    courtesies: [],
    user: { name: '' },
    email: '',
    isReady: false
  };
  goToPdfPage() {
    const { orderId } = this.props.match.params;
    const url = `/orders/${orderId}/pdf`;
    const win = window.open(url, '_blank');
    win.focus();
    //this.props.history.push(`/orders/${orderId}/pdf`);
  }
  generateTickets = async orderTickets => {
    const { orderId } = this.props.match.params;
    return new Promise(async (resolve, reject) => {
      const tickets = orderTickets.docs.map(ticket => {
        if (!this.orderTrack) {
          sendTransaction('addItem', {
            id: orderId,
            name: ticket.data().name,
            sku: ticket.data().phaseId,
            category: 'Phases',
            price: centsToDecimal(ticket.data().unitPrice).toFixed(2),
            quantity: ticket.data().quantity,
            currency: 'MXN'
          });
        }
        return {
          ...ticket.data(),
          id: ticket.id
        };
      });
      const ticketData = await this.handleGetPurchaseTickets(tickets);
      resolve(ticketData);
    });
  };
  generateProducts = async orderProducts => {
    const { orderId } = this.props.match.params;
    return new Promise(async (resolve, reject) => {
      const products = orderProducts.docs.map(product => {
        if (!this.orderTrack) {
          sendTransaction('addItem', {
            id: orderId,
            name: product.data().name,
            sku: product.data().productId,
            category: 'Extra Serices & Products',
            price: centsToDecimal(product.data().unitPrice).toFixed(2),
            quantity: product.data().quantity,
            currency: 'MXN'
          });
        }
        return {
          ...product.data(),
          id: product.id
        };
      });
      const productsData = await this.handleGetPurchaseProducts(products);
      resolve(productsData);
    });
  };
  handleGetPurchaseProducts = async object => {
    let data = [];
    await asyncForEach(object, async item => {
      const { organizationId, eventId } = this.props;
      const { orderId } = this.props.match.params;

      const response = await this.props.firebase.getPurchaseProducts(
        organizationId,
        eventId,
        orderId,
        item.id
      );
      item.purchaseProducts = response.docs.map(product => ({
        ...product.data(),
        id: product.id
      }));
      data.push(item);
    });
    return data;
  };

  handleGetPurchaseTickets = async object => {
    let data = [];
    await asyncForEach(object, async item => {
      const { organizationId, eventId } = this.props;
      const { orderId } = this.props.match.params;
      const phase = await this.props.firebase.getPhaseData(organizationId, eventId, item.phaseId);
      const response = await this.props.firebase.getPurchaseTickets(
        organizationId,
        eventId,
        orderId,
        item.id
      );
      item.purchaseTickets = response.docs.map(ticket => ({
        ...ticket.data(),
        id: ticket.id,
        imageHeader: phase.data().ticketHeaderImage
      }));
      data.push(item);
    });
    return data;
  };

  generateCourtesiesTickets = async orderCourtesies => {
    return new Promise(async (resolve, reject) => {
      const courtesies = orderCourtesies.docs.map(item => ({
        ...item.data(),
        id: item.id
      }));
      const courtesiesData = await this.handleGetCourtesies(courtesies);
      resolve(courtesiesData);
    });
  };
  track;

  handleGetCourtesies = async object => {
    let data = [];
    await asyncForEach(object, async item => {
      const { organizationId, eventId } = this.props;
      const { orderId } = this.props.match.params;
      const response = await this.props.firebase.getOrderCourtesy(
        organizationId,
        eventId,
        orderId,
        item.id
      );
      item.tickets = response.docs.map(ticket => ({
        ...ticket.data(),
        id: ticket.id
      }));
      data.push(item);
    });
    return data;
  };

  handleFetchData = async () => {
    const { organizationId, eventId } = this.props;
    const { orderId } = this.props.match.params;
    const order = await this.props.firebase.getOrder(organizationId, eventId, orderId);

    await order.onSnapshot(async orderData => {
      if (orderData.exists) {
        this.orderTrack = orderData.data().track || false;
        if (this.orderIsPaid(orderData) && orderData.data().fulfilled) {
          localStorage.removeItem('selectedDate')
          if (this.props.acceptTerms) {
            this.props.handleAcceptedTerms();
          }
          const subtotal = centsToDecimal(orderData.data().subtotal).toFixed(2);

          await this.handleGetUserData(orderData);
          const orderTickets = await this.props.firebase.getOrderTicketsStatic(
            organizationId,
            eventId,
            orderId
          );
          const orderProducts = await this.props.firebase.getOrderProductsCollectionStatic(
            organizationId,
            eventId,
            orderId
          );
          const orderCourtesies = await this.props.firebase.getOrderCourtesiesCollection(
            organizationId,
            eventId,
            orderId
          );
          const ticketData = await this.generateTickets(orderTickets);
          const productsData = await this.generateProducts(orderProducts);
          const courtesiesData = await this.generateCourtesiesTickets(orderCourtesies);
          this.setState({
            tickets: ticketData,
            products: productsData,
            courtesies: courtesiesData,
            isReady: true
          });
          if (this.props.firebase.auth.currentUser && !this.orderTrack) {
            const orderDoc = await this.props.firebase.getOrder(organizationId, eventId, orderId);
            orderDoc.update({ track: true });
            sendEventPixel('Purchase', { value: subtotal, currency: 'MXN' });
            sendTransaction(
              'addTransaction',
              {
                id: orderData.id,
                affiliation: 'Handypass',
                revenue: subtotal,
                currency: 'MXN'
              },
              true
            );
          }
        }
      } else {
        this.props.history.push('/');
      }
    });
  };

  handleFetchEventData = async () => {
    const { organizationId, eventId } = this.props;
    const eventRef = await this.props.firebase.eventDoc(organizationId, eventId);
    const event = await eventRef.get();
    this.setState({
      eventName: event.data().name
    });
  };
  orderIsPaid = order => {
    const { orderStatus } = order.data();
    return orderStatus === 'PAID';
  };

  handleGetUserData = async order => {
    const { userId } = order.data();
    const user = await this.props.firebase.userInfo(userId);
    const {
      name: { firstName, lastName },
      email
    } = user.data();
    this.setState({ user: { name: firstName }, email });
  };

  componentDidMount() {
    this.props.resetOrderId({});
    this.handleFetchData();
    this.handleFetchEventData();
  }

  render() {
    const { orderId } = this.props.match.params;
    return (
      <ReactPlaceholder ready={this.state.isReady} type="text" rows={12}>
        <h3>¡Gracias {this.state.user.name} por tu compra! ¡Nos vemos pronto en {this.state.eventName}!</h3>
        <p>
          En los próximos 5 minutos recibiras un correo de confirmación con tus boletos. Podrás
          entrar al evento con ese correo o presentando el PDF de forma digital.
        </p>
        <p>
          {' '}
          Si no recibes el correo te recomendamos revisar la carpeta de SPAM (correo no deseado).
        </p>
        <p>
          Tu número de orden es: <b>{orderId}</b>
        </p>
        <Row className="all_tickets">
          {this.state.tickets.map((elem, i) => (
            <Ticket phase={elem} event={this.props.event} key={i} />
          ))}
          {this.state.courtesies.map((elem, i) => (
            <Coutesy courtesy={elem} event={this.props.event} key={i} />
          ))}
          {this.state.products.map((elem, i) => (
            <Product product={elem} event={this.props.event} key={i} />
          ))}
        </Row>
      </ReactPlaceholder>
    );
  }
}
const WithProps = props => {
  return (
    <EventContainer step={'Paso 5'}>
      {({ eventId, organizationId, event }) => (
        <OrderConfirmation
          event={event}
          organizationId={organizationId}
          eventId={eventId}
          {...props}
        />
      )}
    </EventContainer>
  );
};

//export default withFirebase();

export default compose(
  withFirebase,
  withRouter,
  connect(
    state => ({ acceptTerms: state.cart.acceptedTerms }),
    dispatch => bindActionCreators({ resetOrderId, handleAcceptedTerms }, dispatch)
  )
)(WithProps);
