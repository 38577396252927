import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import google from '../../assets/images/google.svg';
import firefox from '../../assets/images/firefox.svg';
import ie from '../../assets/images/ie.svg';
import opera from '../../assets/images/opera.svg';
import safari from '../../assets/images/safari.svg';
import './styles.scss';

export default class index extends Component {
  render() {
    return (
      <Container className="contentbrowser">
        <Row md="12">
          <Col sm="12" md="12">
            <h4 className="titelbrowser">Tu navegador no es soportado</h4>
          </Col>
        </Row>
        <Row className="rowb" md="12">
          <Col sm="12" md="12">
            <p className="textbrowser">
              Para tener una buena experiencia de compra le recomendamos
              <br />
              descargar la versión mas actualizada de:
            </p>
          </Col>
        </Row>
        <Row className="rowb" sm="12" md="12">
          <Col xs={{ size: 'auto', offset: 2 }} sm={{ size: '12', offset: 2 }} md={{ size: 'auto', offset: 4 }}>
            <Row className="rowb">
              <Col className="imgBar">
                <img src={ie} />
                <br />
                <p>15.0 +</p>
              </Col>
              <Col className="imgBar">
                <a href="https://www.google.com/intl/es/chrome/">
                  <img src={google} />
                </a>
                <br />
                <p>47.0 +</p>
              </Col>
              <Col className="imgBar">
                <a href="https://www.mozilla.org/es-ES/firefox/new/">
                  <img src={firefox} />
                </a>
                <br />
                <p>44.0 +</p>
              </Col>
              <Col className="imgBar">
                <img src={safari} />
                <br />
                <p>10.0 +</p>
              </Col>
              <Col className="imgBar">
                <a href="https://www.opera.com/download">
                  <img src={opera} />
                </a>
                <br />
                <p>50.0 +</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}
