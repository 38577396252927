import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
// redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// bugsnag
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
// styles
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/index.scss';
// providers
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import Firebase, { FirebaseContext } from './components/firebase';
import Providers from './providers';
import configureStore from './redux/store/store';
import { FirebaseAuthProvider } from './hooks/useFirebaseAuth';
import Layout from "./layouts";

import { browserIsValid } from './helpers/browser';

import UnsuppotedBrowser from './pages/Browser';
// redux persist
const { persistor, store } = configureStore();

// Load intercom if env = production
if (process.env.REACT_APP_ENV === 'production') {
  // LogRocket.init('yckf1q/handypass');
  LogRocket.init('handypass-v2/handypass');
  setupLogRocketReact(LogRocket);
}

const bugsnagClient = bugsnag({
  apiKey: '5540925ac696724b2f20759fd7c5a3ba', // handypass account
  releaseStage: process.env.REACT_APP_ENV,
  // notifyReleaseStages: ['production', 'staging'],
  beforeSend: report => {
    report.updateMetaData('LogRocket', {
      sessionURL: LogRocket.sessionURL
    });
  }
});

bugsnagClient.use(bugsnagReact, React);

axios.defaults.headers.common['x-access-token'] = process.env.REACT_APP_TOKEN_ENDPOINTS;
// wrap your entire app tree in the ErrorBoundary provided
const ErrorBoundary = bugsnagClient.getPlugin('react');

ReactDOM.render(
  <Provider store={store}>
    <FirebaseAuthProvider>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorBoundary>
          <FirebaseContext.Provider value={new Firebase()}>
            <Providers>
              {browserIsValid() ? (
                <>
                  <Layout bugsnag={bugsnagClient} logRocket={LogRocket} />
                </>
              ) : (
                <UnsuppotedBrowser />
              )}
            </Providers>
          </FirebaseContext.Provider>
        </ErrorBoundary>
      </PersistGate>
    </FirebaseAuthProvider>
  </Provider>,
  document.getElementById('root')
);
