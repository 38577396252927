import React from 'react';
//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { addOrderId } from '../../redux/actions';
// recaptcha
import Recaptcha from 'react-recaptcha';
// components
import { Button } from 'reactstrap';
import './styles.scss';
import { isUndefined } from 'lodash';

class VerifyRecaptcha extends React.Component {
  state = { loading: true, continue: true };
  verify = () => {
    this.setState({ continue: false });
  };
  cancel = () => {
    //const { organizationSlug, push } = this.props;
    // set results from action to event
    this.props.toggleModalCaptcha();

    // push(`/${organizationSlug}/${this.props.match.params.eventSlug}`);
  };
  onload = () => {};
  componentDidMount() {}

  title = ()=>{
    return isUndefined(this.props.eventName) ? 'Verificación de seguridad' : this.props.eventName
  }
  render() {
    return (
      <>
        <h3>{this.title()}</h3>
        <Recaptcha
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          render="explicit"
          onloadCallback={this.onload}
          verifyCallback={this.verify}
        />
        <p>
          Está prohibido que accedas a este sitio o compres boletos utilizando software
          automatizado.
        </p>
        <div className="flex-buttons">
          <Button color="secondary" onClick={this.cancel} className="btn-lg">
            Cancelar
          </Button>
          <Button
            color="primary"
            onClick={this.props.nextFunction}
            disabled={this.state.continue}
            className={`btn-lg ${this.state.continue}`}
          >
            Continuar
          </Button>
        </div>
      </>
    );
  }
}

export default connect(
  state => ({
    cart: state.cart,
    event: state.event
  }),
  dispatch =>
    bindActionCreators(
      {
        addOrderId
      },
      dispatch
    )
)(VerifyRecaptcha);
