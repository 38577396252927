import React from 'react';

import AuthModal from '../../components/auth_modal';
import handypassLogo from '../../assets/images/handypass_logo.svg';
import './style.scss';
import { withRouter } from 'react-router-dom';
import { useFirebaseAuth } from '../../hooks/useFirebaseAuth';

function Header({ history }) {
  const { user, isModalOpen, setIsModalOpen } = useFirebaseAuth();

  return (
    <>
      <div className="Header">
        <img
          src={handypassLogo}
          onClick={() => {
            history.push('/');
          }}
          alt="Handypass"
        />
        <button onClick={() => setIsModalOpen(true)} type="button">
          {user.name && user.name.firstName
            ? `¡Hola ${user.name && user.name.firstName}!`
            : 'Iniciar Sesión'}
        </button>
      </div>
      <AuthModal modal={isModalOpen} toggleModal={() => setIsModalOpen(!isModalOpen)} />
    </>
  );
}
export default withRouter(Header);
