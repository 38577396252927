import { cartConstants } from "../constants";

export function orderTickets(state = {}, action) {
  switch (action.type) {
    case cartConstants.ADD_TICKET:
      return {
        ...state,
        ...{ [action.payload.name]: action.payload },
      };
    case cartConstants.DELETE_TICKET:
      const name = action.payload.name;
      const objectToFilter = state;
      // polifill to filter objects
      Object.filter = (obj, predicate) =>
        Object.keys(obj)
          .filter((key) => predicate(obj[key])) // eslint-disable-next-line
          .reduce((res, key) => ((res[key] = obj[key]), res), {});
      // filter unwanted object so we can spread new object witouth deleted item
      var filtered = Object.filter(
        objectToFilter,
        (elem) => elem.name !== name,
      );
      return {
        ...filtered,
      };
    default:
      return state;
  }
}
