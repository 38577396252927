import React from "react";
import { Nav, NavItem, NavLink, Container } from "reactstrap";
import "./styles.scss";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

export default class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <Container className="clearfix">
        <footer className="footer">
          <Row>
            <Col md="12">
              <Row>
                {/* <Col md="3" sm="6" xs="6">
                  <h5>Handypass</h5>

                  <Nav vertical>
                    <NavItem>
                      <NavLink href="#">¿Quiénes Somos?</NavLink>
                    </NavItem>
                     <NavItem>
                      <NavLink href="#" className="active">
                        Nuestro Equipo
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="#">Vacantes</NavLink>
                    </NavItem>
                  </Nav>
                </Col>
                <Col md="3" sm="6" xs="6">
                  <h5>Soporte</h5>

                  <Nav vertical>
                    <NavItem>
                      <NavLink href="#">Centro de Ayuda</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="#">Cambios y devoluciones</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="#">Chat en Vivo</NavLink>
                    </NavItem>
                  </Nav>
                </Col> */}
                <Col md="3" sm="6" xs="6">
                  <h5>Legal</h5>

                  <Nav vertical>
                    <NavItem>
                      <NavLink tag={Link} to="/terms_and_conditions">Términos y Condiciones</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link} to="/privacy_policy">Aviso de Privacidad</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link} to="/purchasing_policy">Política de Venta</NavLink>
                    </NavItem>

                  </Nav>
                </Col>
                <Col md="3" sm="6" xs="6">
                  <h5>Social</h5>

                  <Nav vertical>
                    <NavItem>
                      <NavLink target="_blank" href="https://facebook.com/handypassmx">Facebook</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink target="_blank" href="https://twitter.com/handypass">Twitter</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink target="_blank" href="http://instagram.com/handypass">Instagram</NavLink>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
            </Col>
            {/*<Col md="3" className="text-right">
              <UncontrolledDropdown className="select-locale">
                <DropdownToggle color="secondary" outline size="lg" caret>
                  Español MXN
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>English MXN</DropdownItem>
                  <DropdownItem disabled>Español MXN</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>*/}
          </Row>

          <Row className="footer-last-row">
            <Col md="6" sm="12" xs="12" className="order-md-1 order-2">
              <p className="copyright">
                <span>&copy; {1900 + new Date().getYear()}, Handypass</span>
              </p>
            </Col>
            <Col md="6" sm="12" xs="12" className="order-md-2 order-1">
              <p className="cards">
                <img width="220px" src="/images/icons/cards.svg" alt="cards" />
              </p>
            </Col>
          </Row>
        </footer>
      </Container>
    );
  }
}
