export const lastSegmentOfUrl = () => {
  var pathname = window.location.pathname;
  var parts = pathname.split('/');
  var lastSegment = parts.pop() || parts.pop();
  return lastSegment;
};
export const getCurrentPurchaseFlowPath = path => {
  const parts = path.split('/');
  const lastSegment = parts.pop() || parts.pop();
  return lastSegment;
};

export const getSegmentOfURL = index => {
  var pathname = window.location.pathname;
  var parts = pathname.split('/');
  return parts[index];
};

export const ispendingPage = () => {
  window.location.pathname.includes('pending');
};
