import { paymentConstants } from "../constants";
import moment from "moment";

export function payment(state = { access_token: null }, action) {
  switch (action.type) {
    case paymentConstants.SET_PAYPAL_ACCESS_TOKEN:
      console.log("reducer payments");
      return {
        ...state,
        ...{ access_token: action.payload, loadedAt: moment() }
      };
    default:
      return state;
  }
}
