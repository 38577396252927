import React, { useState, createContext, useContext, useEffect } from 'react';
import { storageFactory } from 'storage-factory';
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import { capitalize } from '../helpers/string';

export const local = storageFactory(() => localStorage);

const StateContext = createContext();

export const FirebaseAuthProvider = ({ children }) => {
  const firebaseAuth = firebase.auth();
  const firebaseFirestore = firebase.firestore();
  const [user, _setUser] = useState(JSON.parse(local.getItem('user')) || {});
  const googleProvider = new firebase.auth.GoogleAuthProvider();
  const facebookProvider = new firebase.auth.FacebookAuthProvider();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const setUser = (_user, isGuest = false) => {
    let name = {
      firstName: 'user',
      lastName: 'user'
    };
    if (_user.name) {
      name = _user.name;
    } else if (_user.firstName) {
      name.firstName = _user.firstName;
      name.lastName = _user.lastName;
    }
    const userData = {
      name,
      email: _user.email || '',
      phone: _user.phone || '',
      isAdmin: false,
      isGuest,
      createdAt: new Date(),
      updatedAt: new Date()
    };
    return new Promise(resolve => {
      firebaseFirestore
        .collection('users')
        .doc(_user.id)
        .set(userData)
        .then(() => {
          local.setItem('user', JSON.stringify({ ...userData, id: _user.id, isGuest }));
          _setUser({ ...userData, id: _user.id, isGuest });
          resolve('Usuario creado');
        });
    });
  };

  const updateUser = _user => {
    return new Promise(resolve => {
      firebaseFirestore
        .collection('users')
        .doc(user.id)
        .update({
          ...user,
          ..._user,
          updatedAt: new Date()
        })
        .then(() => {
          local.setItem(
            'user',
            JSON.stringify({
              ...user,
              ..._user
            })
          );

          _setUser({
            ...user,
            ..._user
          });

          resolve('Usuario actualizado');
        });
    });
  };

  const updateUserLocal = _user => {
    return new Promise(resolve => {
      local.setItem(
        'user',
        JSON.stringify({
          ...user,
          ..._user
        })
      );
      _setUser({
        ...user,
        ..._user
      });
      resolve('Usuario actualizado');
    });
  };

  useEffect(() => {
    if (typeof user.isGuest === 'undefined' && user.id) {
      firebaseFirestore
        .collection('users')
        .doc(user.id)
        .get()
        .then(userSnapshot => {
          updateUserLocal({
            isGuest: userSnapshot.data().isGuest,
            conektaCustomerId: userSnapshot.data().conektaCustomerId
          });
        });
    }
  }, [user.isGuest]);

  const updateFirebaseOrder = (organizationId, eventId, orderId, userId) => {
    return new Promise(resolve => {
      firebaseFirestore
        .collection(`organizations/${organizationId}/events/${eventId}/orders`)
        .doc(orderId)
        .update({ userId })
        .then(() => {
          resolve();
        });
    });
  };

  const createUserWithEmailAndPassword = async (
    email,
    password,
    organizationId,
    eventId,
    orderId
  ) => {
    return new Promise((resolve, reject) => {
      firebaseAuth
        .createUserWithEmailAndPassword(email, password)
        .then(userCredential => {
          if (orderId) {
            updateFirebaseOrder(organizationId, eventId, orderId, userCredential.user.uid);
          }

          resolve(userCredential.user);
        })
        .catch(e => {
          let error = e;
          if (e.message === 'The email address is already in use by another account.') {
            error =
              'Error: La dirección de correo electrónico se encuentra en uso por otra cuenta.';
          }
          reject(new Error(error));
        });
    });
  };

  const signInWithEmailAndPassword = async (_email, password, organizationId, eventId, orderId) => {
    try {
      const userCredential = await firebaseAuth.signInWithEmailAndPassword(_email, password);
      const userDocument = await firebaseFirestore
        .collection('users')
        .doc(userCredential.user.uid)
        .get();
      if (orderId) {
        await updateFirebaseOrder(organizationId, eventId, orderId, userCredential.user.uid);
      }
      const { name, email, phone } = userDocument.data();

      local.setItem('user', JSON.stringify({ id: userDocument.id, name, email, phone }));
      _setUser({ id: userDocument.id, name, email, phone });
    } catch (err) {
      throw new Error(err);
    }
  };

  const signInWithGoogle = async (organizationId, eventId, orderId) => {
    return new Promise((resolve, reject) => {
      firebaseAuth.signInWithPopup(googleProvider).then(data => {
        const { uid } = data.user;

        const {
          // eslint-disable-next-line camelcase
          profile: { family_name, given_name, email },
          isNewUser,
          providerId
        } = data.additionalUserInfo;

        if (!isNewUser) {
          firebaseFirestore
            .collection('users')
            .doc(uid)
            .get()
            .then(doc => {
              if (orderId) {
                updateFirebaseOrder(organizationId, eventId, orderId, uid);
              }

              const { name, phone, conektaCustomerId = '', isGuest } = doc.data();

              local.setItem(
                'user',
                JSON.stringify({
                  id: doc.id,
                  name,
                  email,
                  phone,
                  conektaCustomerId,
                  isGuest
                })
              );
              _setUser({
                id: doc.id,
                name,
                email,
                phone,
                conektaCustomerId,
                isGuest
              });

              resolve({
                id: doc.id,
                name,
                email,
                phone,
                conektaCustomerId,
                isGuest
              });
            });
        } else {
          setUser({
            id: uid,
            name: { firstName: capitalize(given_name), lastName: capitalize(family_name) },
            email
          });
          if (orderId) {
            updateFirebaseOrder(organizationId, eventId, orderId, uid);
          }

          resolve({
            id: uid,
            name: { firstName: capitalize(given_name), lastName: capitalize(family_name) },
            email
          });
        }
      });
    });
  };

  const signInWithFacebook = async (organizationId, eventId, orderId) => {
    return new Promise((resolve, reject) => {
      firebaseAuth
        .signInWithPopup(facebookProvider)
        .then(data => {
          const { uid } = data.user;
          const {
            // eslint-disable-next-line camelcase
            profile: { first_name, last_name, email },
            isNewUser
          } = data.additionalUserInfo;

          if (!isNewUser) {
            firebaseFirestore
              .collection('users')
              .doc(uid)
              .get()
              .then(doc => {
                if (orderId) {
                  updateFirebaseOrder(organizationId, eventId, orderId, uid);
                }

                const { name, phone, conektaCustomerId, isGuest } = doc.data();
                local.setItem(
                  'user',
                  JSON.stringify({
                    id: doc.id,
                    name,
                    email,
                    phone,
                    conektaCustomerId,
                    isGuest
                  })
                );
                _setUser({
                  id: doc.id,
                  name,
                  email,
                  phone,
                  conektaCustomerId,
                  isGuest
                });

                resolve({
                  id: doc.id,
                  name,
                  email,
                  phone,
                  conektaCustomerId,
                  isGuest
                });
              });
          } else {
            setUser({
              id: uid,
              name: { firstName: capitalize(first_name), lastName: capitalize(last_name) },
              email
            });
            if (orderId) {
              updateFirebaseOrder(organizationId, eventId, orderId, uid);
            }

            resolve({
              id: uid,
              name: { firstName: capitalize(first_name), lastName: capitalize(last_name) },
              email
            });
          }
        })
        .catch(e => {
          let error = e.message;
          if (
            error ===
            'An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.'
          ) {
            error = 'Este correo esta registrado con otro metodo de inicio de sesión';
          }

          if (error === 'The popup has been closed by the user before finalizing the operation.') {
            error = '';
          }

          reject(error);
        });
    });
  };

  const signInAnonymously = async (organizationId, eventId, orderId, _user) => {
    return new Promise((resolve, reject) => {
      firebaseFirestore
        .collection('users')
        .where('email', '==', _user.email)
        .get()
        .then(users => {
          if (users.empty) {
            firebaseAuth.signInAnonymously().then(userCredential => {
              setUser(
                {
                  ...{
                    name: {
                      firstName: _user.firstName,
                      lastName: _user.lastName
                    },
                    phone: _user.phone,
                    email: _user.email
                  },
                  id: userCredential.user.uid
                },
                true
              );
              updateFirebaseOrder(organizationId, eventId, orderId, userCredential.user.uid);
              resolve('Inició sesión como invitado');
            });
          } else {
            const { name, phone, email, conektaCustomerId, isGuest } = users.docs[0].data();

            if (isGuest) {
              firebaseAuth.signInAnonymously().then(() => {
                const guestId = users.docs[0].id;

                const userO = {
                  name,
                  phone,
                  email,
                  id: guestId,
                  isGuest
                };

                if (conektaCustomerId) {
                  userO.conektaCustomerId = conektaCustomerId || '';
                }

                local.setItem('user', JSON.stringify(userO));
                _setUser(userO);

                axios
                  .post(`${process.env.REACT_APP_FUNCTIONS_URL}/orders/updateFirebaseOrder`, {
                    organizationId,
                    eventId,
                    orderId,
                    userId: guestId
                  })
                  .then(() => {
                    resolve('Inició sesión como invitado');
                  });
              });
            } else {
              reject(new Error('Por favor inicie sesión para continuar.'));
            }
          }
        });
    });
  };

  const signOut = () => {
    firebaseAuth.signOut().then(() => {
      _setUser({});
      local.setItem('user', JSON.stringify({}));
    });
  };

  return (
    <StateContext.Provider
      value={{
        signInWithGoogle,
        signInWithFacebook,
        signInAnonymously,
        signInWithEmailAndPassword,
        createUserWithEmailAndPassword,
        signOut,
        setUser,
        updateUser,
        updateUserLocal,
        user,
        isModalOpen,
        setIsModalOpen
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useFirebaseAuth = () => useContext(StateContext);

// DON'T DELETE

// const renderCaptcha = async () => {
//   const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('captcha', { size: 'invisible' });
//   recaptchaVerifier.render();
//   setApplicationVerifier(recaptchaVerifier);
// };

// const verifyPhoneNumber = async number => {
//   const provider = new firebase.auth.PhoneAuthProvider();
//   setVerificationId(await provider.verifyPhoneNumber(number, applicationVerifier));
// };

// const updatePhoneNumber = verificationCode => {
//   const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
//   currentUser.updatePhoneNumber(credential);
//   console.log(currentUser);
// };
