import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  lastSegmentOfUrl,
  getCurrentPurchaseFlowPath,
  ispendingPage
} from '../../helpers/lastSegmentOfUrl';
//import { Button } from 'reactstrap';
import AuthFirebase from '../../components/firebase/auth';
import ConektaContext from '../../providers/payments/ConektaContext';
import PaypalContext from '../../providers/payments/PaypalContext';
import AccountContext from '../../providers/accounts/AccountContext';
// helpers

import { withFirebase } from '../../components/firebase';
import { withRouter } from 'react-router';
// redux
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setEvent } from '../../redux/actions/event.actions';
import { handleAcceptedTerms } from '../../redux/actions/';
import { isMobile } from 'react-device-detect';
import Button from '../../components/Button';
import axios from 'axios';

class ContinueWithPurchaseButton extends Component {
  state = { buttonMessage: 'Continuar Compra', isLoadingButton: false, isLoadingPDF: true };
  componentDidMount() {
    this.flag = true;
  }
  componentDidUpdate() {
    if (!this.props.event.loading && this.flag) {
      this.flag = false;
      this.handleEnableBtnPDF();
    }
  }
  handleEnableBtnPDF = async () => {
    const { eventId, organizationId } = this.props.event;
    const { orderId } = this.props.match.params;
    if (orderId) {
      const orderDoc = await this.props.firebase.getOrder(organizationId, eventId, orderId);
      orderDoc.onSnapshot(async order => {
        if (order.exists) {
          const { orderStatus, fulfilled } = order.data();
          if (orderStatus === 'PAID' && fulfilled) {
            this.setState({
              isLoadingPDF: false
            });
          }
        }
      });
    }
  };
  payOrder = async () => {
    this.setState({ isLoadingButton: true });
    try {
      const { organizationId, eventId, userId } = this.props.order;
      const { orderId } = this.props;
      const { eventSlug, organizationSlug } = this.props.match.params;
      const now = new Date();

      const paymentData = {
        authCode: userId,
        confirmationToken: `COURTESY_${now.getTime()}`,
        createdAt: now,
        eventId: eventId,
        fee: 0,
        orderId: orderId,
        paidAt: now,
        status: 'PAID',
        provider: 'COURTESY',
        orderType: 'ONLINE',
        paymentType: 'courtesy'
      };

      const payment = await this.props.firebase
        .paymentsCollection(organizationId, eventId, orderId)
        .add(paymentData);
      await this.props.firebase.orderDoc(organizationId, eventId, orderId).update({
        orderStatus: 'PAID',
        paymentId: payment.id,
        updatedAt: now
      });
      this.props.history.push(`/${organizationSlug}/${eventSlug}/order-confirmation/${orderId}`);
    } catch (error) {
      this.setState({ isLoadingButton: false });
      console.log(error);
    }
  };
  render() {
    const { customText } = this.props;
    const { eventSlug, organizationSlug, orderId } = this.props.match.params;
    const { push } = this.props.history;
    const { path } = this.props.match;
    return (
      <>
        {lastSegmentOfUrl() === 'extra-services' && (
          <ConektaContext.Consumer>
            {context => (
              <>
                <Button
                  color="primary"
                  className="btn-lg"
                  block
                  disabled={this.props.orderDetails[this.props.event.eventId] ? false : true}
                  onClick={() => {
                    const { user } = this.props;
                    if (user.phone) {
                      push(`/${organizationSlug}/${eventSlug}/payment-methods`);
                    } else {
                      push(`/${organizationSlug}/${eventSlug}/create-account`);
                    }
                  }}
                >
                  {customText ? customText : 'Continuar Compra'}
                </Button>
              </>
            )}
          </ConektaContext.Consumer>
        )}
        {lastSegmentOfUrl() === 'form' && (
          <ConektaContext.Consumer>
            {context => (
              <>
                <Button
                  color="primary"
                  className="btn-lg"
                  block
                  disabled={this.props.answers ? false : true}
                  onClick={() => push(`/${organizationSlug}/${eventSlug}/extra-services`)}
                >
                  {customText ? customText : 'Continuar Compra'}
                </Button>
              </>
            )}
          </ConektaContext.Consumer>
        )}

        {lastSegmentOfUrl() === 'payment-methods' && (
          <>
            {this.props.selectedPayment === 'CONEKTA' && (
              <ConektaContext.Consumer>
                {context => (
                  <>
                    {/* if order has courtesies, advance anyways */}
                    {this.props.order &&
                    this.props.order.total === 0 &&
                    this.props.orderCourtesies.length > 0 ? (
                      <Button
                        color
                        isLoading={this.state.isLoadingButton}
                        disabled={!this.props.acceptedTerms}
                        onClick={this.payOrder}
                      >
                        Continuar con cortesía
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        className="btn-lg"
                        block
                        isLoading={context.processingPayment}
                        disabled={context.isFormInvalid()}
                        onClick={() => {
                          // const { user } = this.props;
                          context.makePayment(push, eventSlug, organizationSlug);
                        }}
                      >
                        {context.buttonMessage}
                      </Button>
                    )}
                  </>
                )}
              </ConektaContext.Consumer>
            )}
            {this.props.selectedPayment === 'PAYPAL_PLUS' && (
              <PaypalContext.Consumer>
                {context => (
                  <>
                    {/* if order has courtesies, advance anyways */}

                    {this.props.order &&
                    this.props.order.total === 0 &&
                    this.props.orderCourtesies.length > 0 ? (
                      <Button color isLoading={this.state.isLoadingButton} onClick={this.payOrder}>
                        Continuar con cortesía
                      </Button>
                    ) : (
                      <Button
                        color
                        isLoading={this.props.acceptedTerms ? this.props.disableContinue : false}
                        onClick={() => {
                          this.props.setIsButtonDisabled(true);
                          this.props.paypalSelectionPage.doContinue();
                          return false;
                        }}
                        disabled={this.props.acceptedTerms ? this.props.disableContinue : true}
                      >
                        Continuar compra
                      </Button>
                    )}
                  </>
                )}
              </PaypalContext.Consumer>
            )}
            {this.props.selectedPayment === 'OXXO_PAY' && (
              <ConektaContext.Consumer>
                {context => (
                  <>
                    {/* if order has courtesies, advance anyways */}
                    {this.props.order && this.props.order.total > 0 && (
                      <Button
                        color="primary"
                        className="btn-lg"
                        block
                        isLoading={context.processingPayment}
                        disabled={!this.props.acceptedTerms}
                        onClick={() => {
                          context.makePaymentWithOxxo(push, eventSlug, organizationSlug);
                        }}
                      >
                        {context.buttonMessage}
                      </Button>
                    )}
                  </>
                )}
              </ConektaContext.Consumer>
            )}
          </>
        )}
        {getCurrentPurchaseFlowPath(path) === ':orderId' && !ispendingPage() && (
          <Button
            color="primary"
            className="btn-lg"
            block
            disabled={this.state.isLoadingPDF}
            onClick={() => {
              const { eventId, organizationId } = this.props.event;
              window.open(
                `${process.env.REACT_APP_FUNCTIONS_URL}/pdf/generate/${organizationId}/${eventId}/${orderId}`
              );
            }}
          >
            Descargar PDF
          </Button>
        )}
      </>
    );
  }
}

ContinueWithPurchaseButton.contextType = AccountContext;
export default compose(
  withRouter,
  withFirebase,
  connect(
    state => ({
      event: state.event,
      orderDetails: state.orderDetails,
      acceptedTerms: state.cart.acceptedTerms,
      answers: state.form.answers
    }),
    dispatch => bindActionCreators({ setEvent, handleAcceptedTerms }, dispatch)
  )
)(ContinueWithPurchaseButton);
