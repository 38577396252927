import React, { useState, useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import ReactPlaceholder from 'react-placeholder';
import qs from 'query-string';
import 'react-placeholder/lib/reactPlaceholder.css';
import './styles.scss';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  InputGroup,
  InputGroupAddon,
  FormGroup,
  FormFeedback
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isMobile } from 'react-device-detect';
import LogRocket from 'logrocket';
import { centsToDecimal } from '../../helpers/currency';
import VerifyRecaptcha from '../recaptcha';
import { validateRecaptcha, addOrderId, resetOrderId } from '../../redux/actions';
import { parsePayload } from '../../helpers/parsePayload';
import { withFirebase } from '../firebase';
import CounterButtons from '../CounterButtons';
import Tooltip from '../Tooltip';
import { useFirebaseAuth } from '../../hooks/useFirebaseAuth';
import moment from 'moment-timezone';
import Calendar from 'react-calendar';


const showdown = require('showdown');

const converter = new showdown.Converter();

function BuyTicketTypes({
  organizationId,
  eventId,
  orderDetails,
  firebase,
  organizationSlug,
  eventSlug,
  addOrderId,
  recaptchaValid,
  history,
  resetOrderId,
  courtesies,
  startsAt,
  endsAt,
  selectedDate,
  setSelectedDate,
  eventName
}) {
  const [modalCaptcha, setModalCaptcha] = useState(false);
  const [modalVerifying, setModalVerifying] = useState(false);
  const [modalVerifyingMessage, setModalVerifyingMessage] = useState('Verificando disponibilidad');
  const [verifying, setVerifying] = useState(true);
  const [courtesy, setCourtesy] = useState(false);
  const [courtesyWasApplied, setCourtesyWasApplied] = useState(false);
  const [courtesyCode, setCourtesyCode] = useState('');
  const [filteredPhases, setFilteredPhases] = useState([]);
  const [phases, setPhases] = useState([]);
  const [phase, setPhase] = useState({
    modal: false,
    phaseName: '',
    phaseDescription: ''
  });
  const [ticketsObject, setTicketsObject] = useState({});
  const [errorCourtesy, setErrorCourtesy] = useState(false);
  const [errorCourtesyMessage, setErrorCourtesyMessage] = useState('');
  const [hasNotTickets, setHasNotTickets] = useState(false);
  const [rateLimitModal, setRateLimitModal] = useState(false);
  const [messageRateLimit, setmessageRateLimit] = useState('');
  const [titleAlert, setTitleAlert] = useState('');
  const [isReady, setIsReady] = useState(false);
  const { user, setIsModalOpen } = useFirebaseAuth();
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const now = useMemo(() => new Date(), []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.async = true;
    document.body.appendChild(script);
    getOrder();
  }, []);

  useEffect(() => {
    if (moment(startsAt.toDate()).isAfter(new Date())) {
      setSelectedDate(startsAt.toDate());
    }
  }, [startsAt]);

  useEffect(() => {
    getPhases();
  }, [selectedDate]);

  const getOrder = async () => {
    if (orderDetails[eventId]) {
      const orderRaw = await firebase.getOrder(
        organizationId,
        eventId,
        orderDetails[eventId].orderId
      );
      const order = await orderRaw.get();
      if (order.data().orderStatus !== 'PAID') {
        loadOrderTickets(orderDetails[eventId].orderId);
      } else {
        resetOrderId({});
      }
    }
  };

  const getPhases = async () => {
    const phasesCollectionRef = await firebase.getPhasesCollection(organizationId, eventId);
    const phasesRef = await phasesCollectionRef.get();
    const phasesCollection = phasesRef.docs.map(_phase => ({ id: _phase.id, ..._phase.data() }));

    const phasesNoExtraService = phasesCollection
      .filter(_phase => validPhases(_phase))
      .filter(_phase => !_phase.extraService)
      .sort((a, b) => a.sortOrder - b.sortOrder);

    setPhases(phasesCollection);
    setFilteredPhases(phasesNoExtraService);
    setIsReady(true);
  };

  const availablePhase = phase => {
    return (
      phase.soldCount + phase.onHold <= phase.quantity &&
      phase.status === 'ACTIVE' &&
      now < phase.endsAt.toDate()
    );
  };

  const validPhases = phase => {
    return !phase.boxOffice && availablePhase(phase) && isBetween(phase);
  };

  const isBetween = phase => {
    return moment(selectedDate).isBetween(
      phase.startsAt.toDate(),
      phase.endsAt.toDate(),
      'minutes',
      '[]'
    );
  };

  const toggleModal = (name, description) => {
    setPhase({
      modal: !phase.modal,
      phaseName: name,
      phaseDescription: description
    });
  };

  const handleChangeDate = date => {
    setSelectedDate(date);
  };
  const toggleCaptcha = () => {
    setModalCaptcha(!modalCaptcha);
  };
  const toggleVerifyingAvailability = () => {
    setModalVerifying(!modalVerifying);
  };

  const loadOrderTickets = async orderId => {
    var orderTickets = await firebase.getOrderTickets(organizationId, eventId, orderId);
    orderTickets.onSnapshot(querySnapshot => {
      const object = {};
      querySnapshot.forEach(function(orderTicket) {
        const { name, quantity, phaseId } = orderTicket.data();
        object[phaseId] = object[phaseId] || {};
        object[phaseId] = { name, quantity, phaseId };
      });
      setTicketsObject(object);
    });
  };

  const verifyRecaptchaBeforeProceed = async () => {
    // we verify recaptcha is valid, if is valid, create pending order, if not, open modal to verify recaptcha
    // in modal window we call verifyAvailability after recaptcha is valid so we work withsome recursion here.
    recaptchaValid ? createOrUpdatePendingOrder() : toggleCaptcha();
  };

  const haveCourtesy = () => {
    setCourtesy(!courtesy);
  };

  const handleHideAlertRateLimitModal = () => {
    setRateLimitModal(!rateLimitModal);
  };

  const handleSubmitCourtesy = async () => {
    if (courtesyCode.length < 2) return window.alert('Debes proporcionar un código válido');

    let payload = {
      orgId: organizationId,
      eventId: eventId,
      code: courtesyCode,
      userId: '',
      quantity: 1,
      orderType: 'ONLINE'
    };
    if (orderDetails[eventId]) {
      payload.orderId = orderDetails[eventId].orderId;
    }
    //process.env.REACT_APP_FUNCTIONS_URL

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_FUNCTIONS_URL}/courtesies/redeem`,
        payload
      );
      setCourtesyWasApplied(true);
      addOrderId({
        eventId: eventId,
        orderId: response.data.orderId
      });
    } catch (error) {
      const {
        response: { data }
      } = error;
      switch (data.error) {
        case 'INVALID_CODE':
          setErrorCourtesy(true);
          setErrorCourtesyMessage('Código no válido');
          break;
        case 'USED_CODE':
          setErrorCourtesy(true);
          setErrorCourtesyMessage('Código usado');
          break;
        case 'COURTESY_NOT_VALID':
          setErrorCourtesy(true);
          setErrorCourtesyMessage('Cortesía no válida');
          break;
        case 'ORDER_HAS_COURTESIES':
          setErrorCourtesy(true);
          setErrorCourtesyMessage(data.error);
          break;
        default:
          setErrorCourtesy(true);
          setErrorCourtesyMessage('No podemos atender la solicitud en estos momentos');
          break;
      }
    }
  };
  const getLogRockeToken = () => {
    return new Promise((resolve, reject) => {
      if (['staging', 'development'].includes(process.env.REACT_APP_ENV)) {
        resolve('development_mode');
      }
      LogRocket.getSessionURL(url => {
        resolve(url);
      });
    });
  };

  const continueToExtraServices = async () => {
    const productsCollectionRef = await firebase.getProductsCollection(organizationId, eventId);
    const productsRef = await productsCollectionRef.get();
    const productsCollection = productsRef.docs.map(p => ({ id: p.id, ...p.data() }));
    const _products = productsCollection.filter(product => availableProduct(product));
    const phasesWithFlagES = phases
      .filter(_phase => validPhases(_phase))
      .filter(_phase => _phase.extraService);
    const eventDoc = await firebase.eventDoc(organizationId, eventId);
    const event = await eventDoc.get();
    const { donation } = event.data();

    return _products.length > 0 || donation || phasesWithFlagES.length > 0;
  };

  const availableProduct = product => {
    return product.status === 'ACTIVE' && product.soldCount + product.onHold < product.quantity;
  };

  const createOrUpdatePendingOrder = async () => {
    setModalVerifyingMessage('Verificando disponibilidad');
    setVerifying(true);
    toggleVerifyingAvailability();
    let LogRocketSessionUrl = await getLogRockeToken();
    const orderId =
      typeof orderDetails[eventId] !== 'undefined' ? orderDetails[eventId].orderId : false;
    // prettier-ignore
    const payload = parsePayload(
      ticketsObject,
      eventId,
      organizationId,
      "ONLINE",
      user.id||"",
      orderId,
      LogRocketSessionUrl
    );
    const { search } = history.location;
    if (orderId === false && search.length > 0) {
      const { referral } = qs.parse(search);
      payload.cart.referral = referral;
    }
    try {
      const {
        data: { status, rateLimit }
      } = await axios.post(
        `${process.env.REACT_APP_FUNCTIONS_URL}/cart/verify_rate_limit`,
        payload
      );

      if (status) {
        const response = await axios.post(`${process.env.REACT_APP_FUNCTIONS_URL}/cart`, payload);

        addOrderId({
          eventId: eventId,
          orderId: response.data.orderId
        });

        if (response.data.forms) {
          history.push(`/${organizationSlug}/${eventSlug}/form`);
        } else if (await continueToExtraServices()) {
          history.push(`/${organizationSlug}/${eventSlug}/extra-services`);
        } else if (
          !user.name ||
          !user.phone ||
          (user.name && !user.name.firstName) ||
          (user.name && !user.name.lastName) ||
          !user.email
        ) {
          history.push(`/${organizationSlug}/${eventSlug}/create-account`);
        } else {
          history.push(`/${organizationSlug}/${eventSlug}/payment-methods`);
        }
      } else {
        throw {
          response: {
            data: {
              error: 'RATE_LIMIT',
              message: `El monto máximo por transacción es de $${centsToDecimal(rateLimit).toFixed(
                2
              )} MXN , por favor reduzca el número de articulos en el carrito e intente de nuevo. Si necesita realizar una compra mayor al limite de trasacción le recomendamos hacer multiples compras. `
            }
          }
        };
      }
    } catch (error) {
      const {
        response: { data }
      } = error;
      setVerifying(true);
      setModalVerifying(false);
      if (data.error === 'RATE_LIMIT') {
        setTitleAlert('Tu orden supera el limite');
        setmessageRateLimit(data.message);
        handleHideAlertRateLimitModal();
        return;
      }
      setTitleAlert('Sin disponibilidad');
      setmessageRateLimit(
        'No hay suficientes boletos disponibles, por favor reduzca la cantidad de boletos e intente de nuevo'
      );
      handleHideAlertRateLimitModal();
    }
  };

  const addTicket = phase => {
    const newTicketObject = Object.assign({}, ticketsObject);
    newTicketObject[phase.id] = newTicketObject[phase.id] || {
      name: phase.name,
      quantity: 0,
      phaseId: phase.id
    };
    newTicketObject[phase.id].quantity += 1;
    setTicketsObject(newTicketObject);
  };

  const removeTicket = phase => {
    const newTicketObject = Object.assign({}, ticketsObject);
    newTicketObject[phase.id] = newTicketObject[phase.id] || {
      name: phase.name,
      quantity: 0,
      phaseId: phase.id
    };

    if (newTicketObject[phase.id].quantity > 0) {
      newTicketObject[phase.id].quantity -= 1;
    }

    setTicketsObject(newTicketObject);
  };

  useEffect(() => {
    /* prettier-ignore */
    const hasNotTickets = Object.values(ticketsObject).filter(ticket => ticket.quantity > 0).length === 0;
    setHasNotTickets(hasNotTickets);
  });

  const noTicketsMessage = () => {
    if (organizationSlug === 'luztopia') {
      return 'Este día Luztopía está cerrado. ¡Selecciona otra fecha!';
    }
    return 'En este momento no tenemos boletos para este evento';
  };

  return isReady ? (
    <>
      {rateLimitModal && (
        <SweetAlert error title={titleAlert} onConfirm={handleHideAlertRateLimitModal}>
          {messageRateLimit}
        </SweetAlert>
      )}
      <Calendar
        minDate={startsAt.toDate()}
        maxDate={endsAt.toDate()}
        onClickDay={handleChangeDate}
        value={selectedDate}
      />
      <hr className="mt0" />
      <Row>
        <Col lg="6" md="6" sm="6" xs="6">
          <p className="small">Tipo de boleto</p>
        </Col>
        <Col lg="3" md="3" sm="3" xs="3" className="mw-22">
          <p className="small">Precio</p>
        </Col>
        <Col lg="3" md="3" sm="3" xs="3">
          <p className="small"> Cantidad</p>
        </Col>
      </Row>
      <hr className="mt0" />
      {filteredPhases.map((phase, i) => (
        <Row className="ticket-types" key={i}>
          <Col lg="6" md="6" sm="6" xs="6">
            <h4>{phase.name}</h4>
            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: converter.makeHtml(phase.description.substring(0, 150))
              }}
            />
          </Col>
          <Col lg="3 flex" md="3" sm="3" xs="3" className="mw-22">
            <h4>${centsToDecimal(phase.price)}</h4>
            {'  '}
            <Tooltip {...phase} />
          </Col>
          <Col lg="3 flex" md="3" sm="3" xs="3">
            {phase.status === 'COMING_SOON' ? (
              isMobile ? (
                <h4 className="proximamente">Próx..</h4>
              ) : (
                <h4 className="proximamente">Próximamente</h4>
              )
            ) : phase.soldCount + phase.onHold >= phase.quantity ? (
              <h4 className="proximamente">SOLD OUT</h4>
            ) : (
              // prettier-ignore
              <CounterButtons
                    item={phase}
                    add={addTicket}
                    remove={removeTicket}
                    count={
                      (ticketsObject[phase.id] &&
                        ticketsObject[phase.id].quantity) || 0
                    }
                  />
            )}
          </Col>
        </Row>
      ))}
      {filteredPhases.length === 0 && <h2>{noTicketsMessage()}</h2>}
      <hr className="mt0 mb37" />
      <Row>
        <Col md="6">
          {courtesies &&
            (courtesyWasApplied ? (
              <FormGroup>
                <Input disabled={true} valid value={courtesyCode} />
                <FormFeedback className="text-center" valid>
                  Cortesía aplicada
                </FormFeedback>
              </FormGroup>
            ) : (
              <>
                <p className="orange courtesy" onClick={haveCourtesy}>
                  ¿Tienes un código de cortesía?
                </p>
                {courtesy && (
                  <>
                    {errorCourtesy ? (
                      <FormGroup>
                        <Input
                          value={courtesyCode}
                          onClick={() => {
                            setErrorCourtesy(false);
                          }}
                          readOnly
                          invalid
                        />
                        <FormFeedback className="text-center">{errorCourtesyMessage}</FormFeedback>
                      </FormGroup>
                    ) : (
                      <>
                        <InputGroup className="add_courtesy">
                          <Input
                            value={courtesyCode}
                            type="text"
                            name="courtesyCode"
                            placeholder="Agrega tu código de cortesía"
                            onChange={e => setCourtesyCode(e.target.value)}
                          />
                          <InputGroupAddon addonType="append">
                            <Button color="primary" onClick={handleSubmitCourtesy}>
                              Canjear cortesía
                            </Button>
                          </InputGroupAddon>
                        </InputGroup>
                      </>
                    )}
                  </>
                )}
              </>
            ))}
        </Col>
        <Col md="6">
          {orderDetails[eventId] ? (
            <Button
              color="primary"
              onClick={verifyRecaptchaBeforeProceed}
              className={`btn-lg btn-align`}
            >
              Continuar orden
            </Button>
          ) : (
            <Button
              color="primary"
              disabled={hasNotTickets}
              onClick={verifyRecaptchaBeforeProceed}
              className={`btn-lg btn-align ${hasNotTickets ? 'disabled' : ''}`}
            >
              Comprar Boletos
            </Button>
          )}
        </Col>
      </Row>
      <hr className="mt35" />
      <Modal
        isOpen={phase.modal}
        toggle={() => {
          setPhase({ ...phase, modal: !phase.modal });
        }}
      >
        <ModalHeader
          toggle={() => {
            setPhase({ ...phase, modal: !phase.modal });
          }}
        >
          {phase.phaseName}
        </ModalHeader>
        <ModalBody>
          <div
            dangerouslySetInnerHTML={{
              __html: converter.makeHtml(phase.phaseDescription)
            }}
          />
        </ModalBody>
      </Modal>
      <Modal toggle={toggleCaptcha} isOpen={modalCaptcha} className="captcha-modal">
        <ModalBody>
          <VerifyRecaptcha
            eventName={eventName}
            eventSlug={eventSlug}
            toggleModalCaptcha={toggleCaptcha}
            organizationSlug={organizationSlug}
            nextFunction={createOrUpdatePendingOrder}
            push={history.push}
          />
        </ModalBody>
      </Modal>
      <Modal isOpen={modalVerifying} toggle={toggleVerifyingAvailability} className="captcha-modal">
        <ModalBody>
          <h3>{modalVerifyingMessage}</h3>
          {verifying && <FontAwesomeIcon icon={faSpinner} className="fa-spin fa-5x svg-center" />}
        </ModalBody>
      </Modal>
    </>
  ) : (
    <ReactPlaceholder ready={false} type="text" rows={12}>
      {' '}
    </ReactPlaceholder>
  );
}

export default compose(
  withFirebase,
  withRouter,
  connect(
    state => ({
      orderDetails: state.orderDetails,
      orderTickets: state.orderTickets,
      recaptchaValid: state.recaptcha.recaptchaValid
    }),
    dispatch =>
      bindActionCreators(
        {
          validateRecaptcha,
          addOrderId,
          resetOrderId
        },
        dispatch
      )
  )
)(BuyTicketTypes);
