import { eventConstants } from "../constants";

export function event(state = { loading: true }, action) {
  switch (action.type) {
    case eventConstants.SET_EVENT:
      return {
        ...state,
        ...{
          ...action.payload,
          loading: action.loading
        }
      };
    default:
      return state;
  }
}
