import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import LogInForm from './login';
import ResetPasswordForm from './reset_password';
import CreateAccount from './create_account';
import PhoneAuth from './PhoneAuth';
import UserProfile from './UserProfile';

import { useFirebaseAuth } from '../../hooks/useFirebaseAuth';

import './styles.scss';

const AuthModal = ({ modal, toggleModal, type = 'LOGIN' }) => {
  const [title, setTitle] = useState('');
  const [view, setView] = useState('LOGIN');
  const { user } = useFirebaseAuth();
  useEffect(() => {
    const title = type === 'LOGIN' ? 'Entrar a tu cuenta' : 'Registrate';
    setTitle(title);
    setView(type);
  }, [type]);

  useEffect(() => {
    if (user.id) {
      setTitle('Perfil');
      setView('USER PROFILE');
    } else {
      setTitle('Entrar a tu cuenta');
      setView('LOGIN');
    }
  }, [user.id]);

  const changeMode = (_view, _title) => {
    setTitle(_title);
    setView(_view);
  };
  return (
    <>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{title}</ModalHeader>
        <ModalBody>
          {view === 'LOGIN' && (
            <LogInForm mode={view} toggleModal={toggleModal} changeMode={changeMode} />
          )}

          {view === 'RECOVERY_PASSWORD' && (
            <ResetPasswordForm mode={view} changeMode={changeMode} />
          )}

          {view === 'CREATE_ACCOUNT' && (
            <CreateAccount toggleModal={toggleModal} mode={view} changeMode={changeMode} />
          )}

          {view === 'PHONE_AUTH' && <PhoneAuth toggleModal={toggleModal} changeMode={changeMode} />}

          {view === 'USER PROFILE' && (
            <UserProfile toggleModal={toggleModal} changeMode={changeMode} />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default AuthModal;
