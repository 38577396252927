import React, { useEffect, useState } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import ReactPhoneInput from 'react-phone-input-2';
import { useFormik } from 'formik';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import * as yup from 'yup';
import { withFirebase } from '../firebase';
import 'react-phone-input-2/dist/style.css';
import { setUserData, userSignedIn } from '../../redux/actions/user.actions';
import { capitalize, Lowcase } from '../../helpers/string';
import './styles.scss';
import { sendEventPixel } from '../../helpers/pixel';
import { useFirebaseAuth } from '../../hooks/useFirebaseAuth';
import { FacebookLoginButton, GoogleLoginButton } from 'react-social-login-buttons';

const Register = ({ event: { organizationId, eventId }, orderDetails, history }) => {
  const {
    signInAnonymously,
    user,
    updateUser,
    createUserWithEmailAndPassword,
    setUser,
    signInWithGoogle,
    signInWithFacebook
  } = useFirebaseAuth();
  const { orderId } = orderDetails[eventId] || {};
  const [intent, setIntent] = useState('BuyAsGuest');
  const [error, setError] = useState('');
  const [country, setCountry] = useState('mx');

  const defaultValidationSchema = {
    firstName: yup
      .string()
      .min(2)
      .label('Nombre')
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/,
        'Este nombre contiene algunos caracteres no permitidos.'
      )
      .required('¿Cómo te llamas?'),
    lastName: yup
      .string()
      .min(2)
      .label('Apellido')
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/,
        'Este nombre contiene algunos caracteres no permitidos.'
      )
      .required('¿Cómo te llamas?'),
    email: yup
      .string()
      .required('Usarás esta información al entrar a tu cuenta.')
      .email('Ingresa una dirección de correo electrónico válido.'),
    phone: yup
      .string()
      .min(10, 'Ingresa un número de celular de 10 digitos')
      .required('Usarás esta información al entrar a tu cuenta.')
  };
  const [yupObject, setYupObject] = useState(defaultValidationSchema);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    touched,
    setTouched
  } = useFormik({
    initialValues: {
      firstName: (user.name && user.name.firstName) || '',
      lastName: (user.name && user.name.lastName) || '',
      email: user.email || '',
      confirmEmail: user.email || '',
      phone: user.phone || '',
      country: 'mx'
    },
    onSubmit: async values => {
      const { firstName, lastName } = values;
      try {
        if (intent === 'CreateAccount') {
          const user = await createUserWithEmailAndPassword(
            Lowcase(values.email),
            values.password,
            organizationId,
            eventId,
            orderId
          );
          setUser({
            id: user.uid,
            ...{
              name: {
                firstName: capitalize(firstName),
                lastName: capitalize(lastName)
              },
              phone: `+${values.countryCode}${values.phone}`,
              email: Lowcase(values.email)
            }
          });
        }
        if (intent === 'BuyAsGuest') {
          await signInAnonymously(organizationId, eventId, orderId, {
            firstName: capitalize(firstName),
            lastName: capitalize(lastName),
            phone: values.phone,
            email: Lowcase(values.email)
          });
        }
        if (intent === 'CompleteUserData') {
          await updateUser({
            email: Lowcase(values.email),
            phone: values.phone,
            name: { firstName: capitalize(values.firstName), lastName: capitalize(values.lastName) }
          });
        }
      } catch (e) {
        setError(e.message);
      }
    },
    validationSchema: yup.object(yupObject)
  });
  useEffect(() => {
    if (country !== 'mx') {
      setYupObject({
        ...defaultValidationSchema,
        phone: yup.string().required('Usarás esta información al entrar a tu cuenta.')
      });
    } else {
      setYupObject({
        ...defaultValidationSchema
      });
    }
  }, [country]);
  useEffect(() => {
    sendEventPixel('InitiateCheckout');

    if (
      user.id &&
      (!user.name ||
        !user.email ||
        !user.phone ||
        (user.name && !user.name.firstName) ||
        (user.name && !user.name.lastName))
    ) {
      setIntent('CompleteUserData');
    }

    if (
      user.id &&
      user.phone &&
      user.name &&
      user.name.firstName &&
      user.name &&
      user.name.lastName &&
      user.email
    ) {
      history.replace('payment-methods');
    }
  }, [user.phone, user.name && user.name.firstName, user.name && user.name.lastName, user.email]);

  useEffect(() => {
    if (intent === 'BuyAsGuest') {
      setYupObject({
        ...defaultValidationSchema,
        confirmEmail: yup
          .string()
          .required('Vuelve a escribir tu dirección de correo electrónico.')
          .email()
          .oneOf(
            [yup.ref('email'), null],
            'Tus direcciones de correo no coinciden. Inténtalo de nuevo.'
          )
      });
    }

    if (intent === 'CompleteUserData') {
      setYupObject({
        ...defaultValidationSchema,
        confirmEmail: yup
          .string()
          .required('Vuelve a escribir tu dirección de correo electrónico.')
          .email()
          .oneOf(
            [yup.ref('email'), null],
            'Tus direcciones de correo no coinciden. Inténtalo de nuevo.'
          )
      });
    }

    if (intent === 'CreateAccount') {
      setYupObject({
        ...defaultValidationSchema,
        password: yup
          .string()
          .min(8)
          .required('Ingresa una contraseña válida')
      });
    }
  }, [intent]);

  return (
    <div className="Register">
      <div className="header">
        <h3>{intent === 'CompleteUserData' ? 'Completa tus datos' : 'Registrate'}</h3>
      </div>
      <div className="content">
        <>
          {error && <Alert color="danger">{error}</Alert>}

          <Form autoComplete="off" onSubmit={handleSubmit} className="form">
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="userName">Nombre (s)</Label>

                  <Input
                    type="text"
                    name="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={capitalize(values.firstName || '')}
                    autoComplete="off"
                  />

                  {errors.firstName && touched.firstName && (
                    <p className="text-error">{errors.firstName}</p>
                  )}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="userLastName">Apellidos</Label>

                  <Input
                    type="text"
                    name="lastName"
                    onChange={handleChange}
                    value={capitalize(values.lastName || '')}
                    onBlur={handleBlur}
                    autoComplete="nope"
                  />

                  {errors.lastName && touched.lastName && (
                    <p className="text-error">{errors.lastName}</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label for="userEmail">Correo electrónico</Label>

              <Input
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                autoComplete="nope"
              />

              {errors.email && touched.email && <p className="text-error">{errors.email}</p>}
            </FormGroup>

            <FormGroup>
              <Label for="userEmail">Confirma tu correo electrónico</Label>
              <Input
                type="email"
                name="confirmEmail"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirmEmail}
                autoComplete="nope"
              />
              {errors.confirmEmail && touched.confirmEmail && (
                <p className="text-error">{errors.confirmEmail}</p>
              )}
            </FormGroup>

            <FormGroup>
              <Label for="exampleEmail">Número de celular</Label>
              <ReactPhoneInput
                defaultCountry={values.country}
                onlyCountries={['mx']}
                onChange={(value, data) => {
                  setCountry(data.countryCode);
                  setFieldValue('country', data.countryCode);
                  setFieldValue('countryCode', data.dialCode);
                  setFieldValue(
                    'phone',
                    value
                      .replace(/[^0-9]+/g, '')
                      .slice(data.dialCode.length)
                      .substring(0, 10)
                  );
                }}
                onBlur={() => {
                  setTouched({ ...touched, phone: true });
                }}
                value={values.phone}
                inputExtraProps={{
                  required: true
                }}
                regions={[
                  'north-america',
                  'south-america',
                  'central-america',
                  'carribean',
                  'european-union'
                ]}
              />
              {errors.phone && touched.phone && <p className="text-error">{errors.phone}</p>}
            </FormGroup>
            {intent === 'CreateAccount' && (
              <FormGroup>
                <Label for="examplePassword">Contraseña</Label>
                <Input
                  type="password"
                  name="password"
                  value={values.password || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.password && touched.password && (
                  <p className="text-error">{errors.password}</p>
                )}
              </FormGroup>
            )}
            {intent === 'BuyAsGuest' && (
              <button
                type="submit"
                className="btn-lg btn-block btn btn-primary sign-in-anonymously-button"
              >
                Pagar como invitado
              </button>
            )}
            {intent === 'CreateAccount' && (
              <button
                type="submit"
                className="create-account-button"
                onClick={() => setIntent('CreateAccount')}
              >
                Crear Cuenta
              </button>
            )}
            {intent === 'CompleteUserData' && (
              <button
                type="submit"
                className="btn-lg btn-block btn btn-primary sign-in-anonymously-button"
              >
                Actualizar y Continuar
              </button>
            )}
          </Form>
          {!user.id && (
            <>
              {!(intent === 'BuyAsGuest') && (
                <button
                  type="button"
                  className="btn-lg btn-block btn btn-primary sign-in-anonymously-button"
                  onClick={() => setIntent('BuyAsGuest')}
                >
                  Pagar como invitado
                </button>
              )}
              {!(intent === 'CreateAccount') && (
                <button
                  type="button"
                  className="create-account-button"
                  onClick={() => setIntent('CreateAccount')}
                >
                  Crear Cuenta
                </button>
              )}

              <span>Ó entrar con tu cuenta</span>
              <div className="social-medias-login">
                <FacebookLoginButton
                  className="btn-login"
                  onClick={async () => {
                    try {
                      const response = await signInWithFacebook(organizationId, eventId, orderId);
                      if (
                        response.name &&
                        response.name.firstName &&
                        response.name.lastName &&
                        response.phone &&
                        response.email
                      ) {
                        history.replace('payment-methods');
                      } else {
                        setFieldValue('firstName', response.name.firstName || '');
                        setFieldValue('lastName', response.name.lastName || '');
                        setFieldValue('email', response.email || '');
                        setFieldValue('confirmEmail', response.email || '');
                        setIntent('CompleteUserData');
                      }
                    } catch (e) {
                      setError(e);
                    }
                  }}
                >
                  Facebook
                </FacebookLoginButton>
                <GoogleLoginButton
                  className="btn-login"
                  onClick={() => {
                    signInWithGoogle(organizationId, eventId, orderId).then(user => {
                      if (
                        user.phone &&
                        user.name &&
                        user.name.firstName &&
                        user.name &&
                        user.name.lastName
                      ) {
                        history.replace('payment-methods');
                      } else {
                        setFieldValue('firstName', (user.name && user.name.firstName) || '');
                        setFieldValue('lastName', (user.name && user.name.lastName) || '');
                        setFieldValue('email', user.email || '');
                        setFieldValue('confirmEmail', user.email || '');
                        setIntent('CompleteUserData');
                      }
                    });
                  }}
                >
                  Google
                </GoogleLoginButton>
              </div>
              <span>
                {`Al dar click en Ingresar / Registrar, usted reconoce que ha leído y acepta nuestros `}
                <a href="/terms_and_conditions" target="_blank">
                  Términos y Condiciones
                </a>
              </span>
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default compose(
  withFirebase,
  withRouter,
  connect(
    state => ({
      user_data: state.user_data,
      event: state.event,
      orderDetails: state.orderDetails
    }),
    dispatch => bindActionCreators({ setUserData, userSignedIn }, dispatch)
  )
)(Register);
