import React, { useState } from 'react';
import {Tooltip} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { centsToDecimal } from '../../helpers/currency';
import { faInfoCircle }from '@fortawesome/free-solid-svg-icons';
import './styles.scss';
export default function index({id, price, fee}) {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    return (
        <div>
            <FontAwesomeIcon icon={faInfoCircle} className="infoTooltip"   id={`Tooltip-${id}`}  />
            <Tooltip
              placement="right"
              isOpen={tooltipOpen}
              target={`Tooltip-${id}`}
              toggle={toggle}
            >
              Precio: ${centsToDecimal(price)}
              <br />
              Cargos por Servicio: ${centsToDecimal(fee)}
              <br />
              Total: ${centsToDecimal(price + fee)}
            </Tooltip>
        </div>
    )
}
