import React from "react";
import "./styles.scss";
// style and styled components
import { Row, Col, Form, FormGroup, Label } from "reactstrap";

class EventFilter extends React.Component {
  render() {
    return (
      <>
        <Row>
          <Col md="6">
            <h4 className="event-filter">Fechas disponibles</h4>
          </Col>
          <Col md="6" className="text-right d-none d-sm-block">
            <Form inline className="d-flex flex-row-reverse">
              <FormGroup>
                <Label for="exampleEmail" className="mr-sm-2">
                  Ordenar por:{" "}
                </Label>
                <select
                  className="select-width"
                  name="fecha"
                  onChange={(e) => this.props.orderByFunc(e.target.value)}
                  value={this.props.orderBy}>
                  <option value="DATE">Fecha</option>
                  <option value="PRICE">Precio</option>
                </select>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <br />
      </>
    );
  }
}
export default EventFilter;
