import React, { Component } from 'react';
import { Row, Col, FormGroup, Label, Input, Tooltip } from 'reactstrap';
import Cleave from 'cleave.js/react';
import ConektaContext from '../../../providers/payments/ConektaContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import './style.scss';

export default class Conekta extends Component {
  state = { month: '', year: '', cvv: '', tooltipOpen: false };

  toggle = () => {
    this.setState({ tooltipOpen: !this.state.tooltipOpen });
  };

  render() {
    return (
      <ConektaContext.Consumer>
        {context => (
          <>
            <Row className="mt20">
              <Col lg="6">
                <FormGroup>
                  <Label for="cardNumber">
                    <small>Número de tarjeta</small>
                  </Label>
                  <Cleave
                    options={{ creditCard: true }}
                    className="form-control"
                    onChange={context.onCreditCardChange}
                  />

                  {context.cardNumber.length >= 12 && !context.cardNumberValid && (
                    <p className="text-error">Formato de tarjeta no válida.</p>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Label for="name">
                    <small>Nombre como aparece en la tarjeta</small>
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    value={context.name}
                    onChange={context.handleNameChange}
                    autoComplete="cc-name"
                  />
                  {!context.nameValid && context.name.length > 4 && (
                    <p className="text-error">El nombre debe contener más de 4 caracteres.</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt5">
              <Col lg="4">
                <FormGroup>
                  <Label for="month">
                    <small>Mes Exp.</small>
                  </Label>
                  <Input
                    type="text"
                    name="month"
                    maxLength="2"
                    value={context.month}
                    onChange={context.handleMonthChange}
                    autoComplete="cc-exp-month"
                  />
                  {!context.monthValid && context.month.length >= 2 && (
                    <p className="text-error">
                      Elige un número del 1 al 12 para representar el mes de tu tarjeta.
                    </p>
                  )}
                  {!context.expirationDateValid &&
                    context.month.length >= 2 &&
                    context.year.length >= 1 && (
                      <p className="text-error">El formato de mes y año no es válido.</p>
                    )}
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <Label for="year">
                    <small>Año exp.</small>
                  </Label>
                  <Input
                    type="text"
                    name="year"
                    value={context.year}
                    maxLength="4"
                    onChange={context.handleYearChange}
                    autoComplete="cc-exp-year"
                  />
                  {!context.yearValid && context.year.length >= 1 && (
                    <p className="text-error">Elige un año válido.</p>
                  )}
                  {!context.expirationDateValid &&
                    context.month.length >= 2 &&
                    context.year.length >= 1 && (
                      <p className="text-error">El formato de mes y año no es válido.</p>
                    )}
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <Label for="cvv">
                    <small>CVV</small>
                  </Label>
                  <FontAwesomeIcon icon={faInfoCircle} className="tooltip-cvv" id={`Tooltip`} />
                  <Tooltip
                    placement="right"
                    isOpen={this.state.tooltipOpen}
                    target={`Tooltip`}
                    toggle={this.toggle}
                  >
                    El código CVV o CVC es un grupo de 3 o 4 números situado en el reverso de la
                    tarjeta de crédito.
                  </Tooltip>
                  <Input
                    data-private
                    type="text"
                    name="cvv"
                    value={context.cvv}
                    maxLength={context.maxCVV}
                    onChange={context.handleCvvChange}
                    autoComplete="cc-csc"
                  />
                  {!context.cvvValid && context.cvv.length >= 2 && (
                    <p className="text-error">El código de seguridad no es válido.</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <FormGroup>
                  <Label for="pc">
                    <small>Código Postal</small>
                  </Label>
                  <Input
                    type="text"
                    name="pc"
                    value={context.pc}
                    maxLength={5}
                    onChange={e => context.setPc(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        )}
      </ConektaContext.Consumer>
    );
  }
}
