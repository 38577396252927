import React, { Component } from 'react';
import EventCover from '../../components/event_cover';
import { Container, Row, Col } from 'reactstrap';
import EventHeader from '../../components/event_header';
import PurchaseSummary from '../../components/purchase_summary';
import EventSidebar from '../../components/event_sidebar';
import AboutEvent from '../../components/about_event';
// helpers
import { lastSegmentOfUrl } from '../../helpers/lastSegmentOfUrl';
import { isMobile } from 'react-device-detect';
import { compose } from 'recompose';
import { withFirebase } from '../../components/firebase';
import { withRouter } from 'react-router';
// redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setEvent } from '../../redux/actions/event.actions';
import { handleAcceptedTerms, setOrg } from '../../redux/actions/index';
import './styles.scss';
// providers
class EventContainer extends Component {
  state = {
    eventReady: false,
    orgReady: false,
    event: null,
    acceptTerms: false,
    selectedDate: new Date()
  };
  async componentDidMount() {
    const previousSelectedDate = localStorage.getItem('selectedDate');
    if (previousSelectedDate) {
      this.setState({
        selectedDate: new Date(previousSelectedDate)
      });
    }

    if (window.location.href.includes('%E2%80%8B')) {
      window.location.replace(window.location.href.replace('%E2%80%8B', ''));
    }
    const { eventSlug } = this.props.match.params;
    // return to top of the screen after rendering component
    window.scrollTo(0, 0);
    // get event
    const result = await this.props.firebase.getEventBySlug(eventSlug);
    if (!result.empty) {
      const event = result.docs[0].data();
      const eventId = result.docs[0].id;
      this.setState({
        event,
        eventId,
        eventReady: true
      });
      this.props.setEvent({
        eventId,
        organizationId: event.organizationId,
        defaultPaymentGateway: event.defaultPaymentGateway,
        paymentGateways: event.paymentGateways,
        banorteExclusiveSale: event.banorteExclusiveSale,
        donation: event.donation
      });
      const organizationDoc = await this.props.firebase.getOrganizationById(event.organizationId);
      const { gmId, intercomId, googleAnalyticsId, facebookPixelId } = organizationDoc.data();
      this.props.setOrg({ gmId, intercomId, googleAnalyticsId, facebookPixelId });
    } else {
      this.props.history.push('/');
    }
  }

  setSelectedDate = date => {
    this.setState({
      selectedDate: date
    });
    localStorage.setItem('selectedDate', date);
  };

  handleAcceptTerms = () => {
    this.props.handleAcceptedTerms();
    this.setState(prevState => ({
      acceptTerms: !prevState.acceptTerms
    }));
  };

  render() {
    const { organizationSlug, eventSlug } = this.props.match.params;
    const { push } = this.props.history;
    //const { orderId } = this.props.orderDetails[this.state.eventId];
    return (
      this.state.eventReady && (
        <>
          <EventCover headerImage={this.state.event.headerImage} />
          <Container>
            <EventHeader
              event={this.state.event}
              organizationSlug={organizationSlug}
              push={push}
              step={this.props.step}
            />
            <Row className="stickyStyles">
              <Col lg="7" className="event">
                {this.props.children({
                  event: this.state.event,
                  eventId: this.state.eventId,
                  organizationId: this.state.event.organizationId,
                  acceptTerms: this.state.acceptTerms,
                  handleAcceptTerms: this.handleAcceptTerms,
                  selectedDate: this.state.selectedDate,
                  setSelectedDate: this.setSelectedDate
                })}
              </Col>
              {/*Only in the first step, we show EventSidebar, otherwise, purchase summary is shown 
              this is what this filter is about, showing the corresponding sidebar where it should be*/}
              {lastSegmentOfUrl() === eventSlug ? (
                <>
                  <Col lg="5" className="card-col">
                    <EventSidebar event={this.state.event} />
                  </Col>
                  {isMobile && (
                    <Col lg="7" className="event">
                      <AboutEvent event={this.state.event} />
                    </Col>
                  )}
                </>
              ) : (
                <Col lg="5" className="itemClass card-col">
                  {this.state.event && (
                    <PurchaseSummary
                      editable={
                        lastSegmentOfUrl() === 'form' ||
                        lastSegmentOfUrl() === 'extra-services' ||
                        lastSegmentOfUrl() === 'create-account'
                      }
                      user={this.props.user}
                      eventId={this.state.eventId}
                      acceptTerms={this.state.acceptTerms}
                      organizationId={this.state.event.organizationId}
                      selectedPayment={this.props.selectedPayment ? this.props.selectedPayment : ''}
                      paypalSelectionPage={this.props.paypalSelectionPage}
                      disableContinue={this.props.disableContinue}
                      setIsButtonDisabled={this.props.setIsButtonDisabled}
                    />
                  )}
                </Col>
              )}
            </Row>
          </Container>
        </>
      )
    );
  }
}

export default compose(
  withFirebase,
  withRouter,
  connect(
    state => ({ orgReady: state.org.ready }),
    dispatch => bindActionCreators({ setEvent, handleAcceptedTerms, setOrg }, dispatch)
  )
)(EventContainer);
