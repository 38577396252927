import React from 'react';
import { Row, Col } from 'reactstrap';
import { centsToDecimal } from '../../helpers/currency';
import { FormatDate } from './Date';
import { urlQrCode } from '../../helpers/urlQrCode';
export default ({ phase, event }) => {
  return phase.purchaseTickets.map((ticket, i) => {
    return (
      <Col lg="6" className="event" key={i}>
        <div className="ticket-card">
          <div className="ticket-header">
            {ticket.imageHeader && <img src={ticket.imageHeader} alt={phase.name.capitalize()} />}
          </div>
          <div className="ticket-content">
            <Row className="ticket-row fixed-content">
              <Col lg="12">
                <small className="ticket-label">Sección</small>
                <br />
                <div className="ticket-info">{phase.name.capitalize()}</div>
              </Col>
            </Row>
            <Row className="ticket-row">
              <Col lg="8">
                <small className="ticket-label">Fecha y Hora</small>
                <br />
                <FormatDate
                  {...event}
                  accessEndsAt={ticket.accessEndsAt}
                  accessStartsAt={ticket.accessStartsAt}
                />
              </Col>
              <Col lg="4">
                <small className="ticket-label">Precio</small>
                <br />
                <div className="ticket-info">
                  ${centsToDecimal(phase.feePerUnit + phase.unitPrice).toFixed(2)}
                </div>
              </Col>
            </Row>
            <Row className="ticket-row">
              <Col lg="12">
                <small className="ticket-label">Lugar</small>
                <br />
                <div className="ticket-address">{event.location.capitalize()}</div>
              </Col>
            </Row>

            <div className="ticket-qr-wrapper">
              <img
                src={urlQrCode(ticket.id)}
                alt="qr ticket"
                className="ticket-qr"
              />
            </div>
          </div>
        </div>
      </Col>
    );
  });
};
