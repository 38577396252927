import React from 'react';
import Navigation from '../Navigation';
import FeaturedSlider from '../FeaturedSlider';

import './index.scss';

const Header = ({ landing }) => {
  return (
    <header className="landing-header">
      <Navigation landing={landing} />
      <FeaturedSlider landing={landing} />
    </header>
  );
};

export default Header;
