import React from "react";
import { Label, Input, FormGroup } from "reactstrap";
export default function InputComponent({
  key,
  field,
  customIndex,
  orderTicketId,
  persistedValue
}) {
  switch (field.type) {
    case "BOOLEAN":
      return (
        <FormGroup tag="fieldset">
          <Label>
            {field.name}
            {field.required && <span className="required-label">*</span>}
          </Label>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name={`${orderTicketId}.form_answers.${customIndex}.${field.token}`}
                value="SI"
                defaultChecked={
                  persistedValue === "SI" || field.defaultValue === "SI"
                }
              />
              Si
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name={`${orderTicketId}.form_answers.${customIndex}.${field.token}`}
                value="NO"
                defaultChecked={
                  persistedValue === "NO" || field.defaultValue === "NO"
                }
              />
              No
            </Label>
          </FormGroup>
        </FormGroup>
      );
    case "E-MAIL":
      return (
        <FormGroup>
          <Label>{field.name}</Label>
          <Input
            type="email"
            required={field.required}
            placeholder={field.placeholder}
            defaultValue={persistedValue}
            name={`${orderTicketId}.form_answers.${customIndex}.${field.token}`}
          />
        </FormGroup>
      );
    case "MULTIPLE-CHOICE":
      return (
        <FormGroup>
          <Label>{field.name}</Label>
          <Input
            type="select"
            placeholder={field.placeholder}
            defaultValue={persistedValue}
            name={`${orderTicketId}.form_answers.${customIndex}.${field.token}`}
          />
        </FormGroup>
      );
    case "OPEN":
      return (
        <FormGroup>
          <Label>
            {field.name}{" "}
            {field.required && <span className="required-label">*</span>}
          </Label>
          <Input
            type="text"
            required={field.required}
            placeholder={field.placeholder}
            defaultValue={persistedValue}
            name={`${orderTicketId}.form_answers.${customIndex}.${field.token}`}
          />
        </FormGroup>
      );
    case "RADIO":
      return (
        <FormGroup tag="fieldset">
          <Label>{field.name}</Label>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name={`${orderTicketId}.form_answers.${customIndex}.${field.token}`}
              />
              Si
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name={`${orderTicketId}.form_answers.${customIndex}.${field.token}`}
              />
              No
            </Label>
          </FormGroup>
        </FormGroup>
      );
    case "SELECT":
      return (
        <FormGroup>
          <Label>
            {field.name}{" "}
            {field.required && <span className="required-label">*</span>}
          </Label>
          <Input
            type="select"
            required={field.required}
            defaultValue={field.defaultValue}
            name={`${orderTicketId}.form_answers.${customIndex}.${field.token}`}
          >
            <option value="">{field.placeholder}</option>
            {field.options.map((option, i) => (
              <option
                value={option}
                key={i}
                defaultValue={field.defaultValue === option}
              >
                {option}
              </option>
            ))}
          </Input>
        </FormGroup>
      );
    default:
      return "text";
  }
}
