import React from 'react';
// helpers
import Sugar from 'sugar';
import { isMobile } from 'react-device-detect';
// style and styled components
import { Table, Button } from 'reactstrap';
import { withRouter } from 'react-router';
import { getRangeOfPrices, getMinPrice } from '../../helpers/phases';
import './styles.scss';
import { withFirebase } from '../firebase';
import { fbTimestampToDate } from '../../helpers/datetime';
import qs from 'query-string';
import moment from 'moment';

Sugar.extend();

class EventList extends React.Component {
  state = {
    events: null,
    ready: false
  };

  handleGetPhases = async (organizationId, eventId) => {
    let phasesList = [];
    const phases = await this.props.firebase.getPhasesCollectionStatic(organizationId, eventId);
    phases.forEach(phase => {
      phasesList.push(phase.data());
    });
    return phasesList;
  };

  asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };

  async componentDidMount() {
    moment.locale('es');
    const { organizationId } = this.props;
    const snapshot = await this.props.firebase.getEventsCollection(organizationId);
    let eventList = [];
    await this.asyncForEach(snapshot.docs, async element => {
      let elem = element.data();
      elem.id = element.id;
      const phases = await this.handleGetPhases(organizationId, element.id);
      elem.phases = phases;
      eventList.push(elem);
    });
    this.setState({ events: eventList, ready: true });
  }
  toEvent = (eventslug, phases) => {
    const { organizationSlug } = this.props.match.params;
    const { search } = this.props.history.location;
    if (search.length > 0) {
      this.props.history.push(`/${organizationSlug}/${eventslug}${search}`, {
        organizationId: this.props.organizationId,
        phases: phases
      });
    } else {
      this.props.history.push(`/${organizationSlug}/${eventslug}`, {
        organizationId: this.props.organizationId,
        phases: phases
      });
    }
  };
  render() {
    return (
      <>
        <Table responsive className="events-list">
          <thead>
            <tr>
              <th className="p-1 align-middle">Fecha</th>
              <th className="p-1 align-middle"> Evento / Hora</th>
              <th className="p-1 align-middle">Rango de precios</th>
              {!isMobile && <th />}
            </tr>
          </thead>
          <tbody>
            {!this.state.ready ? (
              <tr>
                <td />
              </tr>
            ) : this.props.orderBy === 'DATE' ? (
              this.state.events
                .sort((a, b) => (a.startsAt.seconds - b.startsAt.seconds))
                .map((event, i) =>
                  (1 + i) % 2 === 0 ? (
                    <ElementListItem
                      event={event}
                      key={i}
                      toEvent={this.toEvent}
                      backgroundColor="backgroundOrange"
                    />
                  ) : (
                    <ElementListItem event={event} key={i} toEvent={this.toEvent} />
                  )
                )
            ) : (
              this.state.events
                .sort((a, b) => (getMinPrice(a.phases) - getMinPrice(b.phases)))
                .map((event, i) => <ElementListItem event={event} key={i} toEvent={this.toEvent} />)
            )}
          </tbody>
        </Table>
      </>
    );
  }
}

function ElementListItem({ event, toEvent, backgroundColor = '' }) {
  return (
    <tr
      className={`event-row ${backgroundColor}`}
      onClick={() => toEvent(event.slug, event.phases)}
    >
      <th scope="row" className="event-row__date">
        <div className="flex-center fecha-mt">
          <h1>{fbTimestampToDate(event.startsAt.seconds).format('DD')}</h1>
          <small className="fecha-padding-top">
            {fbTimestampToDate(event.startsAt.seconds).format('MMMM')}
          </small>
        </div>
      </th>
      <td className="event-row__description">
        <div className="flex-center">
          <h5>{event.name}</h5>
          <small className="text-capitalize">
            {fbTimestampToDate(event.startsAt.seconds).format('dddd h:mm A ')} -
            {fbTimestampToDate(event.endsAt.seconds).format(' h:mm A ')}
          </small>
        </div>
      </td>
      {<td className="event-row__price-range">
        <div className="flex-center text-align-right">
          {isMobile
            ? getRangeOfPrices(event.phases).replace("— ", "")
            : getRangeOfPrices(event.phases)}
        </div>
      </td>}
      {!isMobile && (
        <td className="text-center d-xs-none">
          <div className="flex-center">
            <Button color="primary" className="btn-lg">
              Ver boletos
            </Button>
          </div>
        </td>
      )}
    </tr>
  );
}
export default withRouter(withFirebase(EventList));
