import React from "react";
import TermsAndConditions from "../../components/terms_and_conditions";
import { Container } from "reactstrap";

class TermsAndConditionsPage extends React.Component {
  render() {
    return (
      <>
        <Container className="clearfix">
          <h4 className="text-center mt-5">Términos y Condiciones Generales de Uso</h4>
          <div className="mt-5"> <TermsAndConditions  /></div>
        </Container>
      </>
    );
  }
}
export default TermsAndConditionsPage;
