import React from "react";
import { withRouter } from "react-router-dom";
class Image extends React.Component {
  handleGoToEvents = () => {
    if (this.props.redirect) {
      const { urlEvent } = this.props;
      const { organizationSlug } = this.props.match.params;
      urlEvent
        ? this.props.history.push(urlEvent)
        : this.props.history.push(`/${organizationSlug}`);
    }
  };
  render() {
    return (
      <div>
        <div style={{ position: "relative" }}>
          <img
            src={this.props.imgUrl}
            style={this.props.theme}
            className={`img-fluid ${this.props.className}`}
            alt="Responsive"
            onClick={this.handleGoToEvents}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(Image);
