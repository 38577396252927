import { userConstants } from "../constants";

export function user_data(state = {}, action) {
  switch (action.type) {
    case userConstants.SET_USER_DATA:
      return {
        ...state,
        ...{ ...action.payload },
      };
    case userConstants.USER_SIGNED_IN:
      return {
        ...state,
        ...{ ...action.payload },
      };
    case "SET_GUEST_USER":
      return {
        ...state,
        ...{
          ...action.payload,
        },
      };
    case userConstants.USER_SIGNED_OUT:
      return {
        ...state,
        ...{
          email: "",
          emailVerified: "",
          emailConfirmation: "",
          isAnonymous: "",
          uid: "",
          firstName: "",
          lastName: "",
          phone: "",
        },
      };
    default:
      return state;
  }
}
