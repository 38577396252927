import { recaptchaConstants } from "../constants";

export function recaptcha(state = { recaptchaValid: false }, action) {
  switch (action.type) {
    case recaptchaConstants.VALIDATE_RECAPTCHA:
      return {
        ...state,
        ...{
          recaptchaValid: action.payload
        }
      };
    default:
      return state;
  }
}
