import React, { useState } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import 'firebase/auth';
import { connect } from 'react-redux';
import { Snackbar } from '@material-ui/core/';
import MySnackbarContentWrapper from '../../MySnackbarContentWrapper';
import validate from './validate';

import useForm from '../../../hooks/useForm';
import './style.scss';
import FacebookLoginSVG from '../../../assets/images/fb_btn.svg';
import GoogleLoginBtn from '../../../assets/images/google_btn.svg';
import { useFirebaseAuth } from '../../../hooks/useFirebaseAuth';

function LogInForm({ event: { organizationId, eventId }, orderDetails, changeMode, toggleModal }) {
  const { orderId } = orderDetails[eventId] || {};
  // eslint-disable-next-line no-use-before-define
  const { signInWithEmailAndPassword, signInWithGoogle, signInWithFacebook } = useFirebaseAuth();
  const { values, errors, handleChange, handleSubmit } = useForm(
    // eslint-disable-next-line no-use-before-define
    onSignInWithEmailAndPassword,
    validate
  );
  const [error, setError] = React.useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarVariant, setSnackbarVariant] = useState('error');

  async function onSignInWithEmailAndPassword() {
    try {
      await signInWithEmailAndPassword(
        values.email,
        values.password,
        organizationId,
        eventId,
        orderId
      );
      toggleModal();
    } catch (e) {
      // eslint-disable-next-line no-shadow
      console.log(e);
      let error = e.message;
      if (
        e.message ===
        'Error: There is no user record corresponding to this identifier. The user may have been deleted.'
      ) {
        error =
          'Error: No hay registro de usuario correspondiente a este identificador. El usuario puede haber sido eliminado.';
      }
      if (e.message === 'Error: The password is invalid or the user does not have a password.') {
        error = 'Error: La contraseña o el correo son invalidos.';
      }
      setError(error);
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant={snackbarVariant}
          message={snackbarMessage}
        />
      </Snackbar>
      {error && <Alert color="danger">{error}</Alert>}
      <Form className="Login">
        <FormGroup>
          <Label for="exampleEmail">Correo Electrónico</Label>
          <Input
            type="email"
            name="email"
            value={values.email || ''}
            onChange={handleChange}
            onKeyDown={e => e.key === 'Enter' && handleSubmit(e)}
            placeholder="correo@dominio.com"
          />
          {errors.email && <p className="text-error">{errors.email}</p>}
        </FormGroup>
        <FormGroup>
          <p
            className="forgot-password"
            onClick={() => changeMode('RECOVERY_PASSWORD', 'Recupera tu contraseña')}
          >
            Olvidaste tu contraseña?
          </p>
          <Label for="examplePassword">Contraseña</Label>
          <Input
            type="password"
            name="password"
            value={values.password || ''}
            onChange={handleChange}
            onKeyDown={e => e.key === 'Enter' && handleSubmit(e)}
            placeholder="Contraseña"
          />
          {errors.password && <p className="text-error">{errors.password}</p>}
        </FormGroup>
      </Form>
      <button
        type="button"
        className="btn-lg btn-block btn btn-primary login-button"
        onClick={handleSubmit}
      >
        Iniciar Sesión
      </button>
      {/* {error === null ? '' : <Alert color="warning">{error}</Alert>} */}
      <Row>
        <Col lg={6} md={6} sm={6} xs={6}>
          <button
            type="button"
            onClick={async () => {
              try {
                await signInWithFacebook(organizationId, eventId, orderId);
                toggleModal();
              } catch (e) {
                setSnackbarMessage(e);
                setSnackbarVariant('error');
                setSnackbarOpen(true);
              }
            }}
            style={{ border: 'none', background: 'none' }}
          >
            <img
              src={FacebookLoginSVG}
              alt="login with fb"
              className="login-button login-facebook-btn"
            />
          </button>
        </Col>
        <Col lg={6} md={6} sm={6} xs={6}>
          <button
            type="button"
            onClick={() => {
              signInWithGoogle(organizationId, eventId, orderId);
              toggleModal();
            }}
            style={{ border: 'none', background: 'none' }}
          >
            <img
              src={GoogleLoginBtn}
              alt="login with google"
              className="login-button login-google-btn-modal"
            />
          </button>
        </Col>
      </Row>
      <p
        className="text-center create-account"
        onClick={() => changeMode('CREATE_ACCOUNT', 'Registrate')}
      >
        Crear Cuenta
      </p>
    </>
  );
}

export default connect(state => ({
  event: state.event,
  orderDetails: state.orderDetails
}))(LogInForm);
