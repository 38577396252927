import { eventConstants } from "../constants";

export function getEvent(slug) {
  return { type: eventConstants.GET_EVENT, payload: slug };
}

export function setEvent(data) {
  return {
    type: eventConstants.SET_EVENT,
    payload: data,
    loading: false,
  };
}
