import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetOrderId } from '../../../redux/actions';
import { useFirebaseAuth } from '../../../hooks/useFirebaseAuth';

const UserProfile = ({ history, resetOrderId }) => {
  const { signOut } = useFirebaseAuth();
  const onClick = () => {
    signOut();
    history.push('/');
    resetOrderId({});
  };
  return (
    <>
      <button
        type="button"
        className="btn-lg btn-block btn btn-primary login-button"
        onClick={onClick}
      >
        Cerrar Sesión
      </button>
    </>
  );
};

export default compose(
  withRouter,
  connect(
    state => ({}),
    dispatch =>
      bindActionCreators(
        {
          resetOrderId
        },
        dispatch
      )
  )
)(UserProfile);
