import React from 'react';
import ConektaContext from '../../../providers/payments/ConektaContext';

import { Row, Col, FormGroup, Label } from 'reactstrap';

export default function Oxxo() {
  return (
    <ConektaContext.Consumer>
      {context => (
        <Row className="mt20">
          <Col lg="12">
            <FormGroup>
              <Label>
                Una vez completa tu orden, recibiras las instrucciones para realizar el pago en tu
                Oxxo más cercano
              </Label>
              <strong>Puntos importantes al pagar con OxxoPay</strong>
              <ul className="pl-4">
                <li>Tienes 24 horas para realizar tu pago en efectivo</li>
                <li>Oxxo recibe pagos de 8:00 hrs a 20:00 hrs</li>
                <li>Oxxo cobra una comisión por concepto de pago</li>
              </ul>
            </FormGroup>
          </Col>
        </Row>
      )}
    </ConektaContext.Consumer>
  );
}
