import React, { Component } from 'react';
import AccountProvider from '../providers/accounts/AccountProvider';
import ConektaProvider from '../providers/payments/ConektaProvider';
// import PaypalProvider from "../providers/payments/PaypalProvider";
import CartProvider from '../providers/cart/CartProvider';
// this allow us to use this.props.match.params inside providers

export default class Providers extends Component {
  render() {
    return (
      <AccountProvider>
        <ConektaProvider>
          <CartProvider>{this.props.children}</CartProvider>
        </ConektaProvider>
      </AccountProvider>
    );
  }
}
