import Sugar from "sugar";

Sugar.extend();

export function getRangeOfPrices(phases) {
  let prices = [];
  phases.forEach(function(phase, index) {
    prices.push(phase.price + phase.fee);
  });

  return `$${centsToDecimal(prices.min()).format(2)} — $${centsToDecimal(
    prices.max()
  ).format(2)}`;
}

export function getMinPrice(phases) {
  let prices = [];
  phases.forEach(function(phase, index) {
    prices.push(phase.price + phase.fee);
  });

  return prices.min();
}

export function centsToDecimal(num) {
  return parseFloat(num / 100);
}

export function decimalToCents(num) {
  return parseInt(num * 100);
}
