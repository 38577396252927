import axios from "axios";

export const updateOrderProducts = async (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_FUNCTIONS_URL}/cart`, payload)
      .then((response) => {
        resolve(true);
      })
      .catch((error) => {
        reject(error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  });
};

export const createOrderProducts = async (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_FUNCTIONS_URL}/cart`, payload)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  });
};
