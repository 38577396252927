import CartContext from "./CartContext";
import React, { Component } from "react";
// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// firebase
import { withFirebase } from "../../components/firebase";
// helpers
import { compose } from "recompose";
import { lastSegmentOfUrl } from "../../helpers/lastSegmentOfUrl";
import axios from "axios";

class CartProvider extends Component {
  state = {
    orderTickets: [],
    orderProducts: [],
    orderCourtesies: [],
    order: null,
    applying: false,
    discountapplied: false,
    showAlert: false,
  };
  loadOrderTickets = async () => {
    var orderTickets = await this.props.firebase.getOrderTickets(
      this.props.organizationId,
      this.props.eventId,
      this.props.orderDetails[this.props.eventId].orderId,
    );
    orderTickets.onSnapshot((querySnapshot) => {
      var orderTickets = [];
      querySnapshot.forEach(function(phase) {
        let element = phase.data();
        element.id = phase.id;
        orderTickets.push(element);
      });
      this.setState({ orderTickets });
    });
  };
  loadOrderCourtesies = async () => {
    const orderCourtesiesCollection = await this.props.firebase.getOrderCourtesies(
      this.props.organizationId,
      this.props.eventId,
      this.props.orderDetails[this.props.eventId].orderId,
    );
    const orderCourtesies = orderCourtesiesCollection.docs.map((item) => ({
      ...item.data(),
      id: item.id,
    }));
    this.setState({ orderCourtesies });
  };
  loadOrderProducts = async () => {
    var orderProducts = await this.props.firebase.getOrderProducts(
      this.props.organizationId,
      this.props.eventId,
      this.props.orderDetails[this.props.eventId].orderId,
    );
    orderProducts.onSnapshot((querySnapshot) => {
      var orderProducts = [];
      querySnapshot.forEach(function(product) {
        let element = product.data();
        element.id = product.id;
        orderProducts.push(element);
      });
      this.setState({ orderProducts });
    });
  };
  loadOrder = async () => {
    var order = await this.props.firebase.getOrder(
      this.props.organizationId,
      this.props.eventId,
      this.props.orderDetails[this.props.eventId].orderId,
    );
    order.onSnapshot((doc) => {
      this.setState({ order: doc.data() });
    });
  };
  removeCoupon = async () => {
    const { eventId, organizationId, orderDetails } = this.props;
    const { orderId } = orderDetails[eventId];
    const orderTicketsId = this.state.orderTickets.map(
      (orderTicket) => orderTicket.id,
    );
    const payload = {
      coupon: {
        orgId: organizationId,
        eventId: eventId,
        orderId: orderId,
      },
      orderTicketsId,
    };
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_FUNCTIONS_URL}/orders/remove_coupon`,
        payload,
      );
      // since paypal needs current total amount before loading the iframe, we need to reload
      // page if we are in payment-methods so Iframe can rerender with coupon applied
      lastSegmentOfUrl() === "payment-methods" && window.location.reload();
      this.setState({ applying: false });
    } catch (error) {
      const { response } = error;
      this.setState({
        message: response.data.error,
        showAlert: true,
      });
    }
  };
  componentDidMount() {
    if (this.props.orderDetails[this.props.eventId]) {
      this.loadOrderTickets();
      this.loadOrderProducts();
      this.loadOrderCourtesies();
      this.loadOrder();
    }
  }
  render() {
    return (
      <CartContext.Provider
        value={{
          orderTickets: this.state.orderTickets,
          contextMethod: () => {},
        }}>
        {this.props.children}
      </CartContext.Provider>
    );
  }
}

export default compose(
  withFirebase,
  connect(
    (state) => ({
      user_data: state.user_data,
      orderDetails: state.orderDetails,
      event: state.event,
    }),
    (dispatch) => bindActionCreators({}, dispatch),
  ),
)(CartProvider);
