import { combineReducers } from "redux";

import { event } from "./event.reducer";
import { org } from "./org.reducer";
import { cart } from "./cart.reducer";
import { user_data } from "./user.reducer";
import { payment } from "./payment.reducer";
import { orderDetails } from "./orderDetails.reducer";
import { recaptcha } from "./recaptcha.reducer";
import { orderTickets } from "./orderTickets.reducer";
import { orderProducts } from "./orderProducts.reducer";
import { form } from "./form.reducer";

const rootReducer = combineReducers({
  event,
  org,
  cart,
  user_data,
  payment,
  orderDetails,
  recaptcha,
  orderTickets,
  orderProducts,
  form,
});

export default rootReducer;
