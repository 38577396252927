export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function capitalize(string = '') {
  return string
    .toLowerCase()
    .split(' ')
    .map(word => word.substring(0, 1).toUpperCase() + word.substring(1))
    .join(' ');
}

export const Lowcase = str => {
  return String(str).toLowerCase();
};
