import React, { useState, useRef } from 'react';
import { Collapse, Navbar, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

import './index.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas, faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logoWhite from '../../../assets/images/logo-white.svg';
import logo from '../../../assets/images/logo.svg';
import searchIcon from '../../../assets/images/search.svg';
import AuthModal from '../../auth_modal';
import { useFirebaseAuth } from '../../../hooks/useFirebaseAuth';

library.add(fas, faBars);

const WAIT_INTERVAL = 400;

const Navigation = props => {
  const {
    getEvents,
    events,
    setEvents,
    loading,
    setLoading,
    queryLimit,
    setQueryLimit,
    recordsCount,
    setRecordsCount,
    pageSize,
    setPageSize,
    offset,
    setOffset,
    searchValue,
    setSearchValue,
    searchIsOpen,
    setSearchIsOpen
  } = props.landing;

  const searchInput = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const [view, setView] = useState('LOGIN');
  const { user, isModalOpen, setIsModalOpen } = useFirebaseAuth();

  const [timer, setTimer] = useState(null);

  const focusSearchInput = () => searchInput.current.focus();
  const toggle = () => setIsOpen(!isOpen);

  const openSearch = () => {
    setSearchIsOpen(!searchIsOpen);
    setTimeout(() => {
      focusSearchInput();
    }, 100);
  };

  const searchInputBlur = () => {
    if (searchValue.length === 0) {
      setSearchIsOpen(false);
    }
  };

  const searchInputFocus = () => {
    setSearchIsOpen(true);
  };

  const handleChangeModal = _view => () => {
    setView(_view);
    setIsModalOpen(true);
  };

  const handleChange = input => {
    var val = input.target.value;

    setLoading(true);
    setSearchValue(val);
    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        getEvents(1, [`q[str_cont]=${val}`]);
        setLoading(false);
      }, WAIT_INTERVAL)
    );
  };

  return (
    <>
      <Navbar expand="md" className="landing-navigation fixed-top">
        {/* <Collapse isOpen={isOpen} className="w-100 order-1 order-md-0" navbar> */}
        <Collapse className="w-100 order-1 order-md-0" navbar>
          <Nav className="mr-auto" navbar>
            <NavItem className={`nav-search ${searchIsOpen ? 'nav-item-hidden' : ''}`}>
              <a href="#" className="nav-link" onClick={openSearch}>
                <img src={searchIcon} alt="search" />
              </a>
            </NavItem>
            <form
              className={`nav-search-form form-inline my-2 my-lg-0 ${
                searchIsOpen ? '' : 'nav-item-hidden'
              }`}
            >
              <input
                className="form-control mr-sm-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
                onBlur={searchInputBlur}
                onChange={handleChange}
                value={searchValue}
                ref={searchInput}
              />
            </form>
            {/* <NavItem>
              <NavLink href="/components/">Ayuda</NavLink>
            </NavItem> */}
          </Nav>
        </Collapse>
        <div className="mx-auto order-0 order-md-0">
          <NavbarBrand href="/" className="mx-auto">
            <img src={logoWhite} alt="Handypass" className="lg-visible" />
            <img src={logo} alt="handypass" className="sm-visible" />
          </NavbarBrand>
          {/* <NavbarToggler onClick={toggle} /> */}
          <button onClick={toggle} type="button" className="navbar-toggler">
            <FontAwesomeIcon icon={faBars} size="lg" className="fb-icon" />
          </button>
        </div>
        <Collapse isOpen={isOpen} className="w-100 order-3 order-md-0" navbar>
          <Nav className="ml-auto" navbar>
            <form className="nav-search-form form-inline my-2 my-lg-0 alt-search-form">
              <input
                className="form-control mr-sm-2"
                type="search"
                placeholder="Buscar..."
                aria-label="Search"
                onBlur={searchInputBlur}
                onChange={handleChange}
                onFocus={searchInputFocus}
                value={searchValue}
                ref={searchInput}
              />
            </form>
            {user.name && user.name.firstName ? (
              <NavItem>
                <NavLink
                  href="javascript:;"
                  onClick={() => setIsModalOpen(true)}
                  className="text-orange"
                >
                  ¡Hola {user.name && user.name.firstName}!
                </NavLink>
              </NavItem>
            ) : (
              <>
                <NavItem>
                  <NavLink
                    href="javascript:;"
                    onClick={handleChangeModal('CREATE_ACCOUNT')}
                    className="text-white"
                  >
                    Crear cuenta
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="javascript:;"
                    onClick={handleChangeModal('LOGIN')}
                    className="text-orange"
                  >
                    Iniciar sesión
                  </NavLink>
                </NavItem>
              </>
            )}
          </Nav>
        </Collapse>
      </Navbar>
      <AuthModal
        type={view}
        modal={isModalOpen}
        toggleModal={() => {
          setIsModalOpen(!isModalOpen);
        }}
      />
    </>
  );
};

export default Navigation;
