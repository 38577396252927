export function cart(state = { acceptedTerms: false }, action) {
  switch (action.type) {
    case "HANDLE_ACCEPTED_TERMS":
      return {
        ...state,
        ...{
          acceptedTerms: !state.acceptedTerms,
        },
      };
    default:
      return state;
  }
}
