import React, { Component } from "react";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";

export function withGoogleAnalytics(
  WrappedComponent,
  tracking = true,
  options = {}
) {
  const trackPage = page => {
    ReactGA.set({
      page,
      ...options
    });
    ReactGA.pageview(page);
  };

  const HOC = class extends Component {
    componentDidMount() {
      //   ReactGA.plugin.require("ec", { path: "/log", debug: true });
      if (tracking) {
        const page = this.props.location.pathname;
        trackPage(page);
        ReactPixel.pageView();
        ReactPixel.fbq("track", "PageView", { url: page });
      }
    }
    sendEvent = (payload = {}) => {
      ReactGA.event(payload);
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          sendEvent={this.sendEvent}
        />
      );
    }
  };

  return HOC;
}
