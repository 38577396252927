import React from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import { useFormik } from 'formik';
import * as yup from 'yup';
// components
import { Row, Col, Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { capitalize } from '../../../helpers/string';
// helpers
import { useFirebaseAuth } from '../../../hooks/useFirebaseAuth';

function CreateAccount({
  event: { organizationId, eventId },
  orderDetails,
  changeMode,
  toggleModal
}) {
  const { orderId } = orderDetails[eventId] || {};
  const { createUserWithEmailAndPassword, setUser } = useFirebaseAuth();
  const [error, setError] = React.useState('');
  const validationSchema = yup.object({
    firstName: yup
      .string()
      .min(2)
      .required('Ingresa un nombre con mas de 2 caracteres'),
    lastName: yup
      .string()
      .min(2)
      .required('Ingresa un apellido con mas de 2 caracteres'),
    email: yup
      .string()
      .label('Email')
      .email()
      .required('Ingresa un correo electrónico válido'),
    confirmEmail: yup
      .string()
      .label('Email')
      .email()
      .required('Ingresa un correo electrónico válido')
      .oneOf([yup.ref('email'), null], 'El email debe coincidir'),
    phone: yup
      .string()
      .min(10)
      .required('Ingresa un número de teléfono válido'),
    password: yup
      .string()
      .label('string')
      .min(8)
      .required('Campo necesario')
  });

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    touched,
    setTouched
  } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      confirmEmail: '',
      phone: '',
      country: 'mx'
    },
    onSubmit: async values => {
      const { firstName, lastName } = values;
      try {
        const user = await createUserWithEmailAndPassword(
          values.email,
          values.password,
          organizationId,
          eventId,
          orderId
        );

        await setUser({
          id: user.uid,
          email: values.email,
          firstName: capitalize(firstName),
          lastName: capitalize(lastName),
          phone: `+${values.countryCode}${values.phone}`
        });
        toggleModal();
      } catch (e) {
        setError(e.message);
      }
    },
    validationSchema
  });

  return (
    <>
      {error && <Alert color="danger">{error}</Alert>}
      <Form autoComplete="off" onSubmit={handleSubmit} className="form">
        <Row>
          <Col md="6">
            <FormGroup>
              <Label for="userName">Nombre (s)</Label>

              <Input
                type="text"
                name="firstName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={capitalize(values.firstName)}
                autoComplete="off"
              />

              {errors.firstName && touched.firstName && (
                <p className="text-error">{errors.firstName}</p>
              )}
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label for="userLastName">Apellidos</Label>

              <Input
                type="text"
                name="lastName"
                onChange={handleChange}
                value={capitalize(values.lastName)}
                onBlur={handleBlur}
                autoComplete="nope"
              />

              {errors.lastName && touched.lastName && (
                <p className="text-error">{errors.lastName}</p>
              )}
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label for="userEmail">Correo electrónico</Label>

          <Input
            type="email"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            autoComplete="nope"
          />

          {errors.email && touched.email && <p className="text-error">{errors.email}</p>}
        </FormGroup>

        <FormGroup>
          <Label for="userEmail">Confirma tu correo electrónico</Label>
          <Input
            type="email"
            name="confirmEmail"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.confirmEmail}
            autoComplete="nope"
          />
          {errors.confirmEmail && touched.confirmEmail && (
            <p className="text-error">{errors.confirmEmail}</p>
          )}
        </FormGroup>

        <FormGroup>
          <Label for="exampleEmail">Número telefónico</Label>
          <ReactPhoneInput
            defaultCountry={values.country}
            onlyCountries={['mx']}
            onChange={(value, data) => {
              setFieldValue('country', data.countryCode);
              setFieldValue('countryCode', data.dialCode);
              setFieldValue(
                'phone',
                value
                  .replace(/[^0-9]+/g, '')
                  .slice(data.dialCode.length)
                  .substring(0, 10)
              );
            }}
            onBlur={() => {
              setTouched({ ...touched, phone: true });
            }}
            value={values.phone}
            inputExtraProps={{
              required: true
            }}
            regions={[
              'north-america',
              'south-america',
              'central-america',
              'carribean',
              'european-union'
            ]}
          />
          {errors.phone && touched.phone && <p className="text-error">{errors.phone}</p>}
        </FormGroup>

        <FormGroup>
          <Label for="examplePassword">Contraseña</Label>
          <Input
            type="password"
            name="password"
            value={values.password || ''}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.password && touched.password && <p className="text-error">{errors.password}</p>}
        </FormGroup>

        <button type="submit" className="create-account-button">
          Crear Cuenta
        </button>
      </Form>

      <p
        className="text-center create-account"
        onClick={() => changeMode('LOGIN', 'Entrar a tu cuenta')}
      >
        Ya tienes una cuenta
      </p>
    </>
  );
}

export default connect(state => ({
  event: state.event,
  orderDetails: state.orderDetails
}))(CreateAccount);
