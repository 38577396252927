import React from 'react';
//fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendar, faClock, faMapMarker } from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';
import 'moment-timezone';
import './styles.scss';

library.add(faCalendar, faClock, faMapMarker);

class EventSidebar extends React.Component {
  render() {

    const { lon, lat, startsAt, endsAt, location } = this.props.event;
    return (
      <div className="card no-padding">
        <div className="card-content">
          <h2>Detalles del evento</h2>
          <hr />
          <ul className="fa-ul">
            <li>
              <span className="fa-li">
                <img src="/images/icons/feather/calendar.svg" alt="calendar" />
              </span>
              <Moment format="DD \d\e MMMM \d\e\l YYYY">{startsAt.seconds * 1000}</Moment>

              {startsAt.seconds != endsAt.seconds && (
                <>
                  {' al '}
                  <Moment format="DD \d\e MMMM \d\e\l YYYY">{endsAt.seconds * 1000}</Moment>
                </>
              )}
            </li>
            <li>
              <span className="fa-li">
                <img src="/images/icons/feather/clock.svg" alt="clock" />
              </span>
              <Moment format="h:mm A">{startsAt.seconds * 1000}</Moment>
              {' a '}
              <Moment format="h:mm A">{endsAt.seconds * 1000}</Moment>
              {/*9:00 PM - 10:00 PM*/}
            </li>
            <li>
              <span className="fa-li">
                <img src="/images/icons/feather/map-pin.svg" alt="map marker" />
              </span>
              {location}
            </li>
          </ul>
        </div>

        {lat && lon && (
          <iframe
            width="100%"
            height="300"
            id="gmap_canvas"
            src={`https://www.google.com/maps?q=${lat},${lon}&output=embed`}
            frameBorder="0"
            scrolling="no"
          />
        )}
      </div>
    );
  }
}

export default EventSidebar;
