import React from 'react';
import { Row } from 'reactstrap';
// components
import EventContainer from '../../components/event_container';
import { withFirebase } from '../../components/firebase';

import { withRouter } from 'react-router-dom';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetOrderId, handleAcceptedTerms } from '../../redux/actions';

class OrderPending extends React.Component {
  orderTrack = false;
  state = {
    user: { name: '' },
    email: '',
    paymentReference: '',
    paymentBarCodeUrl: ''
  };

  handleFetchData = async () => {
    const { organizationId, eventId } = this.props;
    const { orderId } = this.props.match.params;
    const orderRef = await this.props.firebase.getOrder(organizationId, eventId, orderId);
    const order = await orderRef.get();
    const orderData = { id: order.id, ...order.data() };
    if (order.exists) {
      const { userId, track, paymentId } = orderData;
      this.orderTrack = track || false;

      const user = await this.props.firebase.userInfo(userId);
      const paymentRef = await this.props.firebase
        .paymentsCollection(organizationId, eventId, orderId)
        .doc(paymentId)
        .get();
      const { reference, barCodeUrl } = paymentRef.data();
      const {
        name: { firstName, lastName },
        email
      } = user.data();
      this.setState({
        user: { name: `${firstName} ${lastName}` },
        email,
        paymentReference: reference,
        paymentBarCodeUrl: barCodeUrl
      });
    } else {
      this.props.history.push('/');
    }
  };

  componentDidMount() {
    this.props.resetOrderId({});
    this.handleFetchData();
  }

  render() {
    const { orderId } = this.props.match.params;
    return (
      <ReactPlaceholder ready={true} type="text" rows={12}>
        <h3>¡Gracias {this.state.user.name} estas a un paso de finalizar tu orden!</h3>
        <p>Tienes 24 horas para realizar tu pago en cualquier tienda OXXO</p>
        <p>
          Tu número de referencia es: <b>{this.state.paymentReference}</b>
        </p>
        <p>
          Hemos enviado el mismo número de refrerencia y las instrucciones para realizar tu pago al
          correo <strong>{this.state.email}</strong>, tus boletos llegaran a tu correo despues de
          haber realizado el pago en un lapso maximo de 1 hora
        </p>
      </ReactPlaceholder>
    );
  }
}
const WithProps = props => {
  return (
    <EventContainer>
      {({ eventId, organizationId, event }) => (
        <OrderPending event={event} organizationId={organizationId} eventId={eventId} {...props} />
      )}
    </EventContainer>
  );
};

//export default withFirebase();

export default compose(
  withFirebase,
  withRouter,
  connect(
    state => ({ acceptTerms: state.cart.acceptedTerms }),
    dispatch => bindActionCreators({ resetOrderId, handleAcceptedTerms }, dispatch)
  )
)(WithProps);
