import React from 'react';
// style and styled components
import { Container } from 'reactstrap';
import { Redirect } from 'react-router-dom';

class Index extends React.Component {
  componentDidMount() {
    //const { loadEventsIndex } = this.props;
    //loadEventsIndex();
  }
  render() {
    return (
      <>
        <Redirect to="/luztopia/2019" />
        <Container className="clearfix" />
      </>
    );
  }
}

export default Index;
