import parser from 'ua-parser-js';

export const browserIsValid = () => {
  const ua = parser(navigator.userAgent);
  const { name, version } = ua.browser;
  const parseVersion = parseFloat(version);
  switch (name) {
    case 'Chrome':
      return parseVersion >= 30;
    case 'IE':
      return parseVersion >= 11;
    case 'Firefox':
      return parseVersion >= 44;
    case 'Opera':
      return parseVersion >= 50;
    case 'Safari':
      return parseVersion >= 10;
    case 'Edge':
      return parseVersion >= 15;
    case 'Mobile Safari':
      return parseVersion >= 9;
    case 'Chrome Mobile ':
      return parseVersion >= 47;
    default:
      return true;
  }
};
