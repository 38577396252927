import ReactGA from 'react-ga';

export function sendEvent(payload = {}) {
  ReactGA.event(payload);
}

export function sendTransaction(type = '', payload = {}, send = false) {
  ReactGA.plugin.require('ecommerce');
  ReactGA.plugin.execute('ecommerce', type, payload);
  if (send) {
    ReactGA.plugin.execute('ecommerce', 'send');
    ReactGA.plugin.execute('ecommerce', 'clear');
  }
}
