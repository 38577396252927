import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import Register from '../../components/register';
import EventContainer from '../../components/event_container';
import { withFirebase } from '../../components/firebase';

import './index.scss';

const CreateAccount = () => {
  return (
    <EventContainer step={'Paso 3'}>
      {({ event, eventId, organizationId }) => {
        return <Register />;
      }}
    </EventContainer>
  );
};

export default compose(
  withRouter,
  withFirebase,
  connect(
    state => ({ event: state.event, orderDetails: state.orderDetails }),
    dispatch => bindActionCreators({}, dispatch)
  )
)(CreateAccount);
