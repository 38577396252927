import React, { useEffect } from 'react';
import { router } from './router';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import Header from '../containers/Header';
import Footer from '../components/footer/index';
import { withFirebase } from '../components/firebase';
import Services from '../components/AppServices';
import GTM from '../components/Google_Tag_Manager';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import { userSignedIn, userSignedOut } from '../redux/actions';
import { Landing } from '../pages';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
const hist = createBrowserHistory();

function Index(props) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.conekta.io/js/latest/conekta.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return (
    <Router history={hist}>
      <Switch>
        <Route path="/" component={Landing} exact />
        <Route render={routerProps => <App {...routerProps} {...props} />} />
      </Switch>
    </Router>
  );
}

function App(props) {
  const {
    orgReady,
    googleAnalyticsId,
    gmId,
    facebookPixelId,
    intercomId,
    logRocket,
    bugsnag
  } = props;

  useEffect(() => {
    if (googleAnalyticsId) {
      ReactGA.initialize(googleAnalyticsId);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    if (facebookPixelId) {
      ReactPixel.init(facebookPixelId, {}, { debug: false, autoConfig: false });
    }
  }, [googleAnalyticsId, facebookPixelId]);

  return (
    <React.Fragment>
      <Header />
      <Switch>
        {router.map((route, key) => {
          return <Route path={route.path} component={route.component} exact key={key} />;
        })}
      </Switch>
      <Footer />
      {orgReady && gmId && <GTM gtmId={gmId} />}
      {orgReady && intercomId && (
        <Services logRocket={logRocket} bugsnag={bugsnag} appID={intercomId} />
      )}
    </React.Fragment>
  );
}

export default compose(
  withFirebase,
  connect(
    state => ({
      orgReady: state.org.ready,
      gmId: state.org.gmId,
      intercomId: state.org.intercomId,
      googleAnalyticsId: state.org.googleAnalyticsId,
      facebookPixelId: state.org.facebookPixelId
    }),
    dispatch => bindActionCreators({ userSignedIn, userSignedOut }, dispatch)
  )
)(Index);
