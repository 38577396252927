import React from "react";
import moment from "moment";
import { Row, Col } from "reactstrap";
import { centsToDecimal } from "../../helpers/currency";
import Image from "../../components/image";
import { urlQrCode } from "../../helpers/urlQrCode";
import "moment/locale/es";
export default ({ product, event }) => {
  return product.purchaseProducts.map((ticket, i) => {
    return (
      <Col lg="6" className="event" key={i}>
        <div className="ticket-card">
          <div className="ticket-header">
            {/* <Image
              imgUrl={event.headerImage}
              theme={{
                photoImg: { width: "100%", height: "150px" },
              }}
              redirect={false}
            /> */}
            <img src={event.headerImage} alt={product.name.capitalize()}></img>
            <div className="ticket-header-content">
              <h3>{event.name.capitalize()}</h3>
            </div>
          </div>
          <div className="ticket-content">
            <Row className="ticket-row fixed-content">
              <Col lg="12">
                <small className="ticket-label">Servicio adicional</small>
                <br />
                <div className="ticket-info">{product.name.capitalize()}</div>
              </Col>
            </Row>
            <Row className="ticket-row ">
              <Col lg="8">
                <small className="ticket-label">Fecha y Hora</small>
                <br />
                <div className="ticket-info">
                  {moment(event.startsAt.seconds * 1000)
                    .format("dddd DD MMMM YYYY")
                    .capitalize()}
                  <br />
                  {moment(event.startsAt.seconds * 1000).format(
                    "h:mm a",
                  )} - {moment(event.endsAt.seconds * 1000).format("h:mm a")}
                </div>
              </Col>
              <Col lg="4">
                <small className="ticket-label">Precio</small>
                <br />
                <div className="ticket-info">
                  $
                  {centsToDecimal(
                    product.unitPrice + product.feePerUnit,
                  ).toFixed(2)}
                </div>
              </Col>
            </Row>
            <Row className="ticket-row">
              <Col lg="12">
                <small className="ticket-label">Lugar</small>
                <br />
                <div className="ticket-address">
                  {event.location.capitalize()}
                </div>
              </Col>
            </Row>
            <div className="ticket-qr-wrapper">
              <img
                src={urlQrCode(ticket.id)}
                alt="qr ticket"
                className="ticket-qr"
              />
            </div>
          </div>
        </div>
      </Col>
    );
  });
};
