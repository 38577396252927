import React from 'react';
// style components
import { Row, Col } from 'reactstrap';
import Image from '../image';
import './styles.scss';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../firebase';

class EventHeader extends React.Component {
  state = { events: [] };
  handleGoToEvents = () => {
    this.props.push(`/${this.props.organizationSlug}`);
  };
  handleGoToEvent = () => {
    this.props.push(`/${this.props.organizationSlug}/${this.props.match.params.eventSlug}`);
  };
  asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };
  async componentDidMount() {
    const { organizationId } = this.props.event;
    const events = await this.props.firebase.getEventsCollection(organizationId);
    let eventList = [];
    await this.asyncForEach(events.docs, async element => {
      let elem = element.data();
      eventList.push(elem);
    });
    this.setState({ events: eventList, ready: true });
  }

  showText = path => {
    return path == '/:organizationSlug/:eventSlug';
  };

  labelText = () =>{
    if (this.props.organizationSlug == 'luztopia'){
      return 'ciudades'
    }
    return 'fechas'
  }

  eventName = (name) =>{
      if (this.props.organizationSlug == 'luztopia'){
        return `${name} - ${this.props.step}`
      }
      return name
  }

  render() {
    const { name, logo } = this.props.event;
    return (
      <>
        <Row>
          <Col lg="7" className="eventHeader">
            <Image
              imgUrl={logo}
              theme={{ width: '50px', height: '50px' }}
              urlEvent={`/${this.props.organizationSlug}/${this.props.match.params.eventSlug}`}
              redirect={true}
            />
            <h3 className="title" onClick={this.handleGoToEvent}>
              {this.eventName(name)}
            </h3>
            {this.state.events.length >= 2 && (
              <p className="orange float-right view-more-dates" onClick={this.handleGoToEvents}>
                Ver más {this.labelText()}
              </p>
            )}
          </Col>
          <Col lg="5" />
        </Row>
        <hr className="hr_top_ticket_types" />
      </>
    );
  }
}

export default compose(
  withFirebase,
  withRouter
)(EventHeader);
