// 424242 is for testing purposes on dev
const banorteBins = [
  "424242",
  "402318",
  "402318",
  "402318",
  "402318",
  "425981",
  "425981",
  "425981",
  "425981",
  "425982",
  "425982",
  "425982",
  "425982",
  "425982",
  "425982",
  "464599",
  "480862",
  "491341",
  "491341",
  "491341",
  "491341",
  "491341",
  "491366",
  "491369",
  "491371",
  "491372",
  "491375",
  "491375",
  "491376",
  "491586",
  "493157",
  "493157",
  "493157",
  "493158",
  "493158",
  "493172",
  "493172",
  "493172",
  "493172",
  "493172",
  "493172",
  "493172",
  "493172",
  "493172",
  "493173",
  "493173",
  "493173",
  "493173",
  "512745",
  "544548",
  "544548",
  "544548",
  "544548",
  "544549",
  "544549",
  "544549",
  "544549",
  "544549",
  "547050",
  "547050",
  "547050",
  "547078",
  "547078",
  "547078",
  "547079",
  "547096",
  "547096",
  "547097",
  "554764",
  "554764",
  "554764",
  "442192",
  "516594",
];

const banorteDebitBins = [
  "41891410",
  "41892810",
  "49156610",
  "49156613",
  "49156620",
  "49156622",
  "49156623",
  "49156625",
  "49156627",
  "49156628",
  "49156630",
  "49156632",
  "49156634",
  "49156640",
  "49156643",
  "49156644",
  "49156653",
  "49156654",
  "49156662",
  "49156663",
  "49156664",
  "49156673",
  "49156674",
  "49156683",
  "49156684",
  "49156693",
  "49156694",
  "49156732",
  "52642468",
  "51111421",
  "51111430",
  "43345401",
  "43345402",
  "42598401",
  "42598498",
  "55755101",
];

export const isBanorteCard = (cardNumber) => {
  return (
    banorteBins.includes(cardNumber.substring(0, 6)) ||
    banorteDebitBins.includes(cardNumber.substring(0, 8))
  );
};
