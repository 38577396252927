import React from 'react';
// redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetOrderId, hasOnlyCortesies } from '../../redux/actions';
// components
import { Container, Row, Col, Input, Form, FormGroup, FormFeedback } from 'reactstrap';
import CounterButtons from '../CounterButtons';
import SweetAlert from 'react-bootstrap-sweetalert';
import ContinueWithPurchaseButton from '../continue_with_purchase_button';
import Timer from './timer';
// helpers
import { centsToDecimal } from '../../helpers/currency';
import {
  lastSegmentOfUrl,
  getCurrentPurchaseFlowPath,
  getSegmentOfURL
} from '../../helpers/lastSegmentOfUrl';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { withFirebase } from '../firebase';
import './styles.scss';
import axios from 'axios';

class PurchaseSummary extends React.Component {
  state = {
    orderTickets: [],
    orderProducts: [],
    orderCourtesies: [],
    order: null,
    applying: false,
    discountapplied: false,
    showAlert: false,
    ready: false,
    showInputCoupon: false
  };
  loadOrderTickets = async () => {
    const { orderId } = this.props.match.params;

    var orderTickets = await this.props.firebase.getOrderTickets(
      this.props.organizationId,
      this.props.eventId,
      (this.props.orderDetails[this.props.eventId] &&
        this.props.orderDetails[this.props.eventId].orderId) ||
        orderId
    );
    orderTickets.onSnapshot(querySnapshot => {
      var orderTickets = [];
      querySnapshot.forEach(function(phase) {
        let element = phase.data();
        element.id = phase.id;
        orderTickets.push(element);
      });
      this.setState({ orderTickets });
    });
  };

  loadOrderCourtesies = async () => {
    const { orderId } = this.props.match.params;
    const orderCourtesies = await this.props.firebase.db
      .collection('organizations')
      .doc(this.props.organizationId)
      .collection('events')
      .doc(this.props.eventId)
      .collection('orders')
      .doc(
        (this.props.orderDetails[this.props.eventId] &&
          this.props.orderDetails[this.props.eventId].orderId) ||
          orderId
      )
      .collection('order_courtesies');

    orderCourtesies.onSnapshot(querySnapshot => {
      var orderCourtesies = [];
      querySnapshot.forEach(function(courtesy) {
        let element = courtesy.data();
        element.id = courtesy.id;
        orderCourtesies.push(element);
      });
      this.setState({ orderCourtesies });
    });

    // const orderCourtesiesCollection = await this.props.firebase.getOrderCourtesies(
    //   this.props.organizationId,
    //   this.props.eventId,
    //   this.props.orderDetails[this.props.eventId].orderId
    // );
    // const orderCourtesies = orderCourtesiesCollection.docs.map(item => ({
    //   ...item.data(),
    //   id: item.id
    // }));
    // this.setState({ orderCourtesies });
  };

  loadOrderProducts = async () => {
    const { orderId } = this.props.match.params;
    var orderProducts = await this.props.firebase.getOrderProducts(
      this.props.organizationId,
      this.props.eventId,
      (this.props.orderDetails[this.props.eventId] &&
        this.props.orderDetails[this.props.eventId].orderId) ||
        orderId
    );
    orderProducts.onSnapshot(querySnapshot => {
      var orderProducts = [];
      querySnapshot.forEach(function(product) {
        let element = product.data();
        element.id = product.id;
        orderProducts.push(element);
      });
      this.setState({ orderProducts });
    });
  };
  loadOrder = async () => {
    const { orderId } = this.props.match.params;
    var order = await this.props.firebase.getOrder(
      this.props.organizationId,
      this.props.eventId,
      (this.props.orderDetails[this.props.eventId] &&
        this.props.orderDetails[this.props.eventId].orderId) ||
        orderId
    );
    order.onSnapshot(doc => {
      this.setState({ order: doc.data() });
    });
  };
  removeCoupon = async () => {
    const { eventId, organizationId, orderDetails } = this.props;
    const { orderId } = orderDetails[eventId];
    const payload = {
      orgId: organizationId,
      eventId: eventId,
      orderId: orderId
    };
    try {
      await axios.post(`${process.env.REACT_APP_FUNCTIONS_URL}/coupons/remove`, payload);
      // since paypal needs current total amount before loading the iframe, we need to reload
      // page if we are in payment-methods so Iframe can rerender with coupon applied
      // lastSegmentOfUrl() === 'payment-methods' && window.location.reload();
      this.setState({ applying: false, coupon: '', discountapplied: false });
    } catch (error) {
      const { response } = error;
      this.setState({
        message: response.data.error,
        showAlert: true
      });
    }
  };
  componentDidMount() {
    const { orderId } = this.props.match.params;
    if (this.props.orderDetails[this.props.eventId] || orderId) {
      this.loadOrderTickets();
      this.loadOrderProducts();
      this.loadOrderCourtesies();
      this.loadOrder();
    }
  }
  componentWillMount() {
    this.timer = null;
  }
  handleChange = ({ target }) => {
    clearInterval(this.timer);
    const { name, value } = target;
    this.setState({ [name]: value.toUpperCase() });
    this.timer = setTimeout(this.handleApplyCoupon, 1000);
    // if (name === "coupon") {
    //   this.setState({ [name]: value.toUpperCase() });
    // }
  };

  handleApplyCoupon = async () => {
    this.setState({ applying: true });
    const { eventId, organizationId, orderDetails } = this.props;
    const { orderId } = orderDetails[eventId];
    const payload = {
      orgId: organizationId,
      eventId: eventId,
      orderId: orderId,
      code: this.state.coupon
    };
    try {
      await axios.post(`${process.env.REACT_APP_FUNCTIONS_URL}/coupons/redeem`, payload);
      // since paypal needs current total amount before loading the iframe, we need to reload
      // page if we are in payment-methods so Iframe can rerender with coupon applied
      // lastSegmentOfUrl() === 'payment-methods' && window.location.reload();
      this.setState({ discountapplied: true, errorCoupon: false });
    } catch (error) {
      const { response } = error;
      if (response.status === 422) {
        this.setState({
          errorCoupon: true,
          applying: false
        });
      }
    }
  };
  handleConfirmAlert = () => {
    this.setState({ showAlert: false });
  };
  handleShowInputCoupon = () => {
    this.setState(prevState => ({
      showInputCoupon: !prevState.showInputCoupon
    }));
  };
  async componentDidUpdate(prevProps) {
    // if order gets expired, call alert;
    //this.state.order && this.state.order.orderStatus === "EXPIRED" && this.expireCart(); // does this method exist?
    //verify is the previous properties are different than the current properties if true we load the order
    if (this.props.orderDetails[this.props.eventId] !== prevProps.orderDetails[prevProps.eventId]) {
      if (this.props.orderDetails[this.props.eventId]) {
        await this.loadOrderTickets();
        await this.loadOrderProducts();
        await this.loadOrder();
        this.setState({ ready: true });
      }
    }

    if (
      this.state.ready &&
      this.props.orderDetails[this.props.eventId] &&
      this.state.order &&
      this.state.orderCourtesies.length === 0 &&
      this.state.order.total === 0
    ) {
      //ISSUE: CARRITO VACIO
      console.log('cart empty');
      console.log(this.state.ready);
      this.props.resetOrderId({});
      this.props.history.goBack();
    }
  }

  plusCourtesy = async courtesy => {
    try {
      const courtesyObject = {
        orgId: this.props.organizationId,
        eventId: this.props.eventId,
        orderType: 'ONLINE',
        userId: '',
        orderId: this.props.orderDetails[this.props.eventId].orderId,
        code: courtesy.courtesyCode,
        quantity: courtesy.quantity + 1
      };

      await axios.post(`${process.env.REACT_APP_FUNCTIONS_URL}/courtesies/redeem`, courtesyObject);
    } catch (e) {
      console.log(e);
    }
  };

  minusCourtesy = async courtesy => {
    if (courtesy.quantity >= 1) {
      try {
        const courtesyObject = {
          orgId: this.props.organizationId,
          eventId: this.props.eventId,
          orderType: 'ONLINE',
          userId: '',
          orderId: this.props.orderDetails[this.props.eventId].orderId,
          code: courtesy.courtesyCode,
          quantity: courtesy.quantity - 1
        };

        await axios.post(
          `${process.env.REACT_APP_FUNCTIONS_URL}/courtesies/redeem`,
          courtesyObject
        );
      } catch (e) {
        console.log(e);
      }
    }
  };

  plusCounter = async purchase => {
    try {
      const cart = {
        cart: {
          orgId: this.props.organizationId,
          eventId: this.props.eventId,
          orderType: 'ONLINE',
          userId: '',
          orderId: this.props.orderDetails[this.props.eventId].orderId
        }
      };
      if (purchase.phaseId) {
        cart['cart'].orderTickets = [
          {
            phaseId: purchase.phaseId,
            quantity: purchase.quantity + 1
          }
        ];
      }
      if (purchase.productId) {
        cart['cart'].orderProducts = [
          {
            productId: purchase.productId,
            quantity: purchase.quantity + 1
          }
        ];
      }

      await axios.post(`${process.env.REACT_APP_FUNCTIONS_URL}/cart`, cart);
    } catch (e) {
      console.log(e);
    }
  };

  minusCounter = async purchase => {
    if (purchase.quantity >= 1) {
      const cart = {
        cart: {
          orgId: this.props.organizationId,
          eventId: this.props.eventId,
          orderType: 'ONLINE',
          userId: '',
          orderId: this.props.orderDetails[this.props.eventId].orderId
        }
      };
      if (purchase.phaseId) {
        cart['cart'].orderTickets = [
          {
            phaseId: purchase.phaseId,
            quantity: purchase.quantity - 1
          }
        ];
      }
      if (purchase.productId) {
        cart['cart'].orderProducts = [
          {
            productId: purchase.productId,
            quantity: purchase.quantity - 1
          }
        ];
      }
      await axios.post(`${process.env.REACT_APP_FUNCTIONS_URL}/cart`, cart);
    }
  };

  render() {
    // if no custom text is defined, "continuar compra" will be used
    const { func, customText } = this.props;
    const { path } = this.props.match;
    const { eventId, organizationId, orderDetails, editable } = this.props;
    return (
      <>
        {this.state.showAlert && (
          <SweetAlert error title="Oops..." onConfirm={this.handleConfirmAlert}>
            {this.state.message}
          </SweetAlert>
        )}
        <Timer order={this.state.order} />
        <div className="card">
          <h2>Resumen de compra</h2>
          <Container className="mt-4">
            <Row className="mb-3 text-center summary-title">
              <Col>Cantidad</Col>
              <Col>Concepto</Col>
              <Col>Precio</Col>
            </Row>
            {/*We get all elements from */}
            {this.state.order && this.state.order.donation > 0 && (
              <div className="items">
                <Row className="text-center">
                  <Col>1</Col>
                  <Col>Donación</Col>
                  <Col>${centsToDecimal(this.state.order.donation)}</Col>
                </Row>
              </div>
            )}
            {this.state.orderTickets.map((purchase, index) => (
              <div className="items" key={index}>
                <Row className="text-center">
                  <Col>
                    {editable ? (
                      <CounterButtons
                        small
                        item={purchase}
                        count={purchase.quantity}
                        add={this.plusCounter}
                        remove={this.minusCounter}
                      />
                    ) : (
                      purchase.quantity
                    )}
                  </Col>
                  <Col>{purchase.name}</Col>
                  <Col>${centsToDecimal(purchase.unitPrice)}</Col>
                </Row>
              </div>
            ))}
            {this.state.orderProducts.map((purchase, index) => (
              <div className="items" key={index}>
                <Row className="text-center">
                  <Col>
                    {editable ? (
                      <CounterButtons
                        small
                        item={purchase}
                        count={purchase.quantity}
                        add={this.plusCounter}
                        remove={this.minusCounter}
                      />
                    ) : (
                      purchase.quantity
                    )}
                  </Col>
                  <Col>{purchase.name}</Col>
                  <Col>${centsToDecimal(purchase.unitPrice)}</Col>
                </Row>
              </div>
            ))}
            {this.state.orderCourtesies.map((courtesy, index) => (
              <div className="items" key={index}>
                <Row className="text-center">
                  <Col>
                    {' '}
                    {editable ? (
                      <CounterButtons
                        small
                        item={courtesy}
                        count={courtesy.quantity}
                        add={() => this.plusCourtesy(courtesy)}
                        remove={() => this.minusCourtesy(courtesy)}
                      />
                    ) : (
                      courtesy.quantity
                    )}
                  </Col>
                  <Col>{courtesy.courtesyName}</Col>
                  <Col>Cortesía</Col>
                </Row>
              </div>
            ))}
            {this.state.order && (
              <Row className="m-0 mt-3">
                <Col>
                  <small>Cargo por Servicio</small>:
                </Col>
                <Col className="text-right">
                  <h3 className="price fee">
                    {this.state.order && `$${centsToDecimal(this.state.order.fee)} MXN`}
                  </h3>
                </Col>
              </Row>
            )}
            <Row className="m-0 mt-3">
              <Col>
                {this.state.order && this.state.order.discountApplied ? (
                  <small>Subtotal</small>
                ) : (
                  <small>Total</small>
                )}
              </Col>
              <Col className="text-right">
                {this.state.order && this.state.order.discountApplied ? (
                  <h3 className="price tachado">
                    {this.state.order &&
                      `$${centsToDecimal(this.state.order.total + this.state.order.discount)} MXN`}
                  </h3>
                ) : (
                  <h3 className="price">
                    {this.state.order && `$${centsToDecimal(this.state.order.total)} MXN`}
                  </h3>
                )}
              </Col>
            </Row>
            {this.state.order && this.state.order.discountApplied && (
              <Row className="m-0 mt-3">
                <Col>
                  <small>Descuento</small>:
                </Col>
                <Col className="text-right">
                  <h3 className="price">
                    {this.state.order && `$${centsToDecimal(this.state.order.discount)}`}
                  </h3>
                </Col>
              </Row>
            )}
            {this.state.order && this.state.order.discountApplied && (
              <Row className="m-0 mt-3">
                <Col>
                  <small>Total</small>:
                </Col>
                <Col className="text-right">
                  <h3 className="price">
                    {this.state.order && `$${centsToDecimal(this.state.order.total)} MXN`}
                  </h3>
                </Col>
              </Row>
            )}
            <Row className="m-0 mt-3">
              <Col lg="12">
                <br />

                <ContinueWithPurchaseButton
                  func={func}
                  acceptTerms={this.props.acceptTerms}
                  customText={customText}
                  selectedPayment={this.props.selectedPayment}
                  order={this.state.order}
                  eventId={eventId}
                  orderId={
                    orderDetails[eventId] && orderDetails[eventId].orderId
                      ? orderDetails[eventId].orderId
                      : null
                  }
                  orderCourtesies={this.state.orderCourtesies}
                  resetOrderId={resetOrderId}
                  paypalSelectionPage={this.props.paypalSelectionPage}
                  disableContinue={this.props.disableContinue}
                  setIsButtonDisabled={this.props.setIsButtonDisabled}
                  user={this.props.user}
                />
              </Col>
              {getCurrentPurchaseFlowPath(path) !== ':orderId' && (
                <Col lg="12" className="mt-4">
                  {this.state.order && this.state.order.discountApplied ? (
                    <Row className="text-center">
                      <Col className="orange" onClick={this.removeCoupon}>
                        Remover Cupón
                      </Col>
                    </Row>
                  ) : this.state.showInputCoupon ? (
                    <>
                      <Form>
                        {this.state.errorCoupon && (
                          <FormGroup>
                            <Input
                              invalid
                              value={this.state.coupon}
                              type="text"
                              name="coupon"
                              onChange={this.handleChange}
                            />
                            <FormFeedback className="text-center">
                              Cupón no valido o expirado
                            </FormFeedback>
                          </FormGroup>
                        )}
                        {this.state.discountapplied && (
                          <FormGroup>
                            <Input valid value={this.state.coupon} />
                          </FormGroup>
                        )}
                      </Form>
                      {!(this.state.discountapplied || this.state.errorCoupon) && (
                        <Input
                          type="text"
                          name="coupon"
                          placeholder="Agrega tu cupón de descuento"
                          onChange={this.handleChange}
                        />
                      )}
                      <Row className="mt-3 text-center">
                        <Col className="orange" onClick={this.handleShowInputCoupon}>
                          Cancelar
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Row className="text-center">
                      <Col className="orange" onClick={this.handleShowInputCoupon}>
                        Aplica un cupón
                      </Col>
                    </Row>
                  )}
                </Col>
              )}
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default compose(
  withFirebase,
  withRouter,
  connect(
    state => ({
      orderDetails: state.orderDetails,
      recaptchaValid: state.recaptcha.recaptchaValid
    }),
    dispatch => bindActionCreators({ resetOrderId }, dispatch)
  )
)(PurchaseSummary);
