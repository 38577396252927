import React from 'react';
import { isMobile } from 'react-device-detect';
// actions
// components
import { Row, Col } from 'reactstrap';
import BuyTicketTypes from '../../components/buy_ticket_types';
import AboutEvent from '../../components/about_event';
// layout
import EventContainer from '../../components/event_container';

import { withFirebase } from '../../components/firebase';

class Event extends React.Component {
  render() {
    const { organizationSlug, eventSlug } = this.props.match.params;
    return (
      <EventContainer step={'Paso 1'}>
        {({ event, eventId, organizationId, selectedDate, setSelectedDate }) => {
          return (
            <Row>
              <Col lg="12" className="event">
                <BuyTicketTypes
                  organizationSlug={organizationSlug}
                  eventSlug={eventSlug}
                  eventId={eventId}
                  organizationId={organizationId}
                  selectedDate={selectedDate}
                  setSelectedDate = {setSelectedDate}
                  eventName={event.name}
                  {...event}
                />
                {!isMobile && <AboutEvent event={event} />}
              </Col>
            </Row>
          );
        }}
      </EventContainer>
    );
  }
}

export default withFirebase(Event);
