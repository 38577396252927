import React from 'react';
import { Label, Input } from 'reactstrap';
import { withFirebase } from '../../firebase';

class ResetPasswordForm extends React.Component {
  state = { email: '', message: null };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  resetPassword = () => {
    this.props.firebase
      .doPasswordReset(this.state.email)
      .then(() => {
        this.setState({
          message: 'Instrucciones enviadas a tu correo electrónico.'
        });
      })
      .catch(err => {
        this.setState({
          message: 'Usuario o contraseña no válidos.'
        });
      });
  };
  render() {
    const { email } = this.state;
    const { changeMode } = this.props;
    return (
      <>
        <Label for="exampleEmail">
          Ingresa tu correo electrónico para reiniciar tu contraseña.
        </Label>
        <Input
          type="email"
          name="email"
          value={email}
          onChange={this.onChange}
          placeholder="correo electronico"
        />
        <button
          className="btn-lg btn-block btn btn-primary login-button"
          onClick={this.resetPassword}
        >
          Enviar instrucciones a mi correo
        </button>
        {this.state.message ? <p>{this.state.message}</p> : ''}
        <p className="text-center orange" onClick={() => changeMode('LOGIN', 'Entrar a tu cuenta')}>
          Volver a acceder a mi cuenta
        </p>
      </>
    );
  }
}

export default withFirebase(ResetPasswordForm);
