import axios from 'axios';

const url =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://us-central1-handypass-production.cloudfunctions.net/app'
    : 'https://us-central1-handypass-dev.cloudfunctions.net/app';
// const url = 'http://localhost:5000/handypass-dev/us-central1/app';

const eventsService = config => {
  const service = axios.create({
    baseURL: `${url}/replica/events`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': 'd0358c74ffb389ad56235eda29aa8a378ba7a2a2909a234d8a'
    }
  });

  return service(config);
};

const all = ({ pageSize = 3, currentPage = 1, filters = [] }) =>
  eventsService({
    method: 'GET',
    url: `?pageSize=${pageSize}&currentPage=${currentPage}${filters ? `&${filters.join('&')}` : ''}`
  }).then(({ data }) => data);

export default { all };
