import {
  Home,
  AboutUs,
  Organization,
  CreateAccount,
  PaymentMethods,
  OrderConfirmation,
  Event,
  ExtraServices,
  TermsAndConditions,
  PrivacyPolicy,
  PurchasingPolicy,
  Forms,
  CompleteUserData,
  OrderPending
} from '../pages';
import { withGoogleAnalytics } from '../components/AppServices/withAnalytics';

export const router = [
  {
    path: '/about_us',
    component: AboutUs
  },
  {
    path: '/terms_and_conditions',
    component: TermsAndConditions
  },
  {
    path: '/privacy_policy',
    component: PrivacyPolicy
  },
  {
    path: '/purchasing_policy',
    component: PurchasingPolicy
  },
  {
    path: '/about_us/bla',
    component: AboutUs
  },
  {
    path: '/:organizationSlug',
    component: withGoogleAnalytics(Organization)
  },
  {
    path: '/:organizationSlug/:eventSlug',
    component: withGoogleAnalytics(Event)
  },
  {
    path: '/:organizationSlug/:eventSlug/form',
    component: withGoogleAnalytics(Forms)
  },
  {
    path: '/:organizationSlug/:eventSlug/extra-services',
    component: withGoogleAnalytics(ExtraServices)
  },
  {
    path: '/:organizationSlug/:eventSlug/create-account',
    component: withGoogleAnalytics(CreateAccount)
  },
  {
    path: '/:organizationSlug/:eventSlug/completar_informacion',
    component: CompleteUserData
  },
  {
    path: '/:organizationSlug/:eventSlug/payment-methods',
    component: withGoogleAnalytics(PaymentMethods)
  },
  {
    path: '/:organizationSlug/:eventSlug/order-confirmation/:orderId',
    component: withGoogleAnalytics(OrderConfirmation)
  },
  {
    path: '/:organizationSlug/:eventSlug/pending/:orderId',
    component: withGoogleAnalytics(OrderPending)
  }
];
