export default function validate(values) {
  const errors = {};

  if (!values.email) {
    errors.email = 'Se requiere un correo electrónico';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Revisa el formato del correo electrónico.';
  }

  if (!values.password) {
    errors.password = 'Se requiere una contraseña';
  } else if (values.password.length < 8) {
    errors.password = 'La contraseña debe ser de 8 caracteres o mas';
  }

  return errors;
}
