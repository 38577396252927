import React from "react";
import { Row, Col } from "reactstrap";
import "./styles.scss";

var showdown = require("showdown"),
  converter = new showdown.Converter();

class AboutEvent extends React.Component {
  render() {
    const { description } = this.props.event;
    return (
      <>
        <Row>
          <Col lg="12">
            <h3 className="intro">Acerca del evento:</h3>

            <div className="mdText">
              <div
                dangerouslySetInnerHTML={{
                  __html: converter.makeHtml(description)
                }}
              />
            </div>
          </Col>
        </Row>
        {/*
        <Row>
          <Col lg="12">
            <h3 className="intro">No se permite:</h3>
            <div className="mdText">
              Disclaimer text
            </div>
          </Col>
        </Row>
        */}
      </>
    );
  }
}

export default AboutEvent;
