import React from 'react';
import { Grid, RadioGroup, Button } from '@material-ui/core';
import CardItem from '../../components/CardItem';
import './style.scss';

export default function PaymentCards({ cards, onChange, value, onAddCard }) {
  return (
    <Grid container className="PaymentCards" direction="column">
      <RadioGroup name="paymentCards" value={value} onChange={onChange}>
        {cards &&
          cards.map((card, i) => (
            <CardItem key={i} value={value} brand={card.brand} last4={card.last4} id={card.id} />
          ))}
      </RadioGroup>
      {cards && cards.length > 0 && (
        <Button onClick={onAddCard} fullWidth variant="text">
          Nueva Tarjeta
        </Button>
      )}
    </Grid>
  );
}
