import React from 'react';
import moment from 'moment';
import 'moment/locale/es';
export function FormatDate({ startsAt, endsAt, accessEndsAt, accessStartsAt }) {
  const startDateOnS = accessStartsAt === undefined ? startsAt.seconds : accessStartsAt.seconds;
  const endsDateOnS = accessEndsAt === undefined ? endsAt.seconds : accessEndsAt.seconds;
  const startDate = moment(startDateOnS * 1000);
  const endsDate = moment(endsDateOnS * 1000);
  const daysDuration = moment(startDate).diff(endsDate, 'days');
  if (Math.abs(daysDuration) > 1) {
    return (
      <div className="ticket-info">
        {startDate.format('MMM DD YYYY H:mm').capitalize()}
        <br />
        {endsDate.format('MMM DD YYYY H:mm').capitalize()}
      </div>
    );
  }
  return <div className="ticket-info">{startDate.format('MMM DD YYYY H:mm').capitalize()}</div>;
}
