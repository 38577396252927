import React from "react";

import { Container } from "reactstrap";

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <>
        <Container className="clearfix mt-5">
          <h4 class="text-center">Aviso de Privacidad</h4>
          <div class="mt-4 ">
            <p>
              En <strong>Eventos CrowdFront S.A. de C.V.</strong>, con
              domicilio en{" "}
              <strong>
                José Eleuterio González #250, Int. 113-V, Col. Santa María,
                Monterrey, Nuevo León, código postal 64650{" "}
              </strong>
              (en adelante, LA EMPRESA) estamos conscientes que usted como
              cliente (en lo sucesivo, EL TITULAR) tiene derecho a conocer
              qué datos personales recabamos de usted y el uso que se le
              dará a los mismos.
            </p>
            <p>
              El presente Aviso de Privacidad (en adelante EL PRINCIPAL)
              tiene como objeto informarle sobre el tratamiento que se le
              dará a sus datos personales cuando los mismos son recabados,
              utilizados, almacenados y/o transferidos por LA EMPRESA.
            </p>
            <p>
              Las condiciones contenidas en el presente Aviso de Privacidad
              son acorde a lo dispuesto en la Ley Federal de Protección de
              Datos Personales en Posesión de los Particulares (en adelante,
              LFPDPPP) y demás legislación vigente aplicable, condiciones
              aplicables a toda la información que recabe LA EMPRESA de EL
              TITULAR.
            </p>
            <strong>
              Responsable del tratamiento de sus datos personales
            </strong>
            <p>
              LA EMPRESA con domicilio en José Eleuterio González #250, Int.
              113-V, Col. Santa María, Monterrey, Nuevo León, código postal
              64650, será el responsable y, por ende, quien decidirá sobre
              el tratamiento que se le dé a sus datos personales, siempre
              con su consentimiento.
            </p>
            <strong> Datos personales que se recaban</strong>
            <p>
              {" "}
              LA EMPRESA podrá recabar, almacenar, utilizar y, en su caso,
              transferir datos personales, es decir, información que lo
              identifica o lo hace identificable. Los datos personales y
              sensibles que recabará LA EMPRESA son los siguientes:{" "}
            </p>
            <ul className="pl-4">
              <li>Nombre y Apellido,</li>
              <li>Correo electrónico de EL TITULAR,</li>
              <li>Contraseña,</li>
              <li>Intentos de cargos realizados,</li>
              <li>IP DE LA SESIÓN ACTUAL,</li>
              <li>Último IP que utilizó para iniciar sesión,</li>
              <li>Evento/s a los que ha comprado boleto/s,</li>
              <li>Tipo de boleto/s comprado/s,</li>
              <li>
                Historial de número de accesos y tiempo navegado en cada
                evento,
              </li>
              <li>Firma electrónica,</li>
              <li>Edad,</li>
              <li>Genero,</li>
              <li>Fecha de Nacimiento,</li>
              <li>Teléfono de contacto,</li>
              <li>Ciudad,</li>
              <li>Estado,</li>
              <li> Nacionalidad,</li>
            </ul>
            <p>En caso de facturación:</p>
            <ul className="pl-4">
              <li> Registro Federal de Contribuyentes,</li>
              <li> Domicilio fiscal,</li>
              <li> Nombre/ denominación social,</li>
            </ul>
            <p>
              Los datos sensibles que recabará LA EMPRESA son los
              siguientes:
            </p>

            <ul className="pl-4">
              <li>
                Datos de la tarjeta de crédito/débito con la que se compra
                los boletos,{" "}
              </li>
              <li>
                Banco de la tarjeta de crédito/débito de la compra de
                boleto/s,
              </li>
              <li>Nombre del tarjetahabiente,</li>
              <li>Método de pago utilizado para cada compra,</li>
              <li>Código de autorización de transacciones aprobadas,</li>
              <li>
                Dirección asociada con la tarjeta de crédito/debito de la
                compra de boleto/s,
              </li>
            </ul>

            <p>
              Para eventos específicos, LA EMPRESA podrá recabar, utilizar,
              almacenar o transferir datos personales y/o sensibles
              adicionales a los antes señalados, para lo cual le hará del
              conocimiento de EL TITULAR durante el proceso de compra del
              boleto.
            </p>
            <p>
              En ese sentido, los datos personales y/o sensibles adicionales
              a los antes señalados que solicite LA EMPRESA de EL TITULAR,
              serán recabados, utilizados, almacenados o transferidos bajo
              las condiciones del presente Aviso de Privacidad (EL
              PRINCIPAL).
            </p>
            <strong>
              Finalidades del tratamiento de los datos personales
            </strong>
            <p>
              LA EMPRESA recaba, utiliza, almacena o transfiere sus datos
              personales, en la medida en que las leyes aplicables lo
              permiten, para llevar a cabo lo siguiente:{" "}
            </p>
            <ul className="pl-4">
              <li>Vender boleto/s de diversos eventos,</li>
              <li>Entregar al cliente el boleto comprado,</li>
              <li>Seguimiento en el proceso de compra-venta del boleto,</li>
              <li>Protección de los derechos de consumidor EL TITULAR,</li>
              <li>Cumplimiento con obligaciones legales,</li>
              <li>Cobranza y procesos de pagos,</li>
              <li>
                Proporcionarle información sobre productos o servicios de LA
                EMPRESA,
              </li>
              <li>Desarrollo de nuevos servicios, </li>
              <li>
                Estudios para determinar la satisfacción de EL TITULAR,
              </li>
              <li>
                Solicitarle/utilizar información para efectos de
                investigación de mercado,{" "}
              </li>
              <li>
                Conocer el mercado y el desempeño de LA EMPRESA dentro de
                éste,{" "}
              </li>
              <li>
                Para solicitarle a EL TITULAR que responda algunas encuestas
                de satisfacción con respecto a nuestros servicios,
              </li>
              <li>
                Para seguimiento a algún problema que haya surgido respecto
                a pedidos o entregas de boleto/s,
              </li>
              <li>
                Para hacerle llegar información de la empresa con fines
                publicitarios o de promoción,
              </li>
              <li>
                Almacenar información de compra de EL TITULAR para
                estadísticas comerciales,{" "}
              </li>
              <li>
                Interrelación de bases de datos para conocer el perfil y las
                necesidades de EL TITULAR,
              </li>
              <li>
                Envío de promociones sobre nuestros productos o servicios de
                acuerdo a lo que consideramos son los intereses de EL
                TITULAR,{" "}
              </li>
              <li>
                Hacerle llegar comunicaciones de todo tipo, provenientes de
                LA EMPRESA
              </li>
              <li>Para efectos estadísticos y comerciales,</li>
              <li>
                Hacerle llegar a EL TITULAR a discreción de LA EMPRESA
                mensajes que puedan ser de su interés, ya sean propios o
                bien de nuestros anunciantes y publicidad de terceros con
                quién LA EMPRESA tenga celebrados convenios o tenga relación
                comercial,
              </li>
              <li>
                Hacerle llegar a EL TITULAR publicidad de terceros con quién
                LA EMPRESA tenga celebrados convenios y contenidos,
              </li>
            </ul>

            <p>
              LA EMPRESA podrá hacer uso de sus datos personales y datos
              personales sensibles para otras finalidades, siempre y cuando
              dichas finalidades sean compatibles con las anteriores y se le
              avise a EL TITULAR de forma previa, dando su consentimiento.
            </p>
            <p>
              Sus datos personales serán tratados únicamente por el tiempo
              necesario a fin de cumplir con las finalidades descritas y/o
              de conformidad con lo que establezcan las disposiciones
              legales aplicables.
            </p>
            <p>
              En el caso de las finalidades para cuestiones estadísticas y
              comerciales, sus datos serán utilizados de forma anónima, esto
              es, para dichas finalidades, los datos no podrán asociarse a
              EL TITULAR ni permitir, por su estructura, contenido o grado
              de desagregación, la identificación del mismo por lo que en
              los informes que se realicen con dicha información, no podrá
              ser factible que se identifique a las personas sobre las
              cuales se utilizaron sus datos para fines estadísticos y/o
              comerciales.
            </p>
            <p>
              Igualmente, en caso de que sus datos personales pretendan ser
              utilizados para una finalidad distinta a lo mencionando con
              anterioridad, LA EMPRESA le notificará por correo electrónico
              o por teléfono, a fin de obtener su consentimiento para el
              tratamiento de sus datos personales de acuerdo a las nuevas
              finalidades. De no recibir su consentimiento, para los casos
              en que es necesario LA EMPRESA no utilizará sus datos
              personales para esas nuevas finalidades.{" "}
            </p>
            <p>
              EL TITULAR al realizar una compra a través de la plataforma de
              LA EMPRESA otorga su pleno consentimiento libre y
              voluntariamente (sin vicios en el consentimiento) de
              proporcionar los datos personales y datos personales sensibles
              que le sean requeridos, esto, para que LA EMPRESA pueda
              recabar, utilizar, almacenar y transferir sus datos personales
              y datos personales sensibles bajo los términos y condiciones
              del presente Aviso de Privacidad.
            </p>
            <p>
              LA EMPRESA en ningún caso y bajo ninguna circunstancia
              almacena información de datos personales sensibles como origen
              racial o étnico, estado de salud actual, información genética,
              creencias religiosas, filosóficas y morales, afiliación
              sindical, opiniones políticas, preferencia sexual, entre
              otros.
            </p>
            <p>
              En caso de que no desee que sus datos personales sean tratados
              para alguna o todas las finalidades mencionadas con
              antelación, desde este momento usted nos puede comunicar lo
              anterior al correo hola@handypass.com o directamente en el
              domicilio de LA EMPRESA, indicándonos en el cuerpo del correo
              su nombre, su relación con LA EMPRESA y que no desea que sus
              datos sean tratados para alguna finalidad en específico o para
              todas en general. En caso de que usted se oponga a todas las
              finalidades (o a ciertas finalidades de forma específica, que
              en caso que se oponga le haremos saber en la contestación a su
              correo electrónico), no será posible que realice compras a
              través de la página de LA EMPRESA, pues algunas finalidades de
              las antes señalas son necesarias para completar el servicio
              que le brinda LA EMPRESA.
            </p>

            <strong>Seguridad de los datos personales</strong>
            <p>
              LA EMPRESA implementará las medidas de seguridad, técnicas,
              administrativas y físicas, necesarias para proteger sus datos
              personales y datos personales sensibles y evitar su daño,
              pérdida, alteración, destrucción o el uso, acceso o
              tratamiento no autorizado.{" "}
            </p>
            <p>
              Únicamente el personal autorizado, que ha cumplido y observado
              los correspondientes requisitos de confidencialidad, podrá
              participar en el tratamiento de sus datos personales. El
              personal autorizado tiene prohibido permitir el acceso de
              personas no autorizadas y utilizar sus datos personales para
              fines distintos a los establecidos en el presente Aviso de
              Privacidad. La obligación de confidencialidad de las personas
              que participan en el tratamiento de sus datos personales
              subsiste aun después de terminada la relación con LA EMPRESA.{" "}
            </p>
            <p>
              La página de internet de LA EMPRESA,{" "}
              <a
                href="https://www.handypass.io"
                target="_blank"
                rel="noopener noreferrer">
                www.handypass.io
              </a>{" "}
              , contiene enlaces, vínculos y redirecciones (LOS VINCULOS)
              que al oprimirlos o utilizarlos lo lleva a otros sitios de
              Internet, mismos que no son propiedad de LA EMPRESA. En ese
              sentido, EL TITULAR podrá interactuar, participar o utilizar
              los servicios de o por parte de terceros, tales como
              anunciantes, patrocinadores o promocionales por medio de LOS
              VINCULOS, por lo que LA EMPRESA no es responsable por la
              información, contenido, materiales, acciones y/o servicios
              ofrecidos y /o proporcionados por terceros a EL TITULAR a
              través de los VINCULOS. De esta manera, la información que EL
              TITULAR llegue a proporcionar a través de esos sitios, no se
              encuentra cubierta por el presente Aviso de Privacidad, por lo
              que recomendamos a nuestros usuarios verificar los avisos y
              políticas de privacidad desplegadas o aplicables a estos
              sitios o portales de terceros.
            </p>

            <strong>
              Comunicaciones y Transferencias de datos personales
            </strong>
            <p>
              Como parte de las operaciones propias del negocio y a fin de
              cumplir con las finalidades descritas; LA EMPRESA podrá
              compartir con terceros, nacionales o extranjeros, algunos o
              todos sus datos personales. Dichos terceros podrán ser
              compañías afiliadas o subsidiarias de LA EMPRESA; asesores
              profesionales externos, patrocinadores, publicistas,
              anunciantes potenciales, contratistas, socios comerciales y/u
              otros prestadores de servicios, esto, para las finalidades
              antes descritas.
            </p>
            <p>
              Asimismo, LA EMPRESA transferirá sus datos financieros con el
              organizador del evento relacionado a la compra del boleto
              (mismo que dependerá de cada evento para el cual compre un
              boleto) a través del portal de LA EMPRESA, con la finalidad de
              que el mismo tenga certeza de los datos de compra de los
              boletos vendidos de su evento. Al respecto, se precisa que LA
              EMPRESA no transferirá ni los últimos 16 dígitos de la tarjeta
              de compra de crédito/débito ni la fecha de expiración de la
              misma.
            </p>
            <p>
              Asimismo, LA EMPRESA procurará que dichos terceros mantengan
              medidas de seguridad, administrativas, técnicas y físicas
              adecuadas para resguardar sus datos personales, así como que
              dichos terceros únicamente utilicen sus datos personales para
              las finalidades para las cuales fueron contratados y de
              conformidad con el presente Aviso de Privacidad.{" "}
            </p>
            <p>
              LA EMPRESA no cederá, venderá o transferirá sus datos
              personales, a terceros no relacionados con LA EMPRESA, sin su
              consentimiento previo. Sin embargo, LA EMPRESA podrá
              transferir sus datos personales en los casos previstos en la
              LFPDPPP.{" "}
            </p>
            <p>
              En caso de que EL TITULAR desee oponerse al tratamiento de sus
              datos personales para las finalidades previstas en este
              apartado, podrá hacerlo mediante comunicado enviando un correo
              electrónico al encargado del tratamiento y seguimiento de esta
              información. EL TITULAR podrá solicitar que LA EMPRESA que no
              se compartan o cedan sus datos personales.
            </p>
            <p>
              LA EMPRESA también podrá revelar información cuando por
              mandato de ley y/o de autoridad competente le fuere requerido
              o por considerar de buena fe que dicha revelación es necesaria
              para: i. cumplir con investigaciones, carpetas de
              investigación o juicios legales, ii. cumplir con la relación
              comercial de EL TITULAR; iii. responder reclamaciones que
              involucren cualquier contenido que menoscabe derechos de
              terceros o del propio TITULAR y/o; iv. proteger los derechos,
              la propiedad, o la seguridad de LA EMPRESA, sus clientes, EL
              TITULAR y el público en general.
            </p>

            <strong>
              Responsabilidad sobre la obtención, uso, almacenamiento y
              transferencias de la Información Financiera. recabar
            </strong>
            <p>
              LA EMPRESA hace del conocimiento de EL TITULAR que aún y
              cuando recaba información financiera, no almacena el 100% de
              la misma.
            </p>
            <p>
              Al respecto, LA EMPRESA podrá utilizar la información
              financiera (datos personales sensibles) de EL TITULAR para
              verificar que cumpla con los requisitos que contemplan tanto
              las leyes financieras como las instituciones financieras del
              país para i. realizar ventas y otras transacciones a través de
              la página de internet{" "}
              <a
                href="https://www.handypass.io"
                target="_blank"
                rel="noopener noreferrer">
                www.handypass.io
              </a>{" "}
              de manera eficiente, ii. para entregar los bienes y servicios
              que ha solicitado a través de la página antes referida, iii.
              para inscribirlo en programas de cliente frecuente, beneficios
              comerciales, descuentos, rembolsos y cualquier otro programa
              similar que organice LA EMPRESA a favor de EL TITULAR, iv.
              para evitar temas de lavado de dinero y fraude y v. para
              realizar las operaciones comerciales de forma segura
              financieramente para EL TITULAR y LA EMPRESA.
            </p>
            <p>
              En algunos casos LA EMPRESA podrá utilizar, y consecuentemente
              proporcionarles a proveedores de servicios, su información
              financiera para ayudarnos a realizar algunas de estas tareas.
              En esos casos en concreto, LA EMPRESA procurará que dichos
              terceros tengan implementado sus medidas y políticas de uso,
              obtención, almacenamiento y transferencia de datos personales
              y datos personales sensibles en apego a la LFPDPPP, mismas que
              deberá de ser igual cuando memos al tratamiento que le da LA
              EMPRESA a la información de EL TITULAR.{" "}
            </p>
            <p>
              Adicionalmente, LA EMPRESA podrá proporcionar su información
              financiera a emisores de tarjetas de crédito, instituciones
              financieras o procesadores de tarjetas de crédito para que
              procesen transacciones y para otros de sus propósitos.{" "}
            </p>
            <p>
              LA EMPRESA también podrá proporcionarle su información
              financiera a los organizadores de eventos (tal y como se
              precisó en el apartado de transferencias de datos personales
              en el presente Aviso de Privacidad), principalmente con el
              propósito de facilitar la entrega de los boletos, entregarle
              información de la venta de boletos de sus eventos, evitar y
              resolver disputas y servicio al cliente en el lugar del evento
              y en la fecha del evento, y podemos proporcionar su
              información financiera a aquellos comerciantes que le están
              proporcionando bienes, servicios o reservaciones que usted
              compró u ordenó, o bien que están administrando las ofertas de
              los programas a los que ingresó en la página{" "}
              <a
                href="https://www.handypass.io"
                target="_blank"
                rel="noopener noreferrer">
                www.handypass.io
              </a>{" "}
              (o que ingresó por medio de LOS VÍNCULOS después de hacer clic
              en un enlace de la página de internet antes señalada que
              promovía tal oferta o programa).
            </p>
            <p>
              Asimismo, LA EMPRESA podría revelar la información financiera
              específica de EL TITULAR cuando se determine que tal
              revelación puede ser útil para i. cumplir con investigaciones,
              carpetas de investigación o juicios legales, cooperación o
              asistencia por parte de autoridades de seguridad pública o
              ministerio público, ii. cumplir con la relación comercial de
              EL TITULAR; iii. responder reclamaciones que involucren
              cualquier contenido que menoscabe derechos de terceros o del
              propio TITULAR, iv. evitar el fraude y el lavado de dinero
              y/o; v. proteger los derechos, la propiedad, o la seguridad de
              LA EMPRESA, sus clientes, EL TITULAR y el público en general.
            </p>
            <p>
              Por último, LA EMPRESA podrá suministrar la información
              financiera de EL TITULAR a terceros en el caso de una
              transferencia de propiedad o, en un proceso de quiebra de LA
              EMPRESA.{" "}
            </p>
            <p>
              LA EMPRESA no es responsable o de cualquier otra manera
              responsable legalmente de cualquier uso o divulgación de su
              información financiera por parte de un tercero con quien LA
              EMPRESA tenga permitido divulgar su información financiera
              bajo este aviso de privacidad.
            </p>

            <strong>Derechos que le corresponden</strong>
            <p>
              EL TITULAR, podrá ejercitar ante LA EMPRESA los derechos de
              acceso, rectificación, cancelación y oposición respecto al
              tratamiento de sus datos personales. Asimismo, podrá revocar,
              en todo momento, el consentimiento que haya otorgado y que
              fuere necesario para el tratamiento de sus datos personales. A
              manera de referencia, a continuación se describen los derechos
              de acceso, rectificación, cancelación y oposición:{" "}
            </p>
            <p>
              A través de su derecho de acceso EL TITULAR, podrá solicitar
              se le informe qué tipo de datos personales están siendo
              tratados por LA EMPRESA, el origen de dichos datos y las
              comunicaciones que se hayan realizado con los mismos. Para
              ejercer su derecho de acceso. EL TITULAR lo podrá ejercer
              mediante comunicado enviado al correo electrónico que se
              señala en el presente apartado, del encargado del tratamiento
              y seguimiento de esta información.{" "}
            </p>
            <p>
              Por virtud del derecho de rectificación, EL TITULAR, puede
              solicitar se corrija o complete los datos personales que sean
              incorrectos o estén incompletos y que obren en manos de LA
              EMPRESA. EL TITULAR tendrá la obligación de informar a LA
              EMPRESA cuando deba hacerse un cambio o corrección en sus
              datos personales y este hecho solo sea de su conocimiento.
              Para ejercer su derecho de rectificación, EL TITULAR lo podrá
              ejercer mediante comunicado enviado al correo electrónico que
              se señala en el presente apartado, del encargado del
              tratamiento y seguimiento de esta información.
            </p>
            <p>
              Respecto el derecho de cancelación, cuando sus datos
              personales hayan dejado de ser necesarios para los fines para
              los cuales se recabaron, EL TITULAR podrá solicitar que los
              mismos sean cancelados si es que aún se conservaran en las
              bases de datos de LA EMPRESA. EL TITULAR podrá ejercerlo
              mediante comunicado enviado al correo electrónico que se
              señala en el presente apartado, del encargado del tratamiento
              y seguimiento de esta información. El proceso de cancelación
              irá precedido de un periodo de bloqueo mediante el cual
              únicamente se almacenarán sus datos por un tiempo, equivalente
              al plazo de prescripción de las acciones que dieron origen al
              tratamiento de sus datos personales o el periodo que por ley
              se establezca. Existen casos en que los datos personales no
              pueden ser cancelados por disposición de ley.{" "}
            </p>
            <p>
              Sobre el derecho de oposición, EL TITULAR puede oponerse al
              tratamiento de sus datos personales, cuando exista una causa
              legítima para ello, incluso habiendo expresado anteriormente
              su consentimiento para dicho tratamiento lo podrá ejercer. EL
              TITULAR podrá ejercerlo mediante comunicado enviado al correo
              electrónico que se señala en el presente apartado, del
              encargado del tratamiento y seguimiento de esta información{" "}
            </p>
            <p>
              Al efecto, LA EMPRESA pone a la disposición de EL TITULAR el
              Aviso de Privacidad integral en la{" "}
              <a
                href="https://www.handypass.io"
                target="_blank"
                rel="noopener noreferrer">
                www.handypass.io
              </a>
              , a partir del 1 de enero de 2018. Para efecto de las
              solicitudes de acceso, rectificación, cancelación y oposición,
              se podrán solicitar al correo electrónico que enseguida se
              señala; hola@handypass.com, quién es el encargado por parte de
              LA EMPRESA del manejo y tratamiento de los datos personales de
              EL TITULAR.{" "}
            </p>
            <p>
              Sin perjuicio en lo anterior, EL TITULAR puede solicitar el
              ejercicio de sus derechos ARCO a través de cualquier
              comunicado con la empresa, siendo que la vía indicada para
              ejercer los mismos es a través del correo electrónico antes
              precisado.
            </p>

            <strong>Cambios al Aviso de Privacidad</strong>
            <p>
              LA EMPRESA se reserva el derecho de enmendar o modificar el
              presente Aviso de Privacidad como estime conveniente, por
              ejemplo, para cumplir con cambios a la legislación sobre
              protección de datos o cumplir con disposiciones internas de LA
              EMPRESA.{" "}
            </p>
            <p>
              LA EMPRESA le informará a EL TITULAR y pondrá a su disposición
              el Aviso de Privacidad actualizado cuando se le hagan cambios
              significativos al mismo, así como cuando se requiera recabar
              su consentimiento.{" "}
            </p>
            <p>
              En caso de cambio de correo electrónico del encargado, de
              teléfono de contacto o del domicilio de LA EMPRESA, se le
              notificará a EL TITULAR vía correo electrónico; asimismo en
              caso de no recibir el correo por parte de EL TITULAR, para
              efectos de cualquier comunicación, EL TITULAR deberá verificar
              el Aviso de Privacidad integral de LA EMPRESA, mismo que se
              encuentra en la página de internet{" "}
              <a
                href="https://www.handypass.io"
                target="_blank"
                rel="noopener noreferrer">
                www.handypass.io
              </a>
              , para verificar si no ha habido algún cambio en los datos de
              contacto de LA EMPRESA, esto es, correo electrónicos del
              encargado, teléfono o dirección, a efecto de dirigirse a LA
              EMPRESA de manera correcta.
            </p>

            <strong>Departamento de Protección de Datos</strong>
            <p>
              EL TITULAR podrá dirigir cualquier pregunta o comentario
              respecto del presente Aviso de Privacidad o ejercitar los
              derechos que por la LFPDPPP le correspondan, ante LA EMPRESA
              ubicada en José Eleuterio González #250, Int. 113-V, Col.
              Santa María, Monterrey, Nuevo León, código postal 64650, o al
              correo electrónico del encargado del tratamiento de datos
              personales, señalado en el presente Aviso de Privacidad.
            </p>

            <stong>
              EL TITULAR manifiesta que ha leído y entendido el presente
              Aviso de Privacidad y acepta expresamente la forma en la que
              LA EMPRESA tratará sus datos personales y datos personales
              sensibles, de acuerdo a la Ley Federal de Protección de Datos
              Personales en Posesión de los Particulares y que otorga su
              consentimiento para que LA EMPRESA realice transferencias y/o
              remisiones de datos personales de conformidad con lo que
              establece el presente Aviso de Privacidad.
            </stong>
            <p />
            <p class="font-italic">
              Última actualización: 1 de marzo de 2018
            </p>
          </div>
        </Container>
      </>
    );
  }
}
export default PrivacyPolicy;
