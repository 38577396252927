import React, { Component } from "react";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import Image from "../image";
//import Slider from "react-slick";

class SliderCenterMode extends Component {
  state = {
    headerImage: true
  };
  render() {
    return (
      <div className="text-center">
        {/*<Slider {...settings}>*/}
        <ReactPlaceholder
          type="media"
          showLoadingAnimation={true}
          rows={7}
          ready={true}
        >
          <Image imgUrl={this.props.headerImage} theme={{ width: "100%" }} />
        </ReactPlaceholder>
        {/*<img src={this.props.headerImage} alt="event" />
        {/*</Slider>*/}
      </div>
    );
  }
}

export default SliderCenterMode;
