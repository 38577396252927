import React from 'react';
import './styles.scss';

export default function TermsAndConditions() {
  return (
    <div>
      <p className="p3">POR FAVOR LEA ATENTAMENTE EL SIGUIENTE AVISO LEGAL.</p>
      <p className="p3">
        El presente aviso legal expresa y regula los t&eacute;rminos de uso del sitio de internet
        con ubicaci&oacute;n en la direcci&oacute;n de URL{' '}
        <span className="s1">www.handypass.io</span> (en lo sucesivo, EL SITIO). Todo el contenido,
        as&iacute; como su informaci&oacute;n (incluyendo, de manera enunciativa mas no limitativa:
        el texto, logotipos, contenido y fotograf&iacute;as)
        <span className="Apple-converted-space">&nbsp; </span>de EL SITIO est&aacute;n protegidos
        por las leyes de Derecho de Autor de los Estados Unidos Mexicanos. Al ingresar y navegar por
        EL SITIO, confirma su consentimiento y acepta
        <span className="Apple-converted-space">&nbsp; </span>los t&eacute;rminos de uso que EL
        SITIO ofrece. En caso de usted no est&eacute; de acuerdo con dichos t&eacute;rminos y
        condiciones de uso, le recomendamos y sugerimos que abandone y no haga uso de EL SITIO.
      </p>
      <p className="p3">
        Este es el contrato oficial (en lo sucesivo, EL CONTRATO) que regula los t&eacute;rminos de
        uso de la p&aacute;gina <span className="s1">www.handypass.io</span> con el usuario (en lo
        sucesivo EL USUARIO), misma que es proporcionado por EVENTOS CROWDFRONT S.A. DE C.V., (en lo
        sucesivo, LA EMPRESA). El presente contrato regula y expresa los t&eacute;rminos de uso de
        EL SITIO, as&iacute; como su informaci&oacute;n (incluyendo, de manera enunciativa
        m&aacute;s no limitativa: el texto, logotipos, contenido y fotograf&iacute;as.)
      </p>
      <p className="p3">
        Al usar o visitar EL SITIO,&nbsp; expresamente EL USUARIO manifiesta su conformidad y se
        obligas con los t&eacute;rminos y condiciones de EL SITIO,&nbsp; as&iacute; como de todas
        las leyes y reglamentos aplicables de conformidad a la legislaci&oacute;n vigente y
        aplicable&nbsp; para su uso de EL SITIO. Desde este momento, LA EMPRESA se reserva el
        derecho a modificar en cualquier comento las condiciones y t&eacute;rminos de uso de EL
        SITIO.
      </p>
      <p className="p3">
        <span className="s1">
          <strong>Disposiciones Generales:</strong>
        </span>
      </p>

      <strong>1. POLITICA DE COMPRA DE BOLETOS</strong>

      <p className="p3">
        Para la compra de boletos a trav&eacute;s e EL SITIO, EL USUARIO deber&aacute; revisar la{' '}
        <span className="s1">Pol&iacute;tica de venta</span> de LA EMPRESA, la cual regir&aacute; la
        relaci&oacute;n comercial entre EL USUARIO y LA EMPRESA en la compra de cualquier boleto a
        trav&eacute;s de EL SITIO.
      </p>

      <strong>2. PROPIEDAD INTELECTUAL.</strong>

      <p className="p3">
        EL SITIO, la informaci&oacute;n y<span className="Apple-converted-space">&nbsp; </span>el
        contenido son de propiedad intelectual de LA EMPRESA o de alguno de nuestros proveedores,
        anunciantes, socios, colaboradores, empleados u otras terceras personas, y esta
        informaci&oacute;n podr&aacute; estar protegida bajo la Ley Federal del Derecho de Autor, la
        Ley de la Propiedad Industrial, alguna de las convenciones internacionales de las que
        M&eacute;xico forma parte, o bien, por alguna otra legislaci&oacute;n aplicable a la
        materia. La informaci&oacute;n y contenido, incluye de manera enunciativa mas no limitativa
        a: los textos, reportes, informaci&oacute;n, escritos, art&iacute;culos, videos, podcasts,
        directorios, manuales, fotograf&iacute;as, anuncios, cupones, animaciones, im&aacute;genes,
        dise&ntilde;os, o cualquier otro material, informaci&oacute;n o contenido, ya sea tangible o
        no tangible.
      </p>

      <p className="p3">
        De igual manera, LA EMPRESA no garantiza que la informaci&oacute;n que ofrece EL SITIO sea
        exacta, actualizada, confiable, sin errores, puntual, integra, fiable y confiable. LA
        EMPRESA no se hace responsable por alguna decisi&oacute;n o acci&oacute;n que afecte,
        directa o indirectamente a EL USUARIO derivada y/o basada en informaci&oacute;n que ofrece
        EL SITIO. LA EMPRESA no ofrece garant&iacute;a sobre cualquiera de los contenidos que se
        observan en EL SITIO y<span className="Apple-converted-space">&nbsp; </span>se excluye de
        toda responsabilidad con respecto a EL SITIO, su contenido o el uso indebido que EL USUARIO
        de a EL SITIO. LA EMPRESA no se hace responsable por da&ntilde;os, perjuicios o
        p&eacute;rdidas de cualquier &iacute;ndole que surjan del acceso a EL SITIO o del uso de sus
        contenidos.
      </p>

      <strong>3. DERECHOS DE EL USUARIO.</strong>

      <p className="p3">
        EL USUARIO no tendr&aacute; ning&uacute;n derecho sobre el contenido e informaci&oacute;n
        que se encuentre en EL SITIO; de la misma manera no lo podr&aacute; usar para su uso ni
        explotaci&oacute;n. EL USUARIO solo tiene el derecho a navegar y utilizar el servicio que EL
        SITIO ofrece, bajo los t&eacute;rminos y condiciones establecidos en EL CONTRATO. En caso
        que EL USUARIO utilice informaci&oacute;n de EL SITIO, deber&aacute; de informar que la
        informaci&oacute;n utilizada proviene de EL SITIO, lo anterior respetando el derecho que
        alg&uacute;n tercero posea sobre dicha informaci&oacute;n y/o contenido utilizada por EL
        USUARIO.
      </p>

      <strong>4. MODIFICACIONES.</strong>

      <p className="p3">
        LA EMPRESA se reserva el derecho en todo momento para modificar los t&eacute;rminos y
        condiciones de uso de EL CONTRATO. Las modificaciones no estar&aacute;n sujetas a previo
        aviso, pues una vez que se realicen los cambios en EL CONTRATO y en EL SITIO, estos entraran
        en vigor y por ende, EL USUARIO tendr&aacute; que adaptarse y cumplir con los nuevos
        t&eacute;rminos y condiciones que se deriven de cualquier modificaci&oacute;n a los mismos.
      </p>

      <strong>5. BUEN USO DE EL SITIO.</strong>

      <p className="p3">
        EL USUARIO acepta los t&eacute;rminos de uso y se compromete a utilizar el servicio conforme
        a lo que se establece en las leyes<span className="Apple-converted-space">&nbsp; </span>
        <span className="s2">
          nacionales, estatales, locales, y en ciertos casos a tratados internacionales
        </span>
        ; as&iacute; como actuar de acuerdo a la moral, las buenas costumbres y de buena fe. Se le
        proh&iacute;be al EL USUARIO utilizar el servicio de EL SITIO para fines il&iacute;citos o
        contrarios a lo establecido en los t&eacute;rminos de uso. Al mismo tiempo, EL USUARIO es el
        &uacute;nico responsable por las actividades que desempe&ntilde;e o que ocurran en, durante,
        a trav&eacute;s o bajo su navegaci&oacute;n por EL SITIO. Agregando que EL USUARIO no
        deber&aacute; facilitar a terceros la utilizaci&oacute;n de EL SITIO cuando existan
        elementos para considerar que estos mismos lo pretenden utilizar para fines contrarios a los
        t&eacute;rminos de uso y/o fines il&iacute;citos.
      </p>

      <strong>6. PUBLICACIONES.</strong>

      <p className="p3">
        LA EMPRESA pone a disposici&oacute;n ciertas &aacute;reas de EL SITIO, sin limitarse a blogs
        o foros, para que EL USUARIO exprese su opini&oacute;n, ideas, externe comentarios,
        mensajes, sugerencias u otro tipo de informaci&oacute;n (en lo sucesivo, PUBLICACIONES). EL
        USUARIO manifiesta y reconoce que dichas PUBLICACIONES ser&aacute;n responsabilidad total y
        &uacute;nicamente de cada usuario que decida interactuar y/o comunicarse por medio de estas
        disposiciones a trav&eacute;s de EL SITIO. Las PUBLICACIONES bajo ninguna circunstancia
        expresan las ideas, ideolog&iacute;a y/o intenciones de EL SITIO. EL SITIO no monitorea,
        edita, revisa, elimina, o modifica ninguna de las PUBLICACIONES a pesar de reservarnos el
        derecho para realizarlo. En caso de que LA EMPRESA, a su discreci&oacute;n determina que
        alguna de las PUBLICACIONES transgreda los derechos de un tercero, viole los t&eacute;rminos
        y condiciones de uso de EL CONTRATO o los pudiera violar, LA EMPRESA se reserva el derecho a
        (i) no permitirle que lleve a cabo publicaciones; (ii) eliminar dichas publicaciones; (iii)
        revocar el derecho de EL USUARIO para utilizar EL SITIO y (iv) utilizar medios
        tecnol&oacute;gicos legales para, sin limitaci&oacute;n, bloquear direcciones IP o
        desactivar su navegaci&oacute;n por EL SITIO. Al generar alguna de las PUBLICACIONES, EL
        USUARIO reconoce y acepta que no se reserva ning&uacute;n derecho sobre su
        publicaci&oacute;n y autoriza a EL SITIO a utilizarlo total o parcialmente, en cualquier
        medio o de cualquier forma seg&uacute;n sea su conveniencia.&nbsp;
      </p>

      <p className="p3">
        Las PUBLICACIONES que sean con fines comerciales o de las cuales EL USUARIO pretenda obtener
        alg&uacute;n beneficio, ingreso o promoci&oacute;n se le considerara contrario a los
        t&eacute;rminos y condiciones de uso de EL CONTRATO.
      </p>

      <strong>7. EXCLUSION DE RESPONSABILIDAD (VINCULOS A TERCEROS) </strong>

      <p className="p3">
        EL SITIO contiene enlaces, v&iacute;nculos y redirecciones que lo llevan a otros sitios (en
        lo sucesivo, VINCULOS). Asimismo, EL USUARIO podr&aacute; interactuar, participar o utilizar
        los servicios de o por parte de terceros, tales como anunciantes, patrocinadores o
        promocionales por medio de los VINCULOS. LA EMPRESA no es responsable por la
        informaci&oacute;n, contenido, materiales, acciones y/o servicios ofrecidos por los terceros
        a trav&eacute;s de los VINCULOS. Aunado, LA EMPRESA no ser&aacute; responsable por la
        interacci&oacute;n, comunicaci&oacute;n o participaci&oacute;n de EL USUARIO con los
        VINCULOS.<span className="Apple-converted-space">&nbsp; </span>Los sitios a los que se tenga
        acceso a trav&eacute;s de los VINCULOS no son propiedad de LA EMPRESA por lo que no es
        responsable de su contenido. Es por eso, que sin perjuicio alguno de la legislaci&oacute;n
        aplicable, LA EMPRESA no conceder&aacute; garant&iacute;a alguna sobre la confiabilidad,
        licitud, exactitud, disponibilidad, utilidad o cualquier aspecto sobre el contenido de los
        VINCULOS. Es por eso que, de manera enunciativa mas no limitativa, LA EMPRESA
        <span className="Apple-converted-space">&nbsp; </span>no ser&aacute; responsable por
        da&ntilde;o alguno ya sea directo, secundario, indirecto o de alguna otra manera. De igual
        forma no ser&aacute; responsable de consecuencias de los VINCULOS debidos a: (i) La
        inexactitud de la informaci&oacute;n contenida en las p&aacute;ginas del sitio; (ii) el mal
        funcionamiento del sitio o (iii) la transmisi&oacute;n de virus o alguna transmisi&oacute;n
        da&ntilde;ina hacia el equipo a trav&eacute;s del sitio.
      </p>

      <strong>8. FALLOS EN EL SISTEMA</strong>

      <p className="p3">
        LA EMPRESA no se har&aacute; responsable por cualquier da&ntilde;o, perjuicio o
        p&eacute;rdida, ya sea directo, indirecto, causado a EL USUARIO debido a fallas en el
        sistema, en el servidor o el internet. De igual manera, LA EMPRESA no ser&aacute;
        responsable por cualquier virus o que infecte el equipo de EL USUARIO derivado del uso o
        acceso de EL SITIO. LA EMPRESA no garantiza el uso continuado de EL SITIO, esto debido a
        fallas en el internet, detalles t&eacute;cnicos, reestructuraci&oacute;n de EL SITIO,
        mantenimiento a EL SITIO o alguna causa ajena o no a LA EMPRESA. EL USUARIO reconoce y
        acepta que LA EMPRESA no tendr&aacute; responsabilidad alguna y no podr&aacute; inculparla
        por cualquier incidente anteriormente mencionado.
      </p>

      <strong>9. TECNOLOGIA COOKIE</strong>

      <p className="p3">
        LA EMPRESA utiliza cookies cuando EL USUARIO navega por EL SITIO. Las cookies se relacionan
        &uacute;nicamente con un Usuario an&oacute;nimo y su ordenador. Es por esto que no
        proporcionan referencias que permitan deducir el nombre y apellidos de EL USUARIO. Las
        cookies de LA EMPRESA no pueden leer datos de su disco duro ni leer los archivos cookie
        creados por otros proveedores. El uso de la tecnolog&iacute;a de las cookies, permite que LA
        EMPRESA reconozca a EL USUARIO registrado despu&eacute;s de que &eacute;stos se hayan
        registrado por primera vez, sin que se tengan que registrar en cada visita para acceder a
        las &aacute;reas y servicios reservados exclusivamente a ellos. EL USUARIO tiene el derecho
        de configurar su navegador para ser avisado en pantalla de la recepci&oacute;n de cookies y
        para impedir la instalaci&oacute;n de las mismas en su disco duro. Por favor, consulte las
        instrucciones y manuales de su navegador para ampliar esta informaci&oacute;n. Para utilizar
        el Servicio, no es necesario que EL USUARIO permita la instalaci&oacute;n de las cookies
        enviadas por LA EMPRESA. En caso de impedir la instalaci&oacute;n de cookies es probable que
        EL SITIO no funcione adecuadamente y ser&aacute; necesario que cada vez que se use EL SITIO,
        EL USUARIO tendr&aacute; que registrarse.
      </p>

      <strong>10. JURISDICCION Y LEGISLACION APLICABLE</strong>

      <p className="p3">
        El uso de EL SITIO y todo lo relacionado estar&aacute;n regulados bajo las leyes de los
        Estados Unidos Mexicanos. EL USUARIO se somete expresamente a la jurisdicci&oacute;n de los
        tribunales competentes de la ciudad de Monterrey, Nuevo Le&oacute;n, M&eacute;xico. En este
        sentido, el presente aviso legal se rige y cumple con todos y cada uno de los requisitos
        aplicables en los Estados Unidos Mexicanos.
      </p>

      <strong>11. TERMINOS ADICIONALES</strong>

      <p className="p3">
        EL USUARIO podrá ser sometido a un chequeo por parte del personal de seguridad,
        impidiéndosele la entrada a quien se niegue a recibirlo, no acarreando esto ninguna
        responsabilidad o consecuencia para el organizador. Queda prohibido el acceso a alimentos,
        bebidas, armas, drogas o cualquier otro artículo no autorizado a los eventos.
      </p>

      <p className="p3">
        LA EMPRESA no se hace responsable del clima y/o cancelación o suspensión del evento por
        causas de fuerza mayor. Incluyendo restricciones o cancelaciones por pandemia. En cualquiera
        de estos casos, LA EMPRESA no esta obligado a reembolsar el boleto. LA EMPRESA se reserva el
        derecho de negar la entrada o en su caso retirar del inmueble a cualquier persona cuya
        conducta sea considerada ofensiva, que induzca al desorden, y en general aquellas conductas
        que pudieran constituir una infracción o delito no estando obligado a reembolsar cantidad
        alguna. LA EMPRESA no se hace responsable por boletos no adquiridos en puntos de venta
        oficiales. El titular deberá portar el boleto durante su permanencia en el inmueble
        mostrándolo al personal autorizado cuando le sea requerido.
      </p>

      <p className="p3">AL NAVEGAR EN EL SITIO, EL USUARIO ESTA ACEPTANDO LOS TERMINOS DE USO.</p>

      <p className="p3">
        LA UTILIZACION DE EL SITIO Y DE LOS CONTENIDOS, ES BAJO LA RESPONSABILIDAD UNICA DE EL
        USUARIO.
      </p>

      <p className="p3">Derechos Reservados &copy; EVENTOS CROWDFRONT S.A. DE C.V.</p>
    </div>
  );
}
