import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import EventCard from '../../components/Landing/EventCard/index';
import Header from '../../components/Landing/Header';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import eventsService from '../../services/events.service';
import { withFirebase } from '../../components/firebase';
import {
  fas,
  faChevronLeft,
  faChevronCircleRight,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './index.scss';

import Footer from '../../components/footer';

import tdla from '../../assets/images/clients/tdla.png';
import rctalks from '../../assets/images/clients/rctalks.png';
import luztopia from '../../assets/images/clients/luztopia.png';
import lasilla from '../../assets/images/clients/lasilla.png';
import cm from '../../assets/images/clients/cm.png';
import cluster from '../../assets/images/clients/cluster.png';

import placeholder from '../../assets/images/placeholders/event.png';
library.add(fas, faChevronLeft, faChevronCircleRight);

function Landing(props) {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const [pageSize, setPageSize] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const getEvents = async (page, filters) => {
    setLoading(true);
    eventsService.all({ pageSize, currentPage: page, filters }).then(data => {
      const { events: eventsResult, page, totalPages } = data;
      setTotalPages(totalPages);
      setCurrentPage(page);
      setEvents(eventsResult);
      setLoading(false);
    });
  };

  useEffect(() => {
    getEvents();
  }, []);

  const nextPage = async () => {
    const pageNum = currentPage + 1;
    if (searchValue.length > 0) {
      await getEvents(pageNum, [`q[str_cont]=${searchValue}`]);
    } else {
      await getEvents(pageNum);
    }
  };

  const prevPage = async () => {
    const pageNum = currentPage - 1;
    if (searchValue.length > 0) {
      await getEvents(pageNum, [`q[str_cont]=${searchValue}`]);
    } else {
      await getEvents(pageNum);
    }

  };

  return (
    <>
      <Header
        landing={{
          getEvents,
          events,
          setEvents,
          loading,
          setLoading,
          searchValue,
          setSearchValue,
          searchIsOpen,
          setSearchIsOpen,
          pageSize,
          setPageSize,
          currentPage,
          setCurrentPage
        }}
      />
      <div className="container">
        <section className="section-header">
          <div className="events-pagination">
            <Button className="btn-light-gray" disabled={currentPage - 1 <= 0} onClick={prevPage}>
              <FontAwesomeIcon icon={faChevronLeft} size="lg" className="fb-icon" />
            </Button>
            <Button
              className="btn-light-gray"
              onClick={nextPage}
              disabled={currentPage >= totalPages}
            >
              <FontAwesomeIcon icon={faChevronRight} size="lg" className="fb-icon" />
            </Button>
          </div>
          <h5>Descubre...</h5>
        </section>

        <Row className="events-container">
          {!loading &&
            events.length > 0 &&
            events.map((event, key) => (
              <Col md="4" className="event-card-wrapper" key={key}>
                <EventCard event={event} />
              </Col>
            ))}

          {!loading && events.length === 0 && (
            <Col md="6">
              <em>No se encontraron eventos...</em>
            </Col>
          )}

          {loading && (
            <Col md="4" className="event-card-wrapper">
              <EventCard
                event={{
                  name: 'Cargando...',
                  Organization: { slug: '', name: '...', logo: placeholder },
                  headerImage: placeholder
                }}
              />
            </Col>
          )}
        </Row>

        <hr className="divider" />

        <section className="section-header">
          <h5 className="text-center">Nuestros Clientes</h5>
          <p className="subtitle">¡Trabajemos juntos en tu próximo evento!</p>
        </section>
        <Row className="landing-clients">
          <Col md="2" className="text-center">
            <a href="http://torneodelaamistad.com/" target="_blank">
              <img src={tdla} alt="Torneo de la amistad" />
            </a>
          </Col>
          <Col md="2" className="text-center">
            <a href="https://cielomagico.mx/" target="_blank">
              <img src={cm} alt="Cielo Mágico" width="80%" />
            </a>
          </Col>
          <Col md="2" className="text-center">
            <a href="http://www.clusterturismonl.com/" target="_blank">
              <img src={cluster} alt="Cluster de Turismo" />
            </a>
          </Col>
          <Col md="2" className="text-center">
            <a href="https://www.concursolasilla.com/" target="_blank">
              <img src={lasilla} alt="Concurso La Silla" />
            </a>
          </Col>
          <Col md="2" className="text-center">
            <a href="http://www.rctalks.org/" target="_blank">
              <img src={rctalks} alt="RC Talks" />
            </a>
          </Col>
          <Col md="2" className="text-center">
            <a href="https://luztopia.mx/" target="_blank">
              <img src={luztopia} alt="Luztopia" width="100%" />
            </a>
          </Col>
        </Row>
        <br />
        <br />
        <div className="text-center">
          <a href="mailto:hola@handypass.com" className="btn-contact">
            Contáctanos
          </a>
        </div>
        <br />
        <br />
        <Footer />
      </div>
    </>
  );
}

// export default Landing;
export default compose(
  withFirebase,
  withRouter
)(Landing);
