import { Component } from "react";
import { withFirebase } from "./context";
class Auth extends Component {
  state = {
    isLoading: true,
    error: null,
    auth: null,
  };
  componentDidMount() {
    this.unsubscribe = this.props.firebase.auth.onAuthStateChanged(
      this.handleAuth,
      this.handleError,
    );
  }
  componentWillMount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }
  handleAuth = (auth) => {
    this.setState({
      isLoading: false,
      auth,
      erro: null,
    });
  };
  handleError = (error) => {
    this.setState({
      isLoading: false,
      auth: null,
      error,
    });
  };
  render() {
    return this.props.children(this.state);
  }
}

export default withFirebase(Auth);
