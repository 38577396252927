import React from 'react';
// redux
import { addOrderProduct, deleteOrderProduct, addOrderId } from '../../redux/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import qs from 'query-string';
// reactstrap components
import { Row, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
// helpers
import Image from '../image';
import { centsToDecimal } from '../../helpers/currency';
import { compose } from 'recompose';
import { parsePayload } from '../../helpers/parsePayload';
import { parsePayloadUpdateOrderProducts } from '../../helpers/parsePayload';
import { withRouter } from 'react-router-dom';
import { updateOrderProducts, createOrderProducts } from '../../helpers/updateOrderProducts';
import { sendEventPixel } from '../../helpers/pixel';
import { isMobile } from 'react-device-detect';
// firebase
import { withFirebase } from '../../components/firebase';
import Donation from './donation';
// styles
import './styles.scss';
import { ConsoleView } from 'react-device-detect';
import CounterButtons from '../CounterButtons';
import Tooltip from '../Tooltip';
import moment from 'moment';
var showdown = require('showdown'),
  converter = new showdown.Converter();

class SelectExtraServices extends React.Component {
  state = {
    phasesAreEmpty: true,
    loading: true,
    modalVerifying: false,
    verifying: true,
    modalVerifyingMessage: 'Verificando disponibilidad',
    modal: false,
    productName: '',
    productDescription: '',
    rateLimit: false,
    messageRateLimit: '',
    products: [],
    orderExist: false,
    orderProducts: null,
    phases: [],
    ticketsObject: {}
  };
  toggleVerifyingAvailability = () => {
    this.setState(prevState => ({
      modalVerifying: !prevState.modalVerifying
    }));
  };

  verifyExistOrder = () => {
    return this.props.orderDetails[this.props.eventId] ? true : false;
  };
  // we wait for addTicket to finish updating state before we send data to endpoint
  ticketUpdate = async (name, quantity, phaseId) => {
    this.setState({ modalVerifyingMessage: 'Verificando disponibilidad' });
    try {
      await this.addTicket(name, quantity, phaseId);
      // we format the payload to send
      let payload;
      let response;
      //we verify is exist order,
      if (this.verifyExistOrder()) {
        //If exist we will update the order
        payload = parsePayloadUpdateOrderProducts(
          this.props.orderProducts,
          this.props.eventId,
          this.props.organizationId,
          'ONLINE',
          '',
          this.props.orderDetails[this.props.eventId].orderId
        );
        const {
          data: { status, rateLimit }
        } = await axios.post(
          `${process.env.REACT_APP_FUNCTIONS_URL}/cart/verify_rate_limit`,
          payload
        );
        if (status) {
          response = await updateOrderProducts(payload);
        } else {
          throw {
            response: {
              data: {
                error: 'RATE_LIMIT',
                message: `El monto máximo por transacción es de $${centsToDecimal(
                  rateLimit
                ).toFixed(
                  2
                )} MXN , por favor reduzca el número de articulos en el carrito e intente de nuevo. Si necesita realizar una compra mayor al limite de trasacción le recomendamos hacer multiples compras. `
              }
            }
          };
        }
      } else {
        //if not existe, we will create a new order
        payload = parsePayloadUpdateOrderProducts(
          this.props.orderProducts,
          this.props.eventId,
          this.props.organizationId,
          'ONLINE',
          ''
        );
        const {
          data: { status, rateLimit }
        } = await axios.post(
          `${process.env.REACT_APP_FUNCTIONS_URL}/cart/verify_rate_limit`,
          payload
        );

        if (status) {
          response = await createOrderProducts(payload);
          this.props.addOrderId({
            eventId: this.props.eventId,
            orderId: response.data.orderId
          });
        } else {
          throw {
            response: {
              data: {
                error: 'RATE_LIMIT',
                message: `El monto máximo por transacción es de $${centsToDecimal(
                  rateLimit
                ).toFixed(
                  2
                )} MXN , por favor reduzca el número de articulos en el carrito e intente de nuevo. Si necesita realizar una compra mayor al limite de trasacción le recomendamos hacer multiples compras. `
              }
            }
          };
        }
      }
      response
        ? this.toggleVerifyingAvailability()
        : this.setState({
            modalVerifyingMessage: 'no hay disponibilidad en este momento'
          });
    } catch (error) {
      const {
        response: { data }
      } = error;
      if (data.error_code && data.error_code === 'AVAILABILITY_EXCEEDED') {
        this.setState({
          messageRateLimit:
            'No hay suficientes boletos disponibles, por favor reduzca la cantidad de boletos e intente de nuevo'
        });
        this.toggleVerifyingAvailability();
        this.handleHideAlertRateLimit();
        return;
      }
      if (data.error === 'RATE_LIMIT') {
        this.setState({ messageRateLimit: data.message });
        this.toggleVerifyingAvailability();
        this.handleHideAlertRateLimit();
        return;
      }
      this.setState({
        modalVerifyingMessage: data.error
      });
    }
  };

  handleHideAlertRateLimit = () => {
    this.setState(prevState => ({
      rateLimit: !prevState.rateLimit
    }));
  };

  toggleModal = (name, description) => {
    this.setState(prevState => ({
      modal: !prevState.modal,
      productName: name,
      productDescription: description
    }));
  };

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  };

  addTicket = (name, quantity, productId) => {
    // activate verifying modal
    this.toggleVerifyingAvailability();
    // wait for addOrderProduct to finish, then we can update endpoint on ticketUpdate
    return new Promise((resolve, reject) => {
      this.props.addOrderProduct({
        name,
        quantity,
        productId
      });
      // this function will clear tickets from cart if they are equal to 0
      //this.checkIfOrderProductShouldBeDeleted(name, quantity, productId);
      resolve(true);
    });
  };
  handleSubmitDonation = async ({ target }) => {
    const orderId = this.props.orderDetails[this.props.eventId]
      ? this.props.orderDetails[this.props.eventId].orderId
      : '';
    try {
      const payload = {
        cart: {
          orgId: this.props.organizationId,
          eventId: this.props.eventId,
          orderType: 'ONLINE',
          userId: '',
          donation: parseInt(target.value),
          orderId
        }
      };
      const response = await axios.post(`${process.env.REACT_APP_FUNCTIONS_URL}/cart`, payload);
      sendEventPixel('Donate');
      if (orderId === '') {
        this.props.addOrderId({
          eventId: this.props.eventId,
          orderId: response.data.orderId
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  handleGetOrder = async (organizationId, eventId) => {
    const orderDetails = this.props.orderDetails[this.props.eventId];
    if (orderDetails) {
      const { orderId } = orderDetails;
      const orderProductsCollection = await this.props.firebase.getOrderProducts(
        organizationId,
        eventId,
        orderId
      );
      orderProductsCollection.onSnapshot(querySnapshot => {
        let orderProducts = {};
        querySnapshot.forEach(orderProduct => {
          const { productId, quantity } = orderProduct.data();
          orderProducts[productId] = quantity;
        });
        this.setState({ orderProducts, orderExist: true });
      });

      const orderTickets = await this.props.firebase.getOrderTickets(
        organizationId,
        eventId,
        orderId
      );
      orderTickets.onSnapshot(querySnapshot => {
        const object = {};
        querySnapshot.forEach(function(orderTicket) {
          const { name, quantity, phaseId } = orderTicket.data();
          object[phaseId] = object[phaseId] || {};
          object[phaseId] = { name, quantity, phaseId };
        });
        this.setState({ ticketsObject: object });
      });
    }
  };
  handleRedirect = () => {
    if (!this.state.loading && !this.props.donations && this.emptyItems()) {
      this.props.history.replace(
        `/${this.props.organizationSlug}/${this.props.eventSlug}/create-account`
      );
    }
  };

  emptyItems = () => {
    return this.state.products.length === 0 && this.state.phases.length === 0;
  };
  createOrUpdatePendingOrder = async () => {
    this.setState({ modalVerifyingMessage: 'Verificando disponibilidad' });
    this.toggleVerifyingAvailability();
    const { orderDetails, eventId, organizationId } = this.props;
    let LogRocketSessionUrl = '';
    const orderId =
      typeof orderDetails[eventId] !== 'undefined' ? orderDetails[eventId].orderId : false;
    // prettier-ignore
    const payload = parsePayload(
      this.state.ticketsObject,
      eventId,
      organizationId,
      'ONLINE',
      '',
      orderId,
      LogRocketSessionUrl
    );
    const { search } = this.props.history.location;
    if (orderId === false && search.length > 0) {
      const { referral } = qs.parse(search);
      payload.cart.referral = referral;
    }
    try {
      const {
        data: { status, rateLimit }
      } = await axios.post(
        `${process.env.REACT_APP_FUNCTIONS_URL}/cart/verify_rate_limit`,
        payload
      );
      if (status) {
        const response = await axios.post(`${process.env.REACT_APP_FUNCTIONS_URL}/cart`, payload);
        this.props.addOrderId({
          eventId: eventId,
          orderId: response.data.orderId
        });
        this.toggleVerifyingAvailability();
      } else {
        throw {
          response: {
            data: {
              error: 'RATE_LIMIT',
              message: `El monto máximo por transacción es de $${centsToDecimal(rateLimit).toFixed(
                2
              )} MXN , por favor reduzca el número de articulos en el carrito e intente de nuevo. Si necesita realizar una compra mayor al limite de trasacción le recomendamos hacer multiples compras. `
            }
          }
        };
      }
    } catch (error) {
      const {
        response: { data }
      } = error;
      if (data.error_code && data.error_code === 'AVAILABILITY_EXCEEDED') {
        this.setState({
          messageRateLimit:
            'No hay suficientes boletos disponibles, por favor reduzca la cantidad de boletos e intente de nuevo'
        });
        this.toggleVerifyingAvailability();
        this.handleHideAlertRateLimit();
        return;
      }
      if (data.error === 'RATE_LIMIT') {
        this.setState({ messageRateLimit: data.message });
        this.toggleVerifyingAvailability();
        this.handleHideAlertRateLimit();
        return;
      }
      this.setState({
        modalVerifyingMessage: data.error
      });
    }
  };

  async componentDidMount() {
    const { organizationId, eventId } = this.props;
    this.handleGetOrder(organizationId, eventId);

    const productsCollectionRef = await this.props.firebase.getProductsCollection(
      organizationId,
      eventId
    );
    const productsRef = await productsCollectionRef.get();
    const productsCollection = productsRef.docs.map(p => ({ id: p.id, ...p.data() }));
    const _products = productsCollection.filter(product => this.availableProduct(product));
    const phasesCollectionRef = await this.props.firebase.getPhasesCollection(
      organizationId,
      eventId
    );
    const phasesRef = await phasesCollectionRef.get();
    const phasesCollection = phasesRef.docs.map(_phase => ({ id: _phase.id, ..._phase.data() }));
    const _phases = phasesCollection
      .filter(_phase => this.validPhases(_phase))
      .filter(_phase => _phase.extraService)
      .sort((a, b) => a.sortOrder - b.sortOrder);
    this.setState({ loading: false, phases: _phases, products: _products });
  }

  availableProduct = product => {
    return product.status === 'ACTIVE' && product.soldCount + product.onHold < product.quantity;
  };

  availablePhase = phase => {
    const now = new Date();
    return (
      phase.soldCount + phase.onHold <= phase.quantity &&
      phase.status === 'ACTIVE' &&
      now < phase.endsAt.toDate()
    );
  };
  componentDidUpdate(prevProps) {
    if (this.props.orderDetails[this.props.eventId] !== prevProps.orderDetails[prevProps.eventId]) {
      const { organizationId, eventId } = this.props;
      this.handleGetOrder(organizationId, eventId);
    }
  }
  componentWillMount() {
    this.timer = null;
  }

  validPhases = phase => {
    return !phase.boxOffice && this.availablePhase(phase) && this.isBetween(phase);
  };

  isBetween = phase => {
    return moment(this.props.selectedDate).isBetween(
      phase.startsAt.toDate(),
      phase.endsAt.toDate(),
      'minutes',
      '[]'
    );
  };

  addTicketPhase = phase => {
    clearInterval(this.timer);
    const newTicketObject = Object.assign({}, this.state.ticketsObject);
    newTicketObject[phase.id] = newTicketObject[phase.id] || {
      name: phase.name,
      quantity: 0,
      phaseId: phase.id
    };
    newTicketObject[phase.id].quantity += 1;
    this.setState({ ticketsObject: newTicketObject });
    this.timer = setTimeout(this.createOrUpdatePendingOrder, 1000);
  };

  removeTicketPhase = phase => {
    clearInterval(this.timer);
    const newTicketObject = Object.assign({}, this.state.ticketsObject);
    newTicketObject[phase.id] = newTicketObject[phase.id] || {
      name: phase.name,
      quantity: 0,
      phaseId: phase.id
    };

    if (newTicketObject[phase.id].quantity > 0) {
      newTicketObject[phase.id].quantity -= 1;
    }
    this.setState({ ticketsObject: newTicketObject });
    this.timer = setTimeout(this.createOrUpdatePendingOrder, 1000);
  };

  titleText = () => {
    if (this.props.organizationSlug === 'luztopia') {
      return 'La pulsera es un complemento opcional (Cupo Limitado)';
    }
    return 'Complementa tu visita (Cupo Limitado)';
  };

  render() {
    this.handleRedirect();
    return (
      <>
        {this.state.modalVerifying && (
          <SweetAlert
            title={this.state.modalVerifyingMessage}
            onConfirm={() => {}}
            showConfirm={false}
          >
            {this.state.verifying && (
              <FontAwesomeIcon icon={faSpinner} className="fa-spin fa-5x svg-center" />
            )}
          </SweetAlert>
        )}
        {this.state.rateLimit && (
          <SweetAlert
            error
            title="Tu orden supera el limite"
            onConfirm={this.handleHideAlertRateLimit}
          >
            {this.state.messageRateLimit}
          </SweetAlert>
        )}
        {this.state.warningOrder && (
          <SweetAlert warning title="" onConfirm={() => this.setState({ warningOrder: false })}>
            Para ingresar al evento, es imprescindible contar con un boleto de entrada general.
          </SweetAlert>
        )}
        <br />
        <h3 className="text-xs-center">{this.titleText()}</h3>
        {this.props.donations && (
          <Donation onSubmit={this.handleSubmitDonation} handleModal={this.toggleModal} />
        )}
        {this.state.loading
          ? 'loading'
          : this.state.products
              .sort((a, b) => a.sortOrder - b.sortOrder)
              .map(product => (
                <Row className="extra-service" key={product.id}>
                  <Col lg="2 flex" md="2" sm="3" xs="3" className="p-0">
                    <Image imgUrl={product.file} theme={{ width: '80px', height: '80px' }} />
                  </Col>
                  <Col lg="6" md="6" sm="6" xs="6" className="d-none d-sm-block">
                    <h4>{product.name}</h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: converter.makeHtml(product.description)
                      }}
                    />
                  </Col>
                  <Col lg="2 flex" md="2" sm="7" xs="7">
                    <h4 className="price">
                      ${centsToDecimal(product.price + product.fee).toFixed(2)}
                    </h4>
                  </Col>
                  <Col lg="2 flex" md="2" sm="2" xs="2">
                    {product.status === 'COMING_SOON' ? (
                      <h4 className="coming-soon">Próximamente</h4>
                    ) : (
                      <select
                        name={product.name}
                        value={
                          this.state.orderProducts && this.state.orderProducts[product.id]
                            ? this.state.orderProducts[product.id]
                            : ''
                        }
                        onChange={e => this.ticketUpdate(product.name, e.target.value, product.id)}
                      >
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                      </select>
                    )}
                  </Col>
                  <Col sm="12" xs="12" className="d-block d-sm-none">
                    <h4 className="product-name">
                      {product.name}:{' '}
                      <span className="description-small">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: converter.makeHtml(product.description)
                          }}
                        />
                      </span>
                    </h4>
                  </Col>
                </Row>
              ))}
        {this.state.phases.map((phase, i) => (
          <Row className="extra-service" key={i}>
            <Col lg="8" md="8" sm="6" xs="6">
              <h4>{phase.name}</h4>
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: converter.makeHtml(phase.description)
                }}
              />
            </Col>
            <Col lg="2 flex" md="2" sm="3" xs="3" className="mw-22">
              <h4>${centsToDecimal(phase.price)}</h4>
              {'  '}
              <Tooltip {...phase} />
            </Col>
            <Col lg="2 flex" md="2" sm="3" xs="3">
              {phase.status === 'COMING_SOON' ? (
                isMobile ? (
                  <h4 className="proximamente">Próx..</h4>
                ) : (
                  <h4 className="proximamente">Próximamente</h4>
                )
              ) : phase.soldCount + phase.onHold >= phase.quantity ? (
                <h4 className="proximamente">SOLD OUT</h4>
              ) : (
                // prettier-ignore
                <CounterButtons
                    item={phase}
                    add={this.addTicketPhase}
                    remove={this.removeTicketPhase}
                    count={
                      (this.state.ticketsObject[phase.id] &&
                        this.state.ticketsObject[phase.id].quantity) || 0
                    }
                  />
              )}
            </Col>
          </Row>
        ))}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>{this.state.productName}</ModalHeader>
          <ModalBody>
            <div
              dangerouslySetInnerHTML={{
                __html: converter.makeHtml(this.state.productDescription)
              }}
            />
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default compose(
  withRouter,
  withFirebase,
  connect(
    state => ({
      orderProducts: state.orderProducts,
      orderDetails: state.orderDetails,
      recaptchaValid: state.recaptcha.recaptchaValid
    }),
    dispatch => bindActionCreators({ addOrderProduct, deleteOrderProduct, addOrderId }, dispatch)
  )
)(SelectExtraServices);
