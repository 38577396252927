import React, { Component } from 'react';
import { timerPromise, convertToSeconds } from '../../../helpers/timer';
import moment from 'moment';
// helpers
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { expireOrder, resetOrderId } from '../../../redux/actions';

class Timer extends Component {
  state = {
    status: true,
    timeToExpireOrder: ''
  };

  componentDidUpdate(prevProps) {
    if (this.props.order !== prevProps.order) {
      if (this.orderStatusInvalid()) {
        this.handleRunTimer(12);
      } else {
        this.setState({ status: false });
        clearInterval(this.timer);
      }
    }
  }
  componentWillMount() {
    this.timer = null;
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
    clearInterval(this.timer);
  }
  //timeExpiration is a variable for the minutes, only need a number, for example:
  // 1 equal a one minute or
  // 2 equal a two minutes.
  handleRunTimer = async timeExpiration => {
    let createdAtSeconds = this.props.order.createdAt.seconds;
    const timeToExpireOrder = moment(createdAtSeconds * 1000)
      .add(timeExpiration, 'minutes')
      .unix();
    const valueTimeStamp = moment().diff(
      moment(createdAtSeconds * 1000).add(timeExpiration, 'minutes')
    );
    const duration = moment.duration(valueTimeStamp);
    this.handleTimeToExpireOrder(duration);
    await timerPromise(timeToExpireOrder, duration);
    if (this.mounted) {
      this.props.resetOrderId({});
      localStorage.removeItem('selectedDate')
      window.location.replace('/');
    }
  };

  handleTimeToExpireOrder = duration => {
    this.setState({ timeToExpireOrder: Math.abs(duration.asMilliseconds()) });
    clearInterval(this.timer);
    if (this.state.status) {
      this.timer = setInterval(() => {
        if (this.state.status) {
          this.setState(prevState => {
            return {
              timeToExpireOrder: prevState.timeToExpireOrder - 1000
            };
          });
        }
      }, 1000);
    }
  };

  orderStatusInvalid = () => {
    const validStatus = ['PAID', 'PENDING_PAYMENT'].includes(this.props.order.orderStatus);
    return !validStatus;
  };
  render() {
    return (
      this.state.status &&
      this.props.order &&
      this.orderStatusInvalid() && (
        <div className="alert alert-info">
          {this.props.order
            ? `Tienes ${convertToSeconds(this.state.timeToExpireOrder)}
        para terminar con la reservación, de lo contrario tus boletos serán
        liberados.`
            : 'Cuando realices tu orden, tendrás 8 minutos para completarla'}
        </div>
      )
    );
  }
}

export default compose(
  withRouter,
  connect(
    state => ({ event: state.event }),
    dispatch => bindActionCreators({ expireOrder, resetOrderId }, dispatch)
  )
)(Timer);
