import { cartConstants } from "../constants";

export function handleAcceptedTerms() {
  return { type: "HANDLE_ACCEPTED_TERMS" };
}

export function addTicket(data) {
  return { type: "ADD_TICKET", payload: data };
}

export function deleteTicket(data) {
  return { type: "DELETE_TICKET", payload: data };
}

export function addOrderProduct(data) {
  return { type: "ADD_ORDER_PRODUCT", payload: data };
}

export function deleteOrderProduct(data) {
  return { type: "DELETE_ORDER_PRODUCT", payload: data };
}

export function addOrderId(data) {
  return { type: cartConstants.ADD_ORDER_ID, payload: data };
}

export function removeOrderId(data) {
  return { type: "REMOVE_ORDER_ID", payload: data };
}

export function expireOrder(data) {
  return { type: "EXPIRE_ORDER", payload: data };
}
