import React from 'react';
// reactstrap components
import { Row, Col } from 'reactstrap';
import donationLogo from '../../assets/images/donacion.png';
// helpers
import Image from '../image';
// styles
import './styles.scss';

var showdown = require('showdown'),
  converter = new showdown.Converter();

const description =
  'A través de **COMUNIDADES MÁGICAS**, nuestra asociación civil, trascendemos en nuestro entorno con los siguientes proyectos y asociaciones: Centro Comunitario La Cieneguilla, en colaboración con DIF Santiago, Yo Respiro Mty / Comité Ecológico Interescolar y Tierra Prima, campaña **#RevoluciónRefill.** Súmate, une tus sueños a nuestra inspiración, ¡y volemos juntos!';
export default class donation extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <Row className="extra-service">
        <Col lg="2 flex" md="2" sm="3" xs="3" className="p-0">
          <Image
            imgUrl={donationLogo}
            theme={{ width: '80px', height: '80px', borderRadius: '10px' }}
          />
        </Col>
        <Col lg="6" md="6" sm="6" xs="6" className="d-none d-sm-block">
          <h4>Donación</h4>
          <div
            dangerouslySetInnerHTML={{
              __html: converter.makeHtml(description.substring(0, 100).concat(' ...'))
            }}
          />
          <p className="orange" onClick={() => this.props.handleModal('Donación', description)}>
            Ver Detalles
          </p>
        </Col>
        <Col lg="2 flex" md="2" sm="7" xs="7" />
        <Col lg="2 flex" md="2" sm="2" xs="2">
          <select name={'donation'} onChange={this.props.onSubmit}>
            <option value="000">$0</option>
            <option value="500">$5</option>
            <option value="1000">$10</option>
            <option value="2000">$20</option>
            <option value="5000">$50</option>
          </select>
        </Col>
        <Col sm="12" xs="12" className="d-block d-sm-none">
          <h4 className="product-name">
            Donación
            <span className="description-small">
              <div
                dangerouslySetInnerHTML={{
                  __html: converter.makeHtml(description.substring(0, 100).concat(' ...'))
                }}
              />
              <p className="orange" onClick={() => this.props.handleModal('Donación', description)}>
                Ver Detalles
              </p>
            </span>
          </h4>
        </Col>
      </Row>
    );
  }
}
