import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firebase-firestore';
import 'firebase/storage';
import { environment } from './config';

class Firebase {
  constructor() {
    app.initializeApp(environment.firebase);
    this.db = app.firestore();
    this.auth = app.auth();
    this.storage = app.storage();
    // this.googleProvider = new app.auth.GoogleAuthProvider();
    // this.facebookProvider = new app.auth.FacebookAuthProvider();
  }
  // // auth
  // doSignInWithEmailAndPassword = (email, password) =>
  //   this.auth.signInWithEmailAndPassword(email, password);

  // doSignInAnonymously = async (firstName, lastName, email, phone) => {
  //   try {
  //     const doc = await this.db
  //       .collection('users')
  //       .where('email', '==', email)
  //       .get();
  //     if (doc.empty) {
  //       const data = await this.auth.signInAnonymously();
  //       const { uid } = data.user;
  //       const date = new Date();
  //       const user = {
  //         name: { firstName, lastName },
  //         email,
  //         phone,
  //         isAdmin: false,
  //         isGuest: true,
  //         createdAt: date,
  //         updatedAt: date
  //       };
  //       this.db
  //         .collection('users')
  //         .doc(uid)
  //         .set(user);
  //       return uid;
  //     } else if (doc.docs[0].data() && doc.docs[0].data().isGuest) {
  //       await this.auth.signInAnonymously();
  //       const uid = doc.docs[0].id;
  //       return uid;
  //     } else {
  //       return null;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  doSignOut = () => this.auth.signOut();
  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
  // doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);
  // doCreateAccount = async (email, password, firstName, lastName, phone) => {
  //   try {
  //     const data = await this.auth.createUserWithEmailAndPassword(email, password);
  //     const { uid } = data.user;
  //     const date = new Date();
  //     const user = {
  //       name: { firstName, lastName },
  //       email,
  //       phone,
  //       isAdmin: false,
  //       createdAt: date,
  //       updatedAt: date
  //     };
  //     this.db
  //       .collection('users')
  //       .doc(uid)
  //       .set(user);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // auth = () => this.auth;
  // currentUser = () => {
  //   return this.auth.currentUser;
  // };
  userInfo = async uid => {
    return await this.db
      .collection('users')
      .doc(uid)
      .get();
  };
  //check if user already exist
  checkIfUserExists = async uid => {
    if (!uid) {
      this.doSignOut();
      return false;
    }

    try {
      const doc = await this.db
        .collection('users')
        .doc(uid)
        .get();
      return doc.exists;
    } catch (error) {
      console.log(error);
    }
  };
  // google auth
  // doSignInWithGoogle = async () => {
  //   // try/catch is managed from component, so we can react to errors properly
  //   const data = await this.auth.signInWithPopup(this.googleProvider);
  //   const { uid, email } = data.user;
  //   const exist = await this.checkIfUserExists(uid);
  //   // only if user not already exist, create new user
  //   if (!exist) {
  //     const date = new Date();
  //     const user = {
  //       name: { firstName: '', lastName: '' },
  //       email,
  //       phone: '',
  //       isAdmin: false,
  //       createdAt: date,
  //       updatedAt: date
  //     };
  //     this.db
  //       .collection('users')
  //       .doc(uid)
  //       .set(user);
  //   }
  // };
  // facebook auth
  // doSignInWithFacebook = async () => {
  //   // try/catch is managed from component, so we can react to errors properly
  //   const data = await this.auth.signInWithPopup(this.facebookProvider);
  //   const { uid, email } = data.user;
  //   const exist = await this.checkIfUserExists(uid);
  //   // only if user not already exist, create new user
  //   if (!exist) {
  //     const date = new Date();
  //     const user = {
  //       name: { firstName: '', lastName: '' },
  //       email,
  //       phone: '',
  //       isAdmin: false,
  //       createdAt: date,
  //       updatedAt: date
  //     };
  //     this.db
  //       .collection('users')
  //       .doc(uid)
  //       .set(user);
  //   }
  // };
  // get current user from users collection
  currentUserCredentials = async uid => {
    const exist = await this.checkIfUserExists(uid);
    if (exist) {
      const user = await this.db
        .collection('users')
        .doc(uid)
        .get();

      return user.data();
    } else {
      return {
        name: { firstName: '', lastName: '' },
        phone: '',
        email: '',
        emailVerified: '',
        isAnonymous: '',
        uid
      };
    }
  };
  // update order with user id
  updateOrderWithUserId = async (orgId, eventId, orderId, uid) =>
    await this.db
      .collection(`organizations/${orgId}/events/${eventId}/orders`)
      .doc(orderId)
      .update({ userId: uid });

  // organizations
  getOrganization = async slug =>
    await this.db
      .collection('organizations')
      .where('slug', '==', slug)
      .get();

  getOrganizationById = async orgId =>
    await this.db
      .collection('organizations')
      .doc(orgId)
      .get();

  // image management
  getImgUrl = async ref => {
    await this.storage.ref(ref).getDownloadURL();
  };

  // events
  getEventsCollection = async organizationId =>
    await this.db
      .collection(`organizations/${organizationId}/events`)
      .where('eventStatus', '==', 'ACTIVE')
      .get();

  getEventBySlug = async slug =>
    await this.db
      .collectionGroup('events')
      .where('slug', '==', slug)
      .get();
  eventDoc = async (orgId, eventId) => {
    return this.db
      .collection('organizations')
      .doc(orgId)
      .collection('events')
      .doc(eventId);
  };

  // user for realtime
  getPhasesCollection = async (organizationId, eventId) => {
    const now = new Date();
    return await this.db
      .collection(`/organizations/${organizationId}/events/${eventId}/phases/`)
      .orderBy('sortOrder', 'asc');
  };
  // used for static collection grabbing
  getPhasesCollectionStatic = async (organizationId, eventId) =>
    await this.db.collection(`/organizations/${organizationId}/events/${eventId}/phases/`).get();

  getPhaseData = async (organizationId, eventId, phaseId) => {
    return await this.db
      .collection('organizations')
      .doc(organizationId)
      .collection('events')
      .doc(eventId)
      .collection('phases')
      .doc(phaseId)
      .get();
  };

  getProductsCollection = async (organizationId, eventId) =>
    this.db
      .collection(`/organizations/${organizationId}/events/${eventId}/products/`)
      .orderBy('sortOrder', 'asc');

  getOrderCourtesies = async (organizationId, eventId, orderId) => {
    return await this.db
      .collection('organizations')
      .doc(organizationId)
      .collection('events')
      .doc(eventId)
      .collection('orders')
      .doc(orderId)
      .collection('order_courtesies')
      .get();
  };
  // virtual cart
  getOrderTickets = async (organizationId, eventId, orderId) =>
    await this.db.collection(
      `/organizations/${organizationId}/events/${eventId}/orders/${orderId}/order_tickets`
    );

  getOrderTicket = async (organizationId, eventId, orderId, orderTicketId) =>
    this.db
      .collection(
        `/organizations/${organizationId}/events/${eventId}/orders/${orderId}/order_tickets`
      )
      .doc(orderTicketId);
  getOrderTicketsStatic = async (organizationId, eventId, orderId) =>
    await this.db
      .collection(
        `/organizations/${organizationId}/events/${eventId}/orders/${orderId}/order_tickets`
      )
      .get();

  getOrderProducts = async (organizationId, eventId, orderId) =>
    await this.db.collection(
      `/organizations/${organizationId}/events/${eventId}/orders/${orderId}/order_products`
    );
  getOrderProductsCollectionStatic = async (organizationId, eventId, orderId) =>
    await this.db
      .collection(
        `/organizations/${organizationId}/events/${eventId}/orders/${orderId}/order_products`
      )
      .get();

  getOrderCourtesiesCollection = async (organizationId, eventId, orderId) => {
    return await this.db
      .collection('organizations')
      .doc(organizationId)
      .collection('events')
      .doc(eventId)
      .collection('orders')
      .doc(orderId)
      .collection('order_courtesies')
      .get();
  };
  getOrderCourtesy = async (organizationId, eventId, orderId, courtesyId) => {
    return await this.db
      .collection('organizations')
      .doc(organizationId)
      .collection('events')
      .doc(eventId)
      .collection('orders')
      .doc(orderId)
      .collection('order_courtesies')
      .doc(courtesyId)
      .collection('courtesy_tickets')
      .get();
  };

  getOrder = async (organizationId, eventId, orderId) => {
    const order = await this.db
      .collection('organizations')
      .doc(organizationId)
      .collection('events')
      .doc(eventId)
      .collection('orders')
      .doc(orderId);

    return order;
  };
  getPurchaseProducts = async (organizationId, eventId, orderId, orderProductsId) => {
    return await this.db
      .collection('organizations')
      .doc(organizationId)
      .collection('events')
      .doc(eventId)
      .collection('orders')
      .doc(orderId)
      .collection('order_products')
      .doc(orderProductsId)
      .collection('purchased_products')
      .get();
  };
  getPurchaseTickets = async (organizationId, eventId, orderId, orderTicketsId) => {
    return await this.db
      .collection('organizations')
      .doc(organizationId)
      .collection('events')
      .doc(eventId)
      .collection('orders')
      .doc(orderId)
      .collection('order_tickets')
      .doc(orderTicketsId)
      .collection('tickets')
      .get();
  };
  getOrderStatic = async (organizationId, eventId, orderId) => {
    const order = await this.db
      .collection('organizations')
      .doc(organizationId)
      .collection('events')
      .doc(eventId)
      .collection('orders')
      .doc(orderId)
      .get();

    return order;
  };

  orderDoc = (organizationId, eventId, orderId) => {
    return this.db
      .collection('organizations')
      .doc(organizationId)
      .collection('events')
      .doc(eventId)
      .collection('orders')
      .doc(orderId);
  };

  getForms = async (organizationId, formId) => {
    return this.db
      .collection('organizations')
      .doc(organizationId)
      .collection('forms')
      .doc(formId)
      .get();
  };

  saveAnswers = async (organizationId, eventId, orderId, answers) => {
    const batch = this.db.batch();
    const ref = this.db
      .collection('organizations')
      .doc(organizationId)
      .collection('events')
      .doc(eventId)
      .collection('orders')
      .doc(orderId)
      .collection('order_tickets');

    for (const orderTicketId in answers) {
      Object.values(answers[orderTicketId].form_answers).forEach(item => {
        const id = item.id;
        delete item.id;
        if (id) {
          batch.update(
            ref
              .doc(orderTicketId)
              .collection('form_answers')
              .doc(id),
            item
          );
        } else {
          batch.set(
            ref
              .doc(orderTicketId)
              .collection('form_answers')
              .doc(),
            item
          );
        }
      });
    }
    return await batch.commit();
  };

  orderTicketsCollection = (organizationId, eventId, orderId) =>
    this.db
      .collection('organizations')
      .doc(organizationId)
      .collection('events')
      .doc(eventId)
      .collection('orders')
      .doc(orderId)
      .collection('order_tickets');

  getFormAnswers = async (organizationId, eventId, orderId, orderTicketId) =>
    await this.db
      .collection('organizations')
      .doc(organizationId)
      .collection('events')
      .doc(eventId)
      .collection('orders')
      .doc(orderId)
      .collection('order_tickets')
      .doc(orderTicketId)
      .collection('form_answers')
      .orderBy('sortOrder', 'asc')
      .get();

  paymentsCollection = (organizationId, eventId, orderId) => {
    return this.db
      .collection('organizations')
      .doc(organizationId)
      .collection('events')
      .doc(eventId)
      .collection('orders')
      .doc(orderId)
      .collection('payments');
  };

  activeEventsCollectionGroup = (lastId = "") =>
    this.db
      .collectionGroup('events')
      .where('eventStatus', '==', 'ACTIVE')
      .orderBy('startsAt', 'asc')
      .orderBy('name', 'asc')
      .orderBy('id', 'asc')
      .startAfter(lastId);

  sliderItemsCollection = () => this.db.collection("slider_items");
}

export default Firebase;
