import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import './button.scss';

export default function Button({ id, isLoading, onClick, children, color, disabled }) {
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (isLoading) {
      setShowLoader(true);
    }

    if (!isLoading && showLoader) {
      setTimeout(() => {
        setShowLoader(false);
      }, 500);
    }
  }, [isLoading, showLoader]);

  const fadeOutProps = useSpring({ opacity: showLoader ? 1 : 0 });
  const fadeInProps = useSpring({ opacity: showLoader ? 0 : 1 });

  const handleOnClick = async e => {
    if (!disabled) {
      if (!showLoader) {
        if (onClick) {
          onClick(e);
        }
      }
    }
  };

  return (
    <button
      id={id}
      disabled={disabled}
      className={`Button ${showLoader && 'isLoading'} ${color && 'color'} ${disabled &&
        'disabled'}`}
      type="button"
      onClick={handleOnClick}
    >
      {showLoader ? (
        <animated.div style={fadeOutProps}>
          <div className="loader" />
        </animated.div>
      ) : (
        <animated.div style={fadeInProps}>{children}</animated.div>
      )}
    </button>
  );
}
