import React from "react";

import { Row, Col } from "reactstrap";
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "../image";
import "./styles.scss";

library.add(fas, faFacebook, faTwitterSquare);

// // Kicks off the process of finding <i> tags and replacing with <svg>
dom.watch();

class OrganizationHeader extends React.Component {
  render() {
    const { name, logo, facebookLink, twitterLink } = this.props.organization;
    return (
      <Row className="organization-header">
        <Col lg="9" md="9" sm="12">
          <Image imgUrl={logo} theme={{ width: "50px", height: "50px" }} />
          <h3 className="organization-header__title">{name}</h3>
        </Col>
        <Col lg="3" md="3" className="text-right social-sharing">
          <small>Compartir:</small>
          <a
            href={`https://${facebookLink}`}
            target="_blank"
            rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} size="lg" className="fb-icon" />
          </a>
          <a
            href={`https://${twitterLink}`}
            target="_blank"
            rel="noopener noreferrer">
            <FontAwesomeIcon
              icon={faTwitterSquare}
              size="lg"
              className="tw-icon"
            />
          </a>
        </Col>
      </Row>
    );
  }
}

// export default connect(
//   (state) => ({
//     orgname: state.org.name,
//     organizationLogo: state.org.logo,
//     facebookLink: state.org.facebookLink,
//     twitterLink: state.org.twitterLink
//   }),
//   (dispatch) => bindActionCreators({}, dispatch)
// )(OrganizationHeader);
export default OrganizationHeader;
