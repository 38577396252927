import React from "react";

export default function CounterButtons(props) {
  const { item, remove, add, count, small } = props;
  return (
    <div className="counter">
      <span>{count}</span>
      <div className="buttons">
        <button className={small ? "small" : ""} onClick={() => add(item)}>
          +
        </button>
        <button className={small ? "small" : ""} onClick={() => remove(item)}>
          -
        </button>
      </div>
    </div>
  );
}
