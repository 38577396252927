import { userConstants } from "../constants";
export function setUserData(data) {
  return {
    type: "SET_GUEST_USER",
    payload: data,
  };
}

export function userSignedIn(data) {
  return {
    type: userConstants.USER_SIGNED_IN,
    payload: data,
  };
}

export function userSignedOut() {
  return {
    type: userConstants.USER_SIGNED_OUT,
  };
}
