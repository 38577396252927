export function orderProducts(state = {}, action) {
  switch (action.type) {
    case "ADD_ORDER_PRODUCT":
      return {
        ...{ [action.payload.name]: action.payload },
      };
    default:
      return state;
  }
}
