import React, { useState, useEffect } from 'react';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators } from 'reactstrap';
import { withFirebase } from "../../../components/firebase";
import ProperLink from '../../ProperLink';

const FeaturedSlider = props => {
  const { firebase, landing } = props;
  const { searchIsOpen } = landing;
  const [sliderItems, setSliderItems] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const getSliderItems = async () => {
    const sliderItemsCollection = await firebase
      .sliderItemsCollection()
      .orderBy("sortOrder", "asc")
      .get()

    const items = sliderItemsCollection.docs.map( doc => ({ id: doc.id, ...doc.data() }));

    setSliderItems(items)
  }


  useEffect(() => {
    getSliderItems();
  }, []);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === sliderItems.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? sliderItems.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = sliderItems.map(item => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.image}
      >
        <img src={item.image} alt={item.title} />
        <div className="overlayer">&nbsp;</div>
        <div className="carousel-caption d-none d-md-block">
          <h3>{item.title}</h3>
          <p>{item.subtitle}</p>
          {item.linkCaption && (
            <ProperLink className="btn-lg btn btn-primary c2a" to={item.link}>
              {item.linkCaption}
            </ProperLink>
          )}
        </div>
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      className={searchIsOpen ? 'd-none' : ''}
      previous={previous}
      indicators={false}
      controls={false}
    >
      <CarouselIndicators items={slides} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
};

export default withFirebase(FeaturedSlider);