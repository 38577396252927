import { orgConstants } from "../constants";

export function org(state = { ready: false }, action) {
  switch (action.type) {
    case orgConstants.SET_ORG:
      return {
        ...state,
        ...{ ...action.payload },
        ready: true
      };
    default:
      return state;
  }
}
