import React from "react";

import { Container } from "reactstrap";

class PurchasingPolicy extends React.Component {
  render() {
    return (
      <>
        <Container className="clearfix mt-5">
        <h4 class="text-center">Política de Venta</h4>
          <div class="mt-4 ">
            <p>POR FAVOR LEA ATENTAMENTE EL SIGUIENTE AVISO LEGAL.</p>
            <p>1. <strong>Definiciones</strong>: En el presente apartado se definen las siguientes abreviaturas que se utilizarán a lo largo del presente documento. </p>
            <p><u>LA EMPRESA</u>: Se refiere a EVENTOS CROWDFRONT S.A. DE C.V. <br />
            <u>EL CLIENTE</u>: Se refiere a la persona física o moral que compra a través de la página <a href="https://www.handypass.io" target="_blank" rel="noopener noreferrer">www.handypass.io</a> cualquier tipo de boleto.<br />
            <u>LOS BOLETOS</u>: Se refiere a cualquier tipo de boleto que se ofrezca a la venta a través de la página de internet <a href="https://www.handypass.io" target="_blank" rel="noopener noreferrer">www.handypass.io</a>.<br />
            <u>EL SITIO</u>: Se refiere al sitio de internet con ubicación en la dirección de URL <a href="https://www.handypass.io" target="_blank" rel="noopener noreferrer">www.handypass.io</a>.<br />
            <u>EL PROVEEDOR</u>: Se refiere a el organizador o vendedor de cualquier tipo de boletos para cualquier tipo de evento, que desea venderlo a través de la página de internet <a href="https://www.handypass.io" target="_blank" rel="noopener noreferrer" >www.handypass.io</a>. previo contrato o convenio con EVENTOS CROWDFRONT S.A. DE C.V.<br />
            <u>LA INFORMACIÓN</u>: Es la información necesaria que la persona física o moral que compra a través de la página www.handypass.io cualquier tipo de boleto le tiene que entregar a EVENTOS CROWDFRONT S.A. DE C.V. para que pueda comprar cualquier tipo de boleto a través de la página de internet <a href="https://www.handypass.io" target="_blank" rel="noopener noreferrer">www.handypass.io</a>.<br />
            <u>LA COMPRA</u>: Se refiere al hecho que cualquier persona física o moral compre cualquier tipo de boleto a través de la página de internet <a href="https://www.handypass.io" target="_blank" rel="noopener noreferrer">www.handypass.io</a>.<br />
            <u>LOS EVENTOS</u>: Se refiere a cualquier tipo de evento que EVENTOS CROWDFRONT S.A. DE C.V. ofrezca cualquier tipo de boleto para su venta a través de la página de internet <a href="https://www.handypass.io" target="_blank" rel="noopener noreferrer">www.handypass.io</a>.<br />
            <u>EL CONTRATO</u>: Se refiere al presente documento que regula las políticas, términos y condiciones de la relación comercial entre el cliente y EVENTOS CROWDFRONT S.A. DE C.V. sobre la compra de boletos de cualquier tipo a través de la página de internet <a href="https://www.handypass.io" target="_blank" rel="noopener noreferrer">www.handypass.io</a>.</p>

            <p><strong>2. Aplicación de la Política de venta de boletos: </strong></p>
            <p>El presente aviso legal expresa y regula los términos y condiciones bajo los cuales se regulará la relación comercial entre EL CLIENTE y LA EMPRESA, al momento de la compra de LOS BOLETOS a través de EL SITIO. Al adquirir LOS BOLETOS que se pongan a la venta a través de EL SITIO, EL CLIENTE confirma su consentimiento y acepta los términos bajo los cuales se rige la relación entre EL CLIENTE y LA EMPRESA. En caso de que EL CLIENTE no esté de acuerdo con dichos términos y condiciones, no deberá realizar LA COMPRA de LOS BOLETOS a través de EL SITIO.</p>
            <p>Este es EL CONTRATO que regula los términos y condiciones bajo los cuales se regirá la relación comercial entre EL CLIENTE y LA EMPRESA en relación con LA COMPRA de LOS BOLETOS a través de EL SITIO.</p>
            <p>LA EMPRESA actúa a través de EL SITIO como un intermediario entre EL CLIENTE y EL PROVEEDOR. Al respecto, LA EMPRESA facilita la venta de LOS BOLETOS a EL CLIENTE, poniendo a su disposición EL SITIO, para lo cual, LA EMPRESA no participa directamente en la venta de LOS BOLETOS, pues únicamente es el enlace para la compra-venta entre EL CLIENTE y EL PROVEEDOR, por lo que no participa en la transacción que EL CLIENTE realice a través de EL SITIO. </p>
            <p>En ese sentido, las ventas son finales, mismas que se regulan a través de EL CONTRATO. LOS BOLETOS que se ofrecen a la venta a través de EL SITIO son de terceros ajenos a LA EMPRESA.</p>
            <p>EL CLIENTE que realice LA COMPRA de LOS BOLETOS a través de EL SITIO, se compromete a respetar los términos y condiciones de uso de la misma. Para mas información, revisar las Políticas términos y condiciones de uso.</p>
            <p>Si EL CLIENTE viola cualquiera de las políticas, términos o condiciones de cualquier tipo de LA EMPRESA y EL SITIO, LA EMPRESA podrá cancelar el acceso de EL CLIENTE a EL SITIO, su uso y cualquier tipo de operación comercial que éste desee realizar en futuras ocasiones, así como se reserva su derecho para actuar legalmente en su contra.</p>

            <p><strong>3. Tarifas de precios de LOS BOLETOS:</strong></p>
            <p>El precio de LOS BOLETOS para LOS EVENTOS los establece EL CLIENTE, mismos que podrán cambiar de tiempo en tiempo y LA EMPRESA los publica a través de EL SITIO.</p>
            <p>Los precios de LOS BOLETOS están en pesos mexicanos e incluyen el impuesto al valor agregado; asimismo, incluyen cargos por servicios, gastos de gestión y de entrega, como se define en cada compra, el cual en este último caso puede variar dependiendo del tipo de entrega seleccionado por EL CLIENTE y autorizado por LA EMPRESA, en adición al precio de cada boleto. Los cargos por servicio y por entrega de boletos aplicarán para cada boleto adquirido a través de LA EMPRESA.</p>
            <p>El precio que se cobra a EL CLIENTE adicional al precio individual de los boletos incluirá, como se mencionó anteriormente, las tarifas y cargos siguientes:</p>
            <p><u>Cobro del Servicio</u>: Es el costo que paga EL CLIENTE, asociado al uso y LA COMPRA de LOS BOLETOS a través de EL SITIO; gastos relacionados con servicio al cliente y otros costos similares necesarios para que EL CLIENTE tenga la posibilidad de comprar LOS BOLETOS a través de  EL SITIO.</p>
            <p><u>Cobro por entrega</u>: Es el costo que paga EL CLIENTE para que LA EMPRESA le entregue LOS BOLETOS asociados a LA COMPRA a través de EL SITIO, ya sea de forma física, por correo electrónico, mensaje de texto o algún otro medio.</p>
            <p><u>Cobro por gestión</u>: Es el costo que paga EL CLIENTE asociado a LA COMPRA de los BOLETOS, a efecto de que LA EMPRESA coordine el pago recibido por  EL CLIENTE para entregárselo a EL PROVEEDOR, así como gastos de gestión con los bancos, cuando el pago sea a través de una tarjeta de crédito, débito o PayPal o a través de alguna tiendas participantes cuando sea con cualquier método de pago mediante pago referenciado.</p>
            <p><u>Costo total</u>: Es el costo total que paga EL CLIENTE con motivo de LA COMPRA de LOS BOLETOS a través de EL SITIO.</p>
            <p>El SITIO y LA EMPRESA no están asociados directamente con cualquier artista, equipo deportivo, o lugar de LOS EVENTOS y EL SITIO no actúa como una oficina de ventas principal de LOS EVENTOS, a menos que se indique lo contrario. </p>

            <p><strong>4. Pago de LOS BOLETOS</strong></p>
            <p>EL CLIENTE podrá efectuar LA COMPRA de LOS BOLETOS a través de EL SITIO en tarjeta de crédito, débito, PayPal plus, pago en efectivo referenciado a través de tiendas de conveniencia como Oxxo y 7 eleven y demás tiendas participantes.</p>
            <p>Cuando LA COMPRA de LOS BOLETOS sea por PayPal plus o en efectivo mediante pago referenciado en las tiendas participantes, dichos servicio pueden estar sujetos a políticas, condiciones de uso y tarifas separadas y EL CLIENTE los acepta al completar la transacción utilizando la plataforma de PayPal plus o mediante el pago en efectivo.</p>
            <p>EL CLIENTE manifiesta que los datos utilizados para LA COMPRA de LOS BOLETOS son veraces y propios, por lo que el método de pago (ya sea tarjeta de crédito, débito, PayPal o efectivo) son de su propiedad. Asimismo manifiesta que es su deseo comprar LOS BOLETOS a través de EL SITIO para LOS EVENTOS, por lo que en caso de generar algún contracargo con su cuenta bancaria y que éste no le sea regresado a EL CLIENTE, EL CLIENTE se obliga a regresar y pagar la totalidad de la cantidad generada con motivo del contracargo a LA EMPRESA.</p>
          
            <p><strong>5. Compra de LOS BOLETOS</strong></p>
            <p>Para que LA COMPRA se perfeccione y finalice, estará sujeta a que LA EMPRESA compruebe los datos y tarjeta de crédito, débito o PayPal plus proporcionados por EL CLIENTE en LA COMPRA de LOS BOLETOS a través de EL SITIO, así como a la autorización por parte del banco emisor y aceptante de la tarjeta de crédito, débito o PayPal Plus, cuyos datos fueron proporcionados por EL CLIENTE para LA COMPRA de LOS BOLETOS a través de EL SITIO.</p>
            <p>En caso que de los datos y/o la tarjeta de crédito, débito o PayPal plus propiciados por EL CLIENTE no coincidan con los datos que tenga el banco emisor en su base de datos o aún y cuando los mismos si coincidan, pero el banco emisor o el banco aceptante no autoricen el cargo solicitado por EL CLIENTE, LA COMPRA no será ni procesada ni finalizada o perfeccionada y LOS BOLETOS no serán vendidos a EL CLIENTE, siendo ofrecidos a través de EL SITIO para su venta, sin responsabilidad alguna para LA EMPRESA.</p>
            <p>Asimismo, LA EMPRESA se reserva el derecho a declinar un cargo por comportamientos sospechosos, por lo que LA COMPRA no será ni procesada ni finalizada o perfeccionada y LOS BOLETOS no serán vendidos a EL CLIENTE, siendo ofrecidos a través de EL SITIO para su venta, sin responsabilidad alguna para LA EMPRESA.</p>
            <p>En ese sentido, EL CIENTE acepta expresamente que la solicitud para LA COMPRA de LOS BOLETOS que realice a través de EL SITIO, es una oferta que vincula a EL CLIENTE que una vez que el banco emisor de la tarjeta de crédito, débito o PayPal plus reciba la autorización del banco aceptante, LA COMPRA será definitiva y no estará sujeta a cambios, rembolsos, devoluciones o cancelaciones.</p>
            <p>LA COMPRA de LOS BOLETOS está limitada a un número de boletos adquiribles por cada persona, según el contrato con EL PROVEEDOR de LOS EVENTOS, por lo que esa cantidad varía dependiendo de LOS EVENTOS.</p>
            <p>En ese sentido, si en una o mas operaciones de compra, se excede el límite de boletos adquiribles por una misma persona, las solicitudes de compra serán canceladas automáticamente sin previo aviso y sin responsabilidad para LA EMPRESA.</p>
            <p>En el caso que se hubiere hecho algún cargo a la tarjeta de crédito, débito o PayPal plus de EL CLIENTE, por LA COMPRA de LOS BOLETOS que LA EMPRESA haya cancelado con motivo de lo señalado en el párrafo anterior, se reembolsará a EL CLIENTE la totalidad de los cargos realizados.</p>
            <p>Al momento de LA COMPRA, EL SITIO le proporciona a EL CLIENTE el mejor lugar disponible al momento de realizar LA COMPRA, de acuerdo al ajuste de la solicitud realizada, a menos de que EL CLIENTE haya optado por elegir directamente el lugar a través del mapa de lugares de LOS EVENTOS.</p>
            
            <p><strong>6. Cancelación de LOS EVENTOS</strong></p>
            <p>En caso de que alguno de LOS EVENTOS haya sido cancelado o pospuesto, se rembolsará la cantidad del costo de LOS BOLETOS, descontando los cargos por servicio, gestión y entrega, a EL CLIENTE. Dicha cantidad será devuelta previa acreditación de la legitima compra por EL TITULAR, desde las 72 horas (de días hábiles) siguientes al aviso correspondiente.</p>

            <p><strong>7. Reembolso, cambio, cancelación o devoluciones de LOS BOLETOS</strong></p>
            <p>EL CLIENTE acepta que LA COMPRA de LOS BOLETOS es definitiva y que no tendrá derecho a cambio, rembolso, devolución o cancelación de los mismos, aún y cuando EL CLIENTE no confirme de recibidos LOS BOLETOS, no los imprima o no haga uso de ellos.</p>
            <p>EL CLIENTE acepta expresamente que únicamente tendrá derecho al rembolso de LA COMPRA de LOS BOLETOS en el supuesto señalado en el punto 6 del presente contrato, sobre el cual acepta de manera expresa que de ninguna manera y bajo ninguna circunstancia tendrá derecho al rembolso o devolución de los cargos por servicio, gestión y entrega de LOS BOLETOS.</p>
            <p>En caso de alguna queja por rembolso, cancelación, cambio o devolución de LOS BOLETOS, EL PROVEEDOR es el único responsable, ya que como se ha mencionado, LA EMPRESA únicamente actúa a través de EL SITIO como un intermediario entre EL CLIENTE y EL PROVEEDOR por lo que LA EMPRESA facilita la venta de LOS BOLETOS a EL CLIENTE, poniendo a su disposición EL SITIO, para lo cual, LA EMPRESA no participa directamente en la venta de LOS BOLETOS.</p>

            <p><strong>8. Entrega y tiempo de entrega de LOS BOLETOS</strong></p>
            <p>Una vez que EL CLIENTE realice el pago a través de cualquier método permitido por EL SITIO y que la transacción sea aproada, se le entregarán LOS BOLETOS, ya sea física o electrónicamente, de acuerdo a su método de pago y su elección para recibir LOS BOLETOS. </p>
            <p>LA COMPRA de LOS BOLETOS a través de EL SITIO se perfecciona hasta el momento en el que la transacción es aprobada por la institución bancaria y no así con el simple pago de LOS BOLETOS.</p>
            <p>Una vez aprobada la transacción del pago realizado por EL CLIENTE, se le enviarán LOS BOLETOS a EL CLIENTE a través del correo electrónico proporcionado para recibir LOS BOLETOS. </p>
            <p>En caso de que EL CLIENTE haya efectuado el pago y no haya recibido LOS BOLETOS, deberá levantar LOS BOLETOS nuevamente en el sistema de EL SITIO. Una vez realizada dicha acción, en caso de continuar sin recibir LOS BOLETOS, se deberá comunicar con LA EMPRESA o enviar un correo a la dirección hola@handypass.io para solicitar el envío de LOS BOLETOS.</p>
            <p>Es importante que EL CLIENTE proporcione una dirección de correo electrónico válida, para así estar en posibilidad de que EL SITIO y LA EMPRESA le envíen LOS BOLETOS.</p>
          
            <p><strong>9. Horario de LOS EVENTOS</strong></p>
            <p>Toda vez que LA EMPRESA únicamente actúa a través de EL SITIO como un intermediario entre EL CLIENTE y EL PROVEEDOR, LA EMPRESA únicamente facilita la venta de LOS BOLETOS a EL CLIENTE, poniendo a su disposición EL SITIO, para lo cual, LA EMPRESA no participa directamente en la venta de LOS BOLETOS y no es responsable por el contenido ni horario que venga en LOS BOLETOS respecto de LOS EVENTOS.</p>
            <p>Asimismo, los horarios de LOS EVENTOS cambiarán dependiendo de cada evento, para lo cual EL CLIENTE  es el responsable de verificar la fecha, hora y lugar en el cual se llevarán a cabo LOS EVENTOS sobre LOS BOLETOS que haya adquirido con motivo de LA COMPRA a través de  EL SITIO, liberado de cualquier responsabilidad a LA EMPRESA.</p>
          
            <p><strong>10. Responsabilidad sobre la obtención, uso, almacenamiento y transferencias de la Información Financiera y de los datos personales de EL CLIENTE. </strong></p>
            <p>Con motivo de LA COMPRA de LOS BOLETOS por parte de EL CLIENTE a través de EL SISTIO, LA EMPRESA solicita datos personales y datos personales sensibles, de los cuales su obtención, uso, almacenamiento y transferencia será en términos de las leyes mexicanas, en específico de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, siempre con el consentimiento de EL CLIENTE. El aviso de privada forma parte integral del presente contrato, mismo que EL CLIENTE podrá encontrar en <a href="/privacy_policy" >Aviso de Privacidad</a>.</p>

            <p><strong>11. Cargos en conflicto</strong></p>
            <p>En caso de cualquier dispuesta, queja, juicio o cualquier conflicto, EL CLIENTE es el responsable de los costos legales en que incurra. En ningún caso, EL SITIO o LA EMPRESA serán responsables por cargos legales.</p>

            <p><strong>12. Lista de Eventos</strong></p>
            <p>El SITIO no garantiza que en la información de LOS EVENTOS en el SITIO es exacta, de manera enunciativa mas no limitativa por lo que hace al nombre del evento, lugar del evento, fecha del evento, horario del evento y espectáculo del evento.</p>
          
            <p><strong>13. Disponibilidad de Boletos</strong></p>
            <p>EL SITIO y LA EMPRESA no pueden garantizar a EL CLIENTE la disponibilidad de LOS BOLETOS hasta en tanto no se les hayan entregado por correo electrónico o de forma física.</p>
            
            <p>Lo anterior, pues ya que LA EMPRESA únicamente actúa a través de EL SITIO como un intermediario entre EL CLIENTE y EL PROVEEDOR, LA EMPRESA no participa directamente en la venta de LOS BOLETOS, por lo que existe la posibilidad que EL CLIENTE haya efectuado LA COMPRA y que al momento de enviarle LOS BOLETOS, éstos no estén disponibles, ya sea porque se agotaron, por un error en el sistema, por órdenes de EL PROVEEDOR, o por alguna otra causa, todas sin responsabilidad para LA EMPRESA. En estos casos, las cantidades pagaras les serían devueltas a EL CLIENTE.</p>

            <p><strong>14. Facturación</strong></p>
            <p>Si EL CLIENTE requiere factura, LA EMPRESA le emitirá una factura por los cargos por servicio, gestión y envío. </p>
            <p>Por lo que respecta a la factura del costo de LOS BOLETOS, EL CLIENTE la deberá solicitar directamente a EL PROVEEDOR, contactándose con éste por el medio de contacto que EL PROVEEEDOR a su vez establezca en sus políticas de LOS EVENTOS, mismas que EL CLIENTE tiene acceso al momento de LA COMPRA de LOS BOLETOS a través de EL SITIO.</p>
            <p>Con independencia de lo anterior y sin obligación y/o responsabilidad alguna, LA EMPRESA se compromete a ayudar a EL CLIENTE a conseguir que EL PROVEEDOR le emita la factura correspondiente del costo de LOS BOLETOS.</p>

            <p><strong>15. Limitación de Responsabilidad</strong></p>
            <p>LA EMPRESA únicamente actúa a través de EL SITIO como un intermediario entre EL CLIENTE y EL PROVEEDOR por lo que LA EMPRESA facilita la venta de LOS BOLETOS a EL CLIENTE, poniendo a su disposición EL SITIO, para lo cual, LA EMPRESA no participa directamente en la venta de LOS BOLETOS, por lo que EL CLEINTE reconoce y acepta que cualquier problema que EL CIENTE tenga con LA COMPRA, libera de toda responsabilidad legal a LA EMPRESA y EL SITIO.</p>
            <p>EL CLIENTE manifiesta que está de acuerdo en liberar a LA EMPRESA y EL SITIO de cualquier responsabilidad, derivado de faltas cometidas por EL CLIENTE en contra de cualquier ley, por lo que acepta y reconoce que LA EMPRESA y EL SITIO cumplirán con las autoridades que así lo requieran, por lo que LA EMPRESA les podrá proporcionar toda la información que EL CLIENTE haya proporcionado, para ayudar a cualquier investigación.</p>
            <p>Asimismo, en caso de que LA EMPRESA no pueda verificar o comprobar la autenticidad de cualquier información que proporcione EL CLIENTE durante cualquier proceso de compra de LOS BOLETOS  a través de EL SITIO se le prohibirá el uso de EL SITIO a EL CLIENTE.</p>

            <p><strong>16. Uso de EL SITIO.</strong></p>
            <p>EL CLIENTE se obliga a hacer buen uso de EL SITIO, respecto las políticas, términos y condiciones de uso establecidas en Políticas términos y condiciones de uso, mismas que forman parte integral del presente contrato.</p>

            <p><strong>16. Daños en LOS EVENTOS</strong></p>
            <p>EL CLIENTE manifiesta que cualquier riesgo o daño que pueda sufrir o que sufra, será responsabilidad de EL PROVEEDOR, liberando de toda responsabilidad a LA EMPRESA por cualquier daño o afectación que pudiese ocurrir en EL EVENTO de cualquier índole.</p>

            <p><strong>18. Jurisdicción</strong></p>
            <p>Para la solución de cualquier controversia que surja con motivo de la interpretación, cumplimiento y/o ejecución de lo convenido en EL CONTRATO, EL CLIENTE se somete expresamente y de común acuerdo a la jurisdicción de los tribunales competentes con residencia en la Ciudad de Monterrey Nuevo León, y a las Leyes, reglamentos y demás disposiciones legales vigentes en dicha entidad, renunciando a cualquier otro fuero que por razón de su domicilio  presente o futuro o por cualquier otra causa pudiera corresponderles</p>

            <p class="font-italic">UNA VEZ LEÍDO EL CONTRATO, EL CLIENTE ACEPTA EXPRESAMENTE LOS TÉRMINOS Y CONDICIONES BAJO LOS CUALES SE REJIRA SU RELACIÓN CONTRATUAL EN LO CONCERNIENTE A LA COMPRA DE LOS BOLETOS A TRAVÉS DE EL SITIO.</p>
            <p>Derechos Reservados © EVENTOS CROWDFRONT S.A. DE C.V.</p>

          </div>


        </Container>
      </>
    );
  }
}
export default PurchasingPolicy;
