export const serializeForm = (e) => {
  const elements = e.querySelectorAll('input, select, textarea');
  let data = {};
  for (var i = 0; i < elements.length; i++) {
    const  element = elements[i];
    let value = element.value;

    if (element.type === "radio") {
      const checkedElement = e.querySelector(`input[name="${element.name}"]:checked`);
      if (checkedElement) value = checkedElement.value;
    }

    if (!value) value = "";

    let fullName = element.name;

    if (!fullName) continue;

    let fullNameParts = fullName.split('.');
    let prefix = '';
    let stack = data;
    for (var k = 0; k < fullNameParts.length - 1; k++) {
      prefix = fullNameParts[k];
      if (!stack[prefix]) {
        stack[prefix] = {};
      }
      stack = stack[prefix];
    }
    prefix = fullNameParts[fullNameParts.length - 1];
    stack[prefix] = value;

  }
  return data;
}