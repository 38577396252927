import React, { Component } from 'react';
import { Col, ListGroup, ListGroupItem, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import visa from '../../assets/images/visa.svg';
import amex from '../../assets/images/amex.svg';
import mastercard from '../../assets/images/mastercard.svg';
import paypal from '../../assets/images/paypal.svg';
import oxxo_pay from '../../assets/images/oxxopay.png'
import './styles.scss';

// eslint-disable-next-line react/prefer-stateless-function
export default class index extends Component {
  render() {
    const { typePayment, selectedPayment, name } = this.props;
    return (
      <Col lg="12">
        <ListGroup
className="mt10"
onClick={() => this.props.setSelectedPayment(typePayment)}>
          <ListGroupItem
            className="d-flex justify-content-between align-items-center"
            color={selectedPayment === typePayment ? 'success' : 'default'}
          >
            <div className="flex-column align-items-start">
              {selectedPayment === typePayment ? (
                <FontAwesomeIcon icon={faCaretDown} />
              ) : (
                <FontAwesomeIcon icon={faCaretRight} />
              )}
              {name}
            </div>
            <PaymentMethodsIcons paymentMethod={typePayment} />
          </ListGroupItem>
        </ListGroup>
        <Collapse isOpen={selectedPayment === typePayment}>
          {this.props.children}
        </Collapse>
      </Col>
    );
  }
}

const PaymentMethodsIcons = ({ paymentMethod }) => {
  switch (paymentMethod) {
    case 'CONEKTA':
      return (
        <div className="row">
          <div className="col align-self-end">
            <img className="card_icons" src={visa} width="50px" height="32px" alt="visa" />
            <img
              className="marginIcons card_icons"
              src={mastercard}
              width="50px"
              height="32px"
              alt="mastercard"
            />
            <img
              className="card_icons"
              src={amex}
              width="50px"
              height="32px"
              alt="american express"
            />
          </div>
        </div>
      );
    case 'PAYPAL_PLUS':
      return (
        <div className="row">
          <div className="col align-self-end">
            <img src={paypal} width="50px" height="32px" alt="paypal-plus" />
          </div>
        </div>
      );
    case  'OXXO_PAY':
      return (
        <div className="row">
          <div className="col align-self-end">
          <img src={oxxo_pay} width="100px" height="30px" alt="oxxo-pay" />
          </div>
        </div>
      );
    default:
      return null;
  }
};
