import moment from "moment";
export function timerPromise(timeToExpireOrder, duration) {
  return new Promise((resolve, reject) => {
    if (moment().unix() > timeToExpireOrder) {
      resolve("ORDEN EXPIRADA");
    } else {
      setTimeout(() => {
        resolve("ORDEN EXPIRADA");
      }, Math.abs(duration.asMilliseconds()));
    }
  });
}

export function convertToSeconds(milliseconds) {
  const ms = 1000 * Math.round(milliseconds / 1000); // round to nearest second
  const d = new Date(ms);
  const seconds =
    d.getUTCSeconds() < 10 ? "0" + d.getUTCSeconds() : d.getUTCSeconds();
  return d.getUTCMinutes() + ":" + seconds;
}
