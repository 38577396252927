import { orderDetailsConstants } from "../constants";

export function orderDetails(state = {}, action) {
  switch (action.type) {
    case orderDetailsConstants.ADD_ORDER_ID:
      return {
        ...state,
        ...{
          [action.payload.eventId]: {
            orderId: action.payload.orderId,
          },
        },
      };
    case "EXPIRE_ORDER":
      return {
        ...state,
        ...{
          [action.payload.eventId]: {
            orderId: "",
          },
        },
      };
    case "RESET": {
      return action.payload
    }
    default:
      return state;
  }
}
