import React from "react";

import { Container } from "reactstrap";

class AboutUs extends React.Component {
  render() {
    return (
      <>
        <Container className="clearfix">
          <h3>¿Quiénes Somos?</h3>
          lorem ipsum dolor sit amet
        </Container>
      </>
    );
  }
}
export default AboutUs;
