/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { FormGroup, Label } from 'reactstrap';
import ReactPhoneInput from 'react-phone-input-2';
import './style.scss';
import { useFirebaseAuth } from '../../hooks/useFirebaseAuth';
import EventContainer from '../../components/event_container';

function CompleteUserData({ history }) {
  const { user, updateUser } = useFirebaseAuth();
  const [phone, setPhone] = React.useState('');
  const [errors, setErrors] = React.useState({});

  const validate = () => {
    if (!phone) {
      setErrors({ phone: 'Se requiere un teléfono' });
    } else if (phone.length < 10) {
      setErrors({ phone: 'El teléfono requiere de 10 digitos' });
    } else {
      setErrors({});
    }
  };

  const onContinue = () => {
    validate();
    if (!Object.values(errors).length > 0) {
      history.replace('payment-methods');
      // updateUser({ phone }).then(() => {
      //   history.replace('payment-methods');
      // });
    }
  };

  React.useEffect(() => {
    if (Object.values(errors).length > 0) {
      validate();
    }
  }, [phone]);

  return (
    <EventContainer>
      {() => {
        return (
          <div className="CompleteUserData">
            <p>Complete los campos para continuar la compra.</p>
            <p>
              Nombre:
              <span>{` ${user.name && user.name.firstName} ${user.name &&
                user.name.lastName}`}</span>
            </p>
            <p>
              Correo:
              <span>{` ${user.email}`}</span>
            </p>
            <FormGroup>
              <Label for="exampleEmail">Número telefónico</Label>
              <ReactPhoneInput
                value={phone}
                onChange={e => setPhone(e)}
                defaultCountry="mx"
                inputExtraProps={{
                  required: true
                }}
                regions={[
                  'north-america',
                  'south-america',
                  'central-america',
                  'carribean',
                  'european-union'
                ]}
              />
              {errors.phone && <p className="text-error">{errors.phone}</p>}
            </FormGroup>
            <button
              type="submit"
              className="btn-lg btn-block btn btn-primary sign-in-anonymously-button"
              disabled={Object.values(errors).length > 0}
              onClick={onContinue}
            >
              Continuar
            </button>
          </div>
        );
      }}
    </EventContainer>
  );
}

export default withRouter(CompleteUserData);
