import React from 'react';
import { FormControlLabel, Radio, Box } from '@material-ui/core';
import mastercard from '../../assets/images/mastercard.svg';
import visa from '../../assets/images/visa.svg';
import amex from '../../assets/images/amex.svg';

export default function CardItem({ value, brand, last4, id }) {
  const imageByBrand = () => {
    if (brand === 'mastercard') {
      return mastercard;
    }
    if (brand === 'visa') {
      return visa;
    }
    return amex;
  };
  return (
    <Box
      className="CardItem"
      display="flex"
      alignItems="center"
      pr={1}
      mb="15px"
      bgcolor={value === id ? 'rgba(244, 243, 250, 0.5)' : ''}
      borderRadius="6px"
    >
      <Box flexGrow={1}>
        <FormControlLabel
          value={id}
          label={`**** **** **** ${last4}`}
          control={<Radio color="default" />}
        />
      </Box>
      <Box>
        <img src={imageByBrand()} alt="Card Brand" />
      </Box>
    </Box>
  );
}
