import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import TextTruncate from 'react-text-truncate';
import { Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle, Button } from 'reactstrap';

import './index.scss';

import Moment from 'react-moment';
import 'moment-timezone';


const EventCard = props => {
  const {
    name,
    description,
    headerImage,
    organizationId,
    Organization,
    startsAt,
    slug
  } = props.event;

  return (
    <Card className="event-card" tag={Link} to={`/${Organization.slug}/${slug}`}>
      <CardImg top width="100%" src={headerImage} alt={name} />
      <CardBody>
        <CardTitle>{name}</CardTitle>
        <CardText>
          {/* <TextTruncate line={1} element="span" truncateText="…" text={description} /> */}
          <Moment className="capitalized" date={startsAt} format="D MMMM YYYY" />
        </CardText>
        <div>
          <img
            className="rounded-circle org-logo"
            src={Organization.logo}
            alt={Organization.name}
          />
          {Organization.name}
        </div>
      </CardBody>
    </Card>
  );
};

export default EventCard;
