import React, { useEffect, useState } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
// components
import { Row, Col, Form, FormGroup, Label, Input, Alert } from 'reactstrap';

import { useFirebaseAuth } from '../../../hooks/useFirebaseAuth';

function PhoneAuth({ toggleModal, changeMode }) {
  const [country, setCountry] = useState('mx');
  const [phone, setPhone] = useState('');
  const { updateUser } = useFirebaseAuth();

  const handlePhoneData = (value, data) => {
    setCountry(data.countryCode);
    setPhone(value);
  };

  const isFormValid = () => {
    return phone.length >= 8;
  };

  const handleOnClick = () => {
    updateUser({ phone }).then(() => {
      changeMode('USER PROFILE', 'PERFIL');
      toggleModal();
    });
  };

  return (
    <>
      <div id="captcha" />
      <Form>
        <FormGroup>
          <Label for="exampleEmail">Número telefónico</Label>
          <ReactPhoneInput
            defaultCountry={country}
            onChange={handlePhoneData}
            value={phone}
            inputExtraProps={{
              required: true
            }}
            regions={[
              'north-america',
              'south-america',
              'central-america',
              'carribean',
              'european-union'
            ]}
          />
        </FormGroup>
      </Form>
      <button
        type="button"
        className="btn-lg btn-block btn btn-primary login-button"
        disabled={!isFormValid()}
        onClick={handleOnClick}
      >
        Crear Cuenta
      </button>
    </>
  );
}

export default PhoneAuth;
