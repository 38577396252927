import React from 'react';
import { Link } from 'react-router-dom';

function ProperLink(props) {
  const { to, children } = props;
  return (
    <>
      {/^https?:\/\//.test(to) ?
        <a href={to} {...props}>
          {children}
        </a>
        :
        <Link to={to} {...props}>
          {children}
        </Link>
      }
    </>
  );
}

export default ProperLink;
