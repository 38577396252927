import AccountContext from './AccountContext';
import React, { Component } from 'react';
import { withFirebase } from '../../components/firebase';
// helpers
import axios from 'axios';
// redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import { userSignedIn, userSignedOut } from '../../redux/actions';

class AccountProvider extends Component {
  state = {
    modalLogin: false,
    firstName: '',
    lastName: '',
    phone: '',
    meesage: '',
    country: 'mx',
    isLoading: false
  };

  updateUser = async () => {
    return new Promise((resolve, reject) => {
      const date = new Date();
      const { firstName, lastName, phone } = this.state;
      // compose data to update user
      const data = {
        name: { firstName, lastName },
        phone,
        updatedAt: date
      };
      // get current user id
      const { uid } = this.props.user_data;
      const payload = { uid, data };
      this.setState({
        message: 'actualizando tus datos de usuario'
      });
      // update store with new data (it will update automatically after relogin)
      // if not anon
      const { isAnonymous } = this.props.user_data;
      if (!isAnonymous) {
        axios
          .patch(`${process.env.REACT_APP_FUNCTIONS_URL}/users`, payload)
          .then(response => {
            this.setState({
              message: 'datos actualizados correctamente'
            });
            // update user data in local state
            this.props.userSignedIn({ firstName, lastName, phone });
            resolve(true);
          })
          .catch(error => {
            this.setState({
              message: 'no pudimos actualizar tus datos, intenta nuevamente'
            });
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error.config);
            resolve(true);
          });
      } else if (isAnonymous) {
        // just go to next page, data is already in store.
        this.props.userSignedIn({ firstName, lastName, phone });
        resolve(true);
      }
    });
  };
  updateOrderWithUserId = async () => {
    return new Promise((resolve, reject) => {
      const { organizationId, eventId } = this.props.event;
      const { orderId } = this.props.orderDetails[eventId];
      // const { uid } = this.props.user_data;
      // this.props.firebase.updateOrderWithUserId(organizationId, eventId, orderId, uid);
      resolve(true);
    });
  };
  continueWithPurchase = async () => {
    await this.updateUser();
    await this.updateOrderWithUserId();
  };
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  setPhone = (value, data) => {
    this.setState({ country: data.countryCode });
    this.setState({ phone: value.replace(/[^0-9]+/g, '').slice(data.dialCode.length) });
  };
  // getCurrentUser = () => {
  //   return new Promise((resolve, reject) => {
  //     const self = this;
  //     this.props.firebase.auth.onAuthStateChanged(async function(user) {
  //       if (user && !user.isAnonymous) {
  //         const { email, emailVerified, isAnonymous, uid } = user;
  //         const userCredentials = await self.props.firebase.currentUserCredentials(uid);
  //         const { phone, name } = userCredentials;
  //         const { firstName, lastName } = name;
  //         self.props.userSignedIn({
  //           firstName,
  //           lastName,
  //           phone,
  //           email,
  //           emailVerified,
  //           isAnonymous,
  //           uid
  //         });
  //         resolve(true);
  //       } else if (user && user.isAnonymous) {
  //         //when logging in as a anonymous user, don't change state for user, that overwrites user email in local state
  //       } else {
  //         self.props.userSignedOut();
  //         resolve(true);
  //       }
  //     });
  //   });
  // };
  // componentDidMount = async () => {
  //   const { uid } = this.props.user_data;
  //   const userCredential = await this.props.firebase.currentUserCredentials(uid);
  //   const { name, phone } = userCredential;
  //   const { firstName, lastName } = name;
  //   this.setState({ firstName, lastName, phone });
  // };
  render() {
    return (
      <AccountContext.Provider
        value={{
          modalLogin: this.state.modalLogin,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          phone: this.state.phone,
          country: this.state.country,
          isLoading: this.state.isLoading,
          toggleModal: () => {
            this.setState({ modalLogin: !this.state.modalLogin });
          },
          onChange: e => {
            this.onChange(e);
          },
          setPhone: this.setPhone,
          isFormValidContinueWithPurchase: () => {
            const { firstName, lastName, phone } = this.state;
            return firstName.length > 2 && lastName.length > 2 && phone.length >= 8 ? false : true;
          },
          continueWithPurchase: async () => {
            this.setState({ isLoading: true });
            await this.continueWithPurchase();
            this.setState({ isLoading: false });
          }
        }}
      >
        {this.props.children}
      </AccountContext.Provider>
    );
  }
}

export default compose(
  withFirebase,
  connect(
    state => ({
      user_data: state.user_data,
      event: state.event,
      orderDetails: state.orderDetails
    }),
    dispatch => bindActionCreators({ userSignedIn, userSignedOut }, dispatch)
  )
)(AccountProvider);
