import React, { useState, useEffect, useContext, useM } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import TermsAndConditions from '../../components/terms_and_conditions';
import { Row, Col, Form, FormGroup, CustomInput } from 'reactstrap';
import Conekta from '../../components/payments/conekta';
import Payments from '../../components/payments';
import OxxoPay from '../../components/payments/oxxo_pay';
// import Paypal from '../../components/payments/paypal';
import ConektaErrors from '../../components/payments/conekta/errors';
import EventContainer from '../../components/event_container';
import { sendEventPixel } from '../../helpers/pixel';
import { withFirebase } from '../../components/firebase';
import PaypalV2 from '../../components/payments/PaypalV2';

import './styles.scss';
import { useFirebaseAuth } from '../../hooks/useFirebaseAuth';
import PaymentCards from '../../containers/PaymentCards';
import ConektaContext from '../../providers/payments/ConektaContext';
import PaypalErrors from '../../components/payments/PaypalV2/errors';
import { useMemo } from 'react';

const Wrapper = props => {
  const { defaultPaymentGateway } = props.event;
  const [selectedPayment, setSelectedPayment] = useState(defaultPaymentGateway);
  const [paypalSelectionPage, setPaypalSelectionPage] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { user } = useFirebaseAuth();
  useEffect(() => {
    setSelectedPayment(defaultPaymentGateway);
  }, [defaultPaymentGateway]);
  return useMemo(
    () => (
      <EventContainer
        selectedPayment={selectedPayment}
        paypalSelectionPage={paypalSelectionPage}
        disableContinue={isButtonDisabled}
        setIsButtonDisabled={setIsButtonDisabled}
        user={user}
        step={'Paso 4'}
      >
        {({ event, eventId, organizationId, acceptTerms, handleAcceptTerms }) => {
          const mixProps = {
            ...props,
            event,
            eventId,
            organizationId,
            acceptTerms,
            handleAcceptTerms,
            selectedPayment,
            setSelectedPayment,
            paypalSelectionPage,
            setPaypalSelectionPage,
            isButtonDisabled,
            setIsButtonDisabled
          };
          return <PaymentMethods {...mixProps} />;
        }}
      </EventContainer>
    ),
    [selectedPayment, isButtonDisabled, paypalSelectionPage]
  );
};
const PaymentMethods = ({
  firebase,
  orderDetails,
  history,
  event,
  eventId,
  acceptedTerms,
  organizationId,
  handleAcceptTerms,
  selectedPayment,
  setSelectedPayment,
  setPaypalSelectionPage,
  setIsButtonDisabled
}) => {
  const context = useContext(ConektaContext);
  const [showPaymentMethods, setShowPaymentMethods] = useState(false);
  const [paypalError, setPaypalError] = useState(false);
  const [paypalErrorMessage, setPaypalMessageError] = useState('');
  const { user } = useFirebaseAuth();
  const { orderId } = orderDetails[eventId] || {};
  let flag = false;

  const [paymentsCards, setPaymentsCards] = useState([]);

  useEffect(() => {
    if (user.conektaCustomerId && !user.isGuest) {
      // eslint-disable-next-line no-shadow
      context.getPaymentsCards(user.conektaCustomerId).then(response => {
        const { data, default_payment_source_id } = response;
        context.setPaymentCard(default_payment_source_id);
        setPaymentsCards(data);
      });
    }
  }, []);

  useEffect(() => {
    sendEventPixel('AddPaymentInfo');
    flag = false;
    // this.getDefaultPaymentGateway();
  }, []);

  useEffect(() => {
    if (typeof orderDetails[eventId] === 'undefined') {
      console.log('El event id está undefined');
      history.push('/');
    } else {
      handleCheckOrder();
    }
  }, []);

  const handleCheckOrder = async () => {
    if (!flag) {
      const orderDoc = await firebase.db
        .collection('organizations')
        .doc(organizationId)
        .collection('events')
        .doc(eventId)
        .collection('orders')
        .doc(orderId)
        .get();
      flag = true;
      setShowPaymentMethods(orderDoc.data().total > 0);
    }
  };

  return showPaymentMethods ? (
    <Row>
      <Col lg="12">
        {selectedPayment === 'CONEKTA' && <ConektaErrors />}
        {selectedPayment === 'PAYPAL_PLUS' && (
          <PaypalErrors show={paypalError} message={paypalErrorMessage} />
        )}
        <h3 className="subtitle">Opciones de pago</h3>
      </Col>

      {orderDetails[eventId] && event.paymentGateways.includes('CONEKTA') && (
        <Payments
          togglerName="ConektaCollapse"
          typePayment="CONEKTA"
          name="Tarjetas de Crédito / Débito"
          selectedPayment={selectedPayment}
          setSelectedPayment={setSelectedPayment}
        >
          <PaymentCards
            cards={paymentsCards}
            value={context.paymentCard}
            onChange={e => context.setPaymentCard(e.target.value)}
            onAddCard={() => {
              context.setPaymentCard('');
            }}
          />

          {!context.paymentCard && <Conekta />}
        </Payments>
      )}
      {orderDetails[eventId] && event.paymentGateways.includes('PAYPAL_PLUS') && (
        <Payments
          togglerName="PaypalCollapse"
          typePayment="PAYPAL_PLUS"
          name="Tarjetas de Crédito / Débito"
          selectedPayment={selectedPayment}
          setSelectedPayment={setSelectedPayment}
        >
          <PaypalV2
            eventId={eventId}
            organizationId={organizationId}
            orderId={orderDetails[eventId].orderId}
            selectedPayment={selectedPayment}
            setPaypalSelectionPage={setPaypalSelectionPage}
            setDisableContinue={setIsButtonDisabled}
            setPaypalError={setPaypalError}
            setPaypalMessageError={setPaypalMessageError}
          />
        </Payments>
      )}
      {orderDetails[eventId] && event.paymentGateways.includes('CONEKTA_CASH') && (
        <Payments
          togglerName="OxxoPayCollapse"
          typePayment="OXXO_PAY"
          name="Pago por Oxxo Pay"
          selectedPayment={selectedPayment}
          setSelectedPayment={setSelectedPayment}
        >
          <OxxoPay />
        </Payments>
      )}

      <Col lg="12" className="mt20">
        <Form className="mt10">
          <FormGroup>
            <div className="d-inline-flex">
              <CustomInput
                type="checkbox"
                id="terminos"
                required
                defaultChecked={acceptedTerms}
                onClick={handleAcceptTerms}
              />
              <span>
                He leído y aceptado los{' '}
                <a href="/terms_and_conditions" target="_blank">
                  términos y condiciones
                </a>{' '}
                de uso.
              </span>
            </div>
          </FormGroup>
        </Form>
      </Col>
    </Row>
  ) : (
    <Row>
      <h1 className="title-20">Acepta los términos y condiciones </h1>
      <Col lg="12" className="mt20">
        <Form className="mt10">
          <FormGroup>
            <div className="d-inline-flex">
              <CustomInput
                type="checkbox"
                id="terminos"
                required
                defaultChecked={acceptedTerms}
                onClick={handleAcceptTerms}
              />
              <span>
                He leído y aceptado los{' '}
                <a href="/terms_and_conditions" target="_blank">
                  términos y condiciones
                </a>{' '}
                de uso.
              </span>
            </div>
          </FormGroup>
        </Form>
      </Col>
    </Row>
  );
};

export default compose(
  withFirebase,
  connect(
    state => ({
      user_data: state.user_data,
      event: state.event,
      orderDetails: state.orderDetails,
      acceptedTerms: state.cart.acceptedTerms
    }),
    dispatch => bindActionCreators({}, dispatch)
  )
)(Wrapper);
