import React, { Component } from 'react';
import ConektaContext from '../../../providers/payments/ConektaContext';

export default class ConektaErrors extends Component {
  render() {
    return (
      <ConektaContext.Consumer>
        {context => (
          <>
            {/*eslint-disable-next-line */}
            {context.conektaSuccess && (
              <div className="alert alert-success">Tu pago fue procesado con éxito.</div>
            )}
            {context.conektaError && (
              <div className="alert alert-danger">{context.cardDeclinedMessageConekta}</div>
            )}

            {context.banorteExclusiveSale &&
              !context.ccIsBanorte &&
              context.cardNumber.length >= 14 && (
                <div className="alert alert-danger">
                  La fase de preventa sólo permite tarjetas Banorte.
                </div>
              )}
          </>
        )}
      </ConektaContext.Consumer>
    );
  }
}
