import React from "react";
import Image from "../image";

class EventCover extends React.Component {
  render() {
    const { headerImage } = this.props;
    return (
      <>
        <Image
          imgUrl={headerImage}
          className="event-cover"
          theme={{
            width: "100%",
            height: "auto",
            maxHeight: "320px",
            objectFit: "cover"
          }}
        />
        <br />
        <br />
      </>
    );
  }
}

export default EventCover;
