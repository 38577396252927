export const parsePayload = (
  phases,
  eventId,
  orgId,
  orderType,
  userId,
  orderId,
  LogRocketSessionUrl
) => {
  let data = {
    cart: {
      orgId,
      eventId,
      orderType,
      userId,
      orderTickets: [],
      orderId,
      LogRocketSessionUrl
    },
  };
  //we convert object from array because we are dealing
  //with a redux object of objects
  const orderTickets = Object.values(phases);
  orderTickets.forEach((elem) => {
    let phaseId = elem.phaseId;
    let quantity = parseInt(elem.quantity);
    data.cart.orderTickets.push({ phaseId, quantity });
  });
  return data;
};

export const parsePayloadUpdateOrderProducts = (
  products,
  eventId,
  orgId,
  orderType,
  userId,
  orderId,
) => {
  let data = {
    cart: {
      orgId,
      eventId,
      orderType,
      userId,
      orderId,
      orderProducts: [],
    },
  };
  //we convert object from array because we are dealing
  //with a redux object of objects
  const orderProducts = Object.values(products);
  orderProducts.forEach((elem) => {
    let productId = elem.productId;
    let quantity = parseInt(elem.quantity);
    data.cart.orderProducts.push({ productId, quantity });
  });
  return data;
};
