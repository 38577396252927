import ConektaContext from './ConektaContext';
import React, { useState, useEffect } from 'react';
// redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// firebase
import { withFirebase } from '../../components/firebase';
// helpers
import { compose } from 'recompose';
import axios from 'axios';

import { isBanorteCard } from '../../helpers/banorteExclusiveSale';

import { useFirebaseAuth } from '../../hooks/useFirebaseAuth';

  const CONEKTA_ERRORS = {
  'conekta.errors.processing.bank.declined': 'Las tarjeta ha sido rechazada. Inténtalo de nuevo.',
  'conekta.errors.processing.bank.insufficient_funds':
    'No hay fondos suficientes en esta tarjeta. Inténtalo de nuevo',
  'conekta.errors.processing.bank.invalid_card_security_code':
    'El código de seguridad no es válido. Inténtalo de nuevo.',
  'conekta.errors.processing.bank.invalid_card': 'La tarjeta no es válida. Inténtalo de nuevo.',
  'conekta.errors.processing.bank.restricted_card':
    'La tarjeta fue rechazada por el sistema antifraude del banco. Comuníquese con su banco.'
};

const DEFAULT_ERROR_MESSAGE =
  'Transacción declinada por el banco. Inténtalo de nuevo o seleccione otro método de pago.';

const ConektaProvider = ({ children, event, acceptedTerms, orderDetails, firebase }) => {
  const { user, updateUserLocal } = useFirebaseAuth();
  const [cardNumberValid, setCardNumberValid] = useState(false);
  const [nameValid, setNameValid] = useState(true);
  const [monthValid, setMonthValid] = useState(false);
  const [yearValid, setYearValid] = useState(false);
  const [expirationDateValid, setExpirationDateValid] = useState(false);
  const [cvvValid, setCvvValid] = useState(false);
  const [processingPayment, setProcessingPayment] = useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [cardBrand, setCardBrand] = useState('');
  const [name, setName] = useState(
    `${(user.name && user.name.firstName) || ''} ${(user.name && user.name.lastName) || ''}`
  );
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [cvv, setCvv] = useState('');
  const [maxCVV, setMaxCVV] = useState(3);
  const [conektaSuccess, setConektaSuccess] = useState(false);
  const [conektaError, setConektaError] = useState(false);
  const [cardDeclinedMessageConekta, setCardDeclinedMessageConekta] = useState('');
  const [buttonMessage, setButtonMessage] = useState('Continuar compra');
  const [ccIsBanorte, setCcIsBanorte] = useState(false);
  const [banorteExclusiveSale, setBanorteExclusiveSale] = useState(false);
  const [pc, setPc] = useState('');
  const [paymentCard, setPaymentCard] = useState('');

  const handleResetState = () => {
    setCardNumber(false);
    setNameValid(false);
    setMonthValid(false);
    setYearValid(false);
    setExpirationDateValid(false);
    setCvvValid(false);
    setProcessingPayment(false);
    setCardNumber(false);
    setCardBrand(false);
    setName('');
    setMonth('');
    setYear('');
    setCvv('');
    setMaxCVV(3);
    setConektaSuccess(false);
    setConektaError(false);
    setCardDeclinedMessageConekta('');
    setButtonMessage('Continuar compra');
    setCcIsBanorte(false);
    setBanorteExclusiveSale(false);
    setPc('');
    setPaymentCard('');
  };

  useEffect(() => {
    setName(`${user.name && user.name.firstName} ${user.name && user.name.lastName}`);
  }, [user.name && user.name.firstName, user.name && user.name.lastName]);
  useEffect(() => {
    if (year.length > 0 || month.length > 0) {
      // validate both month and year
      const expirationDateValid = window.Conekta.card.validateExpirationDate(month, year);
      // validate year
      const yearValid = /[0-9]{2,4}/.test(year);
      setYearValid(yearValid);
      setExpirationDateValid(expirationDateValid);
    }
  }, [month, year]);

  const findError = code => {
    const message = CONEKTA_ERRORS[code];
    return message || DEFAULT_ERROR_MESSAGE;
  };
  return (
    <ConektaContext.Provider
      value={{
        cardNumberValid,
        nameValid,
        monthValid,
        yearValid,
        expirationDateValid,
        processingPayment,
        cvvValid,
        cardNumber,
        cardBrand,
        name,
        month,
        year,
        cvv,
        pc,
        setPc,
        conektaSuccess,
        conektaError,
        cardDeclinedMessageConekta,
        buttonMessage,
        maxCVV,
        ccIsBanorte,
        banorteExclusiveSale,
        paymentCard,
        setPaymentCard,
        onCreditCardChange: e => {
          // if event.exclusiveSale is "BANORTE"
          // exclude other cards
          const { rawValue } = e.target;
          if (event.banorteExclusiveSale) {
            const result = isBanorteCard(rawValue);
            setCcIsBanorte(result);
            setBanorteExclusiveSale(true);
          }
          //only allow numbers on this input
          const re = /^[0-9\b\ ]+$/;
          if (e.target.value === '' || re.test(e.target.value)) {
            setCardNumber(rawValue);
          }

          // validate
          const cardBrand = window.Conekta.card.getBrand(rawValue);
          const cardNumberValid = window.Conekta.card.validateNumber(rawValue);
          setCardBrand(cardBrand);
          setCardNumberValid(cardNumberValid);
          setMaxCVV(cardBrand === 'amex' ? 4 : 3);
        },
        handleNameChange: ({ target }) => {
          const { value } = target;

          const regex = new RegExp('^[a-zA-Z ]+$');
          if (value === '' || regex.test(value)) {
            setName(value);
            setNameValid(value.length >= 4);
          }
        },
        handleMonthChange: ({ target }) => {
          const { value } = target;
          //only allow numbers on this input
          const re = /^[0-9\b]+$/;
          if (value === '' || re.test(value)) {
            setMonth(value);
          }
          // validate number is in range from 1 to 12
          const monthValid = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].includes(parseInt(value));
          setMonthValid(monthValid);
        },
        handleYearChange: ({ target }) => {
          const { value } = target;
          //only allow numbers on this input
          const re = /^[0-9\b]+$/;
          if (value === '' || re.test(value)) {
            setYear(value);
          }
        },
        handleCvvChange: ({ target }) => {
          const { value } = target;
          //only allow numbers on this input
          const re = /^[0-9\b]+$/;
          if (value === '' || re.test(value)) {
            setCvv(value);
          }
          // validate
          const cvvValid = window.Conekta.card.validateCVC(value);
          setCvvValid(cvvValid);
        },
        isFormInvalid: () => {
          let isValid = false;

          if (!paymentCard) {
            if (event.banorteExclusiveSale) {
              // if validations are not valid, and payment is not being processed
              // <button disabled={true} />

              isValid = acceptedTerms
                ? !cardNumberValid ||
                  !nameValid ||
                  !monthValid ||
                  !yearValid ||
                  !cvvValid ||
                  !pc ||
                  !expirationDateValid ||
                  processingPayment ||
                  !ccIsBanorte
                : false;
            } else {
              isValid = !!(
                !cardNumberValid ||
                !nameValid ||
                !monthValid ||
                !yearValid ||
                !cvvValid ||
                !pc ||
                !expirationDateValid ||
                !acceptedTerms ||
                processingPayment
              );
            }
          } else {
            isValid = !acceptedTerms;
          }

          return isValid;
        },
        makePayment: (push, eventSlug, organizationSlug) => {
          setButtonMessage('Procesando pago');
          setProcessingPayment(true);

          window.Conekta.setPublicKey(process.env.REACT_APP_CONEKTA_API_KEY);
          const { orderId } = orderDetails[event.eventId];
          const tokenParams = {
            card: {
              number: cardNumber,
              name,
              exp_year: year,
              exp_month: month,
              cvc: cvv
            }
          };
          const customer_info = {
            name,
            email: user.email,
            phone: user.phone
          };

          const successResponseHandler = async token => {
            let { conektaCustomerId } = user;
            try {
              const tokenId = token.id;
              const customerPayload = { customer: { uid: user.id, postalCode: pc } };

              if (!conektaCustomerId) {
                const createCustomer = await axios.post(
                  `${process.env.REACT_APP_FUNCTIONS_URL}/payments/conekta/customers`,
                  customerPayload
                );

                conektaCustomerId = createCustomer.data.id;
                updateUserLocal({ conektaCustomerId });
              }

              let paymentSource = '';
              if (!user.isGuest) {
                if (!paymentCard) {
                  const paymentSourceData = await axios.post(
                    `${process.env.REACT_APP_FUNCTIONS_URL}/payments/conekta/customer_payment_sources`,
                    {
                      conekta_customer_id: conektaCustomerId,
                      payment_source: { type: 'card', token_id: tokenId }
                    }
                  );
                  paymentSource = paymentSourceData.data.id;
                }
              }

              const paymentPayload = {
                order: {
                  orgId: event.organizationId,
                  eventId: event.eventId,
                  id: orderId,
                  userId: user.id,
                  paymentMethod: !user.isGuest
                    ? { type: 'card', payment_source_id: paymentCard || paymentSource }
                    : { type: 'card', token_id: tokenId },
                  customer_info,
                  postalCode: pc,
                  NODE_ENV: process.env.REACT_APP_ENV
                }
              };
              const data = await axios.post(
                `${process.env.REACT_APP_FUNCTIONS_URL}/payments/conekta`,
                paymentPayload
              );

              if (data.object === 'error') {
                setConektaError(true);
                setConektaSuccess(false);
                setCardDeclinedMessageConekta(data.details[0].message);
                setButtonMessage('Continuar compra');
                setProcessingPayment(false);
              } else {
                push(`/${organizationSlug}/${eventSlug}/order-confirmation/${orderId}`);
                handleResetState();
              }
            } catch (error) {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
              if (error.response) {
                const errorDetails = error.response.data.details[0];
                const errorMessage = findError(errorDetails.code);
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                setConektaError(true);
                setConektaSuccess(false);
                setCardDeclinedMessageConekta(errorMessage);
                setButtonMessage('Continuar compra');
                setProcessingPayment(false);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                setConektaError(true);
                setConektaSuccess(false);
                setCardDeclinedMessageConekta(error.request);
                setButtonMessage('Continuar compra');
                setProcessingPayment(false);

                console.log(error.request);
              } else {
                console.log(error);
                setConektaError(true);
                setConektaSuccess(false);
                setCardDeclinedMessageConekta(error.message);
                setButtonMessage('Continuar compra');
                setProcessingPayment(false);

                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
            }
          };
          const errorResponseHandler = error => {
            setConektaError(true);
            setConektaSuccess(false);
            setCardDeclinedMessageConekta(error.message);
            setButtonMessage('Continuar compra');
            setProcessingPayment(false);
          };

          const onPayment = async () => {
            try {
              const paymentPayload = {
                order: {
                  orgId: event.organizationId,
                  eventId: event.eventId,
                  id: orderId,
                  userId: user.id,
                  paymentMethod: { type: 'card', payment_source_id: paymentCard },
                  customer_info,
                  postalCode: pc,
                  NODE_ENV: process.env.REACT_APP_ENV
                }
              };

              const data = await axios.post(
                `${process.env.REACT_APP_FUNCTIONS_URL}/payments/conekta`,
                paymentPayload
              );

              if (data.object === 'error') {
                setConektaError(true);
                setConektaSuccess(false);
                setCardDeclinedMessageConekta(data.details[0].debug_message);
                setButtonMessage('Continuar compra');
                setProcessingPayment(false);
              } else {
                push(`/${organizationSlug}/${eventSlug}/order-confirmation/${orderId}`);
                handleResetState();
              }
            } catch (error) {
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                setConektaError(true);
                setConektaSuccess(false);
                setCardDeclinedMessageConekta(
                  error.response.data.error
                    ? error.response.data.error.details[0].debug_message
                    : error.response.data.details[0].debug_message
                );
                setButtonMessage('Continuar compra');
                setProcessingPayment(false);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                setConektaError(true);
                setConektaSuccess(false);
                setCardDeclinedMessageConekta(error.request);
                setButtonMessage('Continuar compra');
                setProcessingPayment(false);

                console.log(error.request);
              } else {
                console.log(error);
                setConektaError(true);
                setConektaSuccess(false);
                setCardDeclinedMessageConekta(error.message);
                setButtonMessage('Continuar compra');
                setProcessingPayment(false);

                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
            }
          };

          if (!paymentCard) {
            window.Conekta.Token.create(tokenParams, successResponseHandler, errorResponseHandler);
          } else {
            onPayment();
          }
        },
        makePaymentWithOxxo: async (push, eventSlug, organizationSlug) => {
          setProcessingPayment(true);
          setButtonMessage('Procesando pago');
          const { orderId } = orderDetails[event.eventId];
          const paymentPayload = {
            order: {
              orgId: event.organizationId,
              eventId: event.eventId,
              id: orderId,
              userId: user.id,
              paymentType: 'OXXO_PAY',
              NODE_ENV: process.env.REACT_APP_ENV
            }
          };
          await axios.post(
            `${process.env.REACT_APP_FUNCTIONS_URL}/payments/conekta`,
            paymentPayload
          );
          setProcessingPayment(false);
          setButtonMessage('Continuar compra');
          push(`/${organizationSlug}/${eventSlug}/pending/${orderId}`);
        },
        getPaymentsCards: async customerID => {
          const {
            data: {
              // eslint-disable-next-line camelcase
              _json: { payment_sources, default_payment_source_id }
            }
          } = await axios.get(
            `${process.env.REACT_APP_FUNCTIONS_URL}/payments/conekta/customer/${customerID}`
          );
          // eslint-disable-next-line camelcase
          const { data } = { ...payment_sources };
          // eslint-disable-next-line camelcase
          return { data, default_payment_source_id };
        }
      }}
    >
      {children}
    </ConektaContext.Provider>
  );
};

export default compose(
  withFirebase,
  connect(
    state => ({
      user_data: state.user_data,
      orderDetails: state.orderDetails,
      event: state.event,
      acceptedTerms: state.cart.acceptedTerms
    }),
    dispatch => bindActionCreators({}, dispatch)
  )
)(ConektaProvider);
