import React from 'react';
import { Col, Row } from 'reactstrap';
import SelectExtraServices from '../../components/select_extra_services';
import EventContainer from '../../components/event_container';
import { useFirebaseAuth } from '../../hooks/useFirebaseAuth';

const ExtraServices = props => {
  const { organizationSlug, eventSlug } = props.match.params;
  const { user } = useFirebaseAuth();

  return (
    <EventContainer user={user} step={'Paso 2'}>
      {({ event, eventId, organizationId, selectedDate, setSelectedDate }) => {
        return (
          <Row>
            <Col lg="12" className="event">
              <SelectExtraServices
                organizationSlug={organizationSlug}
                eventSlug={eventSlug}
                eventId={eventId}
                organizationId={organizationId}
                donations={event.donation}
                event={event}
                selectedDate={selectedDate}
                setSelectedDate = {setSelectedDate}
              />
            </Col>
          </Row>
        );
      }}
    </EventContainer>
  );
};

export default ExtraServices;
