import React from "react";
//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Row, Col } from "reactstrap";
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.scss";

library.add(fas, faFacebook, faTwitterSquare);

// // Kicks off the process of finding <i> tags and replacing with <svg>
dom.watch();

class ResponsiveSocialShare extends React.Component {
  render() {
    const { facebookLink, twitterLink } = this.props.organization;
    return (
      <Row className="responsive-social-share">
        <Col sm="12" xs="12" className="social-sharing">
          <p className="text-center">
            <small>Compartir:</small> <br />
            <a href={`https://${facebookLink}`}>
              <FontAwesomeIcon
                icon={faFacebook}
                size="lg"
                className="fb-icon"
              />
            </a>
            <a href={`https://${twitterLink}`}>
              <FontAwesomeIcon
                icon={faTwitterSquare}
                size="lg"
                className="tw-icon"
              />
            </a>
          </p>
        </Col>
      </Row>
    );
  }
}

export default connect(
  (state) => ({
    facebookLink: state.org.facebookLink,
    twitterLink: state.org.twitterLink,
  }),
  (dispatch) => bindActionCreators({}, dispatch),
)(ResponsiveSocialShare);
