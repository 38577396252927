import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import './styles.scss';
import { useFirebaseAuth } from '../../../hooks/useFirebaseAuth';
const PAYPAL_ERRORS = {
  INSTRUMENT_DECLINED: 'Las tarjeta ha sido rechazada. Inténtalo de nuevo.',
  TRANSACTION_BLOCKED_BY_PAYEE:
    'La tarjeta fue rechazada por el sistema antifraude del banco. Comuníquese con su banco.'
};

const DEFAULT_ERROR_MESSAGE =
  'Transacción declinada por el banco. Inténtalo de nuevo o seleccione otro método de pago.';

function PaypalV2({
  organizationId,
  eventId,
  orderId,
  setPaypalSelectionPage,
  setDisableContinue,
  setPaypalError,
  setPaypalMessageError,
  history,
  selectedPayment,
  match: {
    params: { eventSlug, organizationSlug }
  }
}) {
  const { user } = useFirebaseAuth();
  const [isLoading, setIsLoading] = useState(true);
  React.useEffect(() => {
    if (selectedPayment === 'PAYPAL_PLUS') {
      createPaypalPayment();
    }
  }, [selectedPayment]);

  const initializeIframe = approvalUrl => {
    const paypalInstance = window.PAYPAL.apps.PPP({
      approvalUrl,
      placeholder: 'ppplus',
      mode: process.env.REACT_APP_PAYPAL_MODE,
      payerFirstName: user.name && user.name.firstName,
      payerLastName: user.name && user.name.lastName,
      payerTaxId: '',
      payerEmail: user.email,
      payerTaxIdType: '',
      language: 'es_MX',
      country: 'MX',
      rememberedCards: 'customerRememberedCardHash',
      disableContinue: '',
      enableContinue: '',
      iframeHeight: '500px'
    });
    setPaypalSelectionPage(paypalInstance);
  };
  const createPaypalPayment = async () => {
    const url = `${process.env.REACT_APP_FUNCTIONS_URL}/payments/paypal`;
    const response = await axios.post(url, {
      order: {
        id: orderId,
        orgId: organizationId,
        eventId: eventId
      }
    });
    const approvalUrl = response.data.links.find(link => link.rel === 'approval_url');
    initializeIframe(approvalUrl.href);
  };

  const findError = code => {
    const message = PAYPAL_ERRORS[code];
    return message || DEFAULT_ERROR_MESSAGE;
  };

  React.useEffect(() => {
    window.addEventListener('message', messageListener);
    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, []);

  const messageListener = async event => {
    if (typeof event.data === 'string' && event.data.indexOf('{') === -1) return false;
    const message = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;

    if (message.result === 'error') {
      setDisableContinue(false);
    }

    if (message.action === 'loaded') {
      setIsLoading(false);
      setDisableContinue(false);
    }
    if (message.action === 'checkout') {
      const payerId = message.result.payer.payer_info.payer_id;
      const paypalCartId = message.result.id;
      try {
        await executePayment(payerId, paypalCartId);
      } catch (error) {
        setPaypalMessageError(DEFAULT_ERROR_MESSAGE);
        setDisableContinue(false);
      }
    }

    if (typeof message.cause !== 'undefined') {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      setPaypalError(false);
      setDisableContinue(false);
    }
  };

  const executePayment = async (payerId, paypalCartId) => {
    try {
      setPaypalError(false);
      const url = `${process.env.REACT_APP_FUNCTIONS_URL}/payments/paypal/execute`;
      await axios.post(url, {
        order: {
          payerId,
          paypalCartId,
          id: orderId,
          orgId: organizationId,
          eventId: eventId
        }
      });
      history.push(`/${organizationSlug}/${eventSlug}/order-confirmation/${orderId}`);
    } catch (error) {
      setPaypalError(true);
      const message = findError(error.response.data.name);
      setPaypalMessageError(message);
      setDisableContinue(false);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  };
  return (
    <div className="PaypalV2">
      {isLoading && <div className="spinner loading" />}
      <div id="ppplus" />
    </div>
  );
}

PaypalV2.propTypes = {};

export default withRouter(PaypalV2);
