import React from 'react';
// style and styled components
import { Container } from 'reactstrap';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import SliderCenterMode from '../../components/slider';
import Eventlist from '../../components/event_list';
import OrganizationHeader from '../../components/organization_header';
import EventFilter from '../../components/event_filter';
import ResponsiveSocialShare from '../../components/responsive_social_share';
import { withFirebase } from '../../components/firebase';
import './styles.scss';
import GTM from '../../components/Google_Tag_Manager';
import Intercom from 'react-intercom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setOrg } from '../../redux/actions/index';

class Organization extends React.Component {
  state = { orderBy: 'DATE', organization: null };
  orderBy = select => {
    this.setState({ orderBy: select });
  };
  async componentDidMount() {
    // return to top of the screen after rendering component
    window.scrollTo(0, 0);
    const { organizationSlug } = this.props.match.params;
    const snapshot = await this.props.firebase.getOrganization(organizationSlug);
    snapshot.forEach(doc => {
      this.setState({ organization: doc.data(), organizationId: doc.id });
      const { gmId, intercomId, googleAnalyticsId, facebookPixelId, eventFilter } = doc.data();
      this.setState({ orderBy: eventFilter || "DATE" });
      this.props.setOrg({ gmId, intercomId, googleAnalyticsId, facebookPixelId });
    });
  }
  render() {
    return this.state.organization != null ? (
      <>
        <SliderCenterMode headerImage={this.state.organization.headerImage} />
        <Container className="clearfix w860 p-0">
          <OrganizationHeader organization={this.state.organization} />
          <EventFilter orderByFunc={this.orderBy} orderBy={this.state.orderBy} />
          <Eventlist
            // events={events}
            // ready={ready}
            organizationId={this.state.organizationId}
            orderBy={this.state.orderBy}
          />
          <ResponsiveSocialShare organization={this.state.organization} />
        </Container>
      </>
    ) : (
      <>
        <Container className="clearfix w860 p-0">
          <ReactPlaceholder ready={false} type="text" rows={12}>
            {' '}
          </ReactPlaceholder>
        </Container>
      </>
    );
  }
}

// export default withFirebase(Organization);

export default compose(
  withFirebase,
  connect(
    state => ({}),
    dispatch => bindActionCreators({ setOrg }, dispatch)
  )
)(Organization);
