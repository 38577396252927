import React from "react";
import EventContainer from "../../components/event_container";
import { withFirebase } from "../../components/firebase";
import { asyncForEach } from "../../helpers/arrays";
import { Form, FormGroup, Button } from "reactstrap";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setFormAnswers } from "../../redux/actions/form.action";
import Input from "./Input";
import "./index.scss";
import { Row, Col } from "reactstrap";
import { serializeForm } from "../../helpers/serializeForm";
class Forms extends React.Component {
  state = {
    orderTickets: [],
    forms: [],
    orderTicketsWithForm: [],
    sending: false
  };
  handleGetOrderTickets = async () => {
    return new Promise(async (resolve, reject) => {
      const { eventId, organizationId, firebase, orderDetails } = this.props;
      const orderId = orderDetails[eventId].orderId;

      const orderTicketsCollection = await firebase.getOrderTicketsStatic(
        organizationId,
        eventId,
        orderId
      );

      let orderTicketsWithForm = [];

      await asyncForEach(orderTicketsCollection.docs, async orderTicket => {
        if (orderTicket.data().formId) {
          const formAnswersCollection = await firebase.getFormAnswers(
            organizationId,
            eventId,
            orderId,
            orderTicket.id
          );

          let persistedFormAnswers = [];
          if (!formAnswersCollection.empty) {
            persistedFormAnswers = formAnswersCollection.docs.map(
              formAnswer => ({
                ...formAnswer.data(),
                id: formAnswer.id
              })
            );
          }

          orderTicketsWithForm.push({
            ...orderTicket.data(),
            id: orderTicket.id,
            persistedFormAnswers
          });
        }
      });

      resolve(orderTicketsWithForm);
    });
  };
  handleGetForms = async orderTickets => {
    const { organizationId, firebase } = this.props;

    let forms = [];

    await asyncForEach(orderTickets, async item => {
      const form = await firebase.getForms(organizationId, item.formId);
      const data = form.data();
      forms.push(data);
      item.form = data;
    });
    this.setState({ forms, orderTicketsWithForm: orderTickets });
  };
  async componentDidMount() {
    if (this.props.orderDetails[this.props.eventId]) {
      const orderTickets = await this.handleGetOrderTickets();
      await this.handleGetForms(orderTickets);
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ formData: serializeForm(e.target) }, this.saveFormData);
  };

  saveFormData = async () => {
    const { organizationId, eventId, orderDetails } = this.props;
    const orderId = orderDetails[eventId].orderId;
    await this.props.firebase.saveAnswers(
      organizationId,
      eventId,
      orderId,
      this.state.formData
    );
    this.setState({
      sending: true
    });
    this.props.setFormAnswers(true);
  };

  render() {
    return (
      <Form onSubmit={this.handleSubmit} className="form-answers">
        {this.state.orderTicketsWithForm.map((orderTicket, i) => (
          <div key={i}>
            <FormGroup>
              <h4>
                {orderTicket.name}: {orderTicket.form.title}
              </h4>
              <p>
                <em>{orderTicket.form.description}</em>
              </p>
            </FormGroup>
            <Row>
              {[...Array(orderTicket.quantity)].map((e, i) => (
                <Col xs="12" md="6" sm="6" key={i}>
                  <FormGroup className="form-fieldset" key={i}>
                    <h5>Datos del boleto {i + 1}</h5>
                    {orderTicket.form.fields.map((field, j) => (
                      <div key={j}>
                        {j === 0 && (
                          <>
                            <input
                              type="hidden"
                              name={`${orderTicket.id}.form_answers.${i}.id`}
                              value={
                                orderTicket.persistedFormAnswers[i]
                                  ? orderTicket.persistedFormAnswers[i].id
                                  : ""
                              }
                            />
                            <input
                              type="hidden"
                              name={`${orderTicket.id}.form_answers.${i}.sortOrder`}
                              value={i}
                            />
                          </>
                        )}
                        <Input
                          field={field}
                          customIndex={i}
                          orderTicketId={orderTicket.id}
                          persistedValue={
                            orderTicket.persistedFormAnswers[i]
                              ? orderTicket.persistedFormAnswers[i][field.token]
                              : ""
                          }
                        />
                      </div>
                    ))}
                  </FormGroup>
                </Col>
              ))}
            </Row>
            <hr />
          </div>
        ))}
        <div className="text-right form-actions">
          <Button color="primary" size="lg" block disabled={this.state.sending}>
            Save
          </Button>
        </div>
      </Form>
    );
  }
}

const WithProps = props => {
  return (
    <EventContainer>
      {otherProps => <Forms {...otherProps} {...props} />}
    </EventContainer>
  );
};

// this.props.orderDetails[this.props.eventId]
export default compose(
  withFirebase,
  connect(
    state => ({
      orderDetails: state.orderDetails
    }),
    dispatch => bindActionCreators({ setFormAnswers }, dispatch)
  )
)(WithProps);
