import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import rootReducer from '../reducers';
// saga
import createSagaMiddleware from 'redux-saga';

// outer helper libraries
import LogRocket from 'logrocket';
import expireReducer from 'redux-persist-expire';
import { isChrome } from 'react-device-detect';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['payment', 'orderDetails', 'user_data'], // only these states will be persisted
  transforms: [
    // Create a transformer by passing the reducer key and configuration. Values
    // shown below are the available configurations with default values
    expireReducer('payment', {
      // (Optional) Key to be used for the time relative to which store is to be expired
      persistedAtKey: 'loadedAt',
      // (Required) Seconds after which store will be expired
      expireSeconds: 32400,
      // (Optional) State to be used for resetting e.g. provide initial reducer state
      expiredState: { access_token: null },
      // (Optional) Use it if you don't want to manually set the time in the reducer i.e. at `persistedAtKey`
      // and want the store to  be automatically expired if the record is not updated in the `expireSeconds` time
      autoExpire: true
    }),
    expireReducer('orderDetails', {
      expireSeconds: 720,
      autoExpire: true,
      expiredState: []
    })
    // You can add more `expireReducer` calls here for different reducers
    // that you may want to expire
  ]
};

const sagaMiddleware = createSagaMiddleware();

const pReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  let store = createStore(
    pReducer,
    process.env.NODE_ENV === 'development' && isChrome
      ? compose(
          applyMiddleware(sagaMiddleware, LogRocket.reduxMiddleware())
          // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        )
      : compose(applyMiddleware(sagaMiddleware, LogRocket.reduxMiddleware()))
  );
  let persistor = persistStore(store);

  return { store, persistor };
};
