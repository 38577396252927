import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Intercom from "react-intercom";
import { IntercomAPI } from 'react-intercom';

class Services extends Component {
  state = {
    user: false
  };
  componentDidUpdate(prevProps) {
    if (window.Intercom && !this.state.user) {
      const idUserIntercom = window.Intercom("getVisitorId");
      this.props.logRocket.identify(idUserIntercom);
      this.props.bugsnag.user = { id: idUserIntercom };
      this.setState({ user: true });
    }
    if (this.handleLocationWasUpdated(prevProps.history, this.props.history)) {
      window.Intercom("update");
      // window.Intercom('showNewMessage');
      //window.Intercom('showMessages');
      IntercomAPI('showMessages')

    }
  }

  handleLocationWasUpdated = (oldHistory, newHistory) => {
    return oldHistory.location.pathname !== newHistory.location.pathname
      ? true
      : false;
  };

  render() {
    return <Intercom appID={this.props.appID} />;
  }
}

export default withRouter(Services);
